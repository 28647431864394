<template>
  <div
      v-show="visible || inline"
      :class="{'inline': inline, 'is-dark': dark, 'visible': visible}"
      :style="responsivePosition"
      class="datetimepicker flex"
      @click.stop
  >
    <div
        :style="[responsivePosition, width]"
        class="datepicker flex flex-direction-column"
        :class="{ 'right': right }"
    >
      <HeaderPicker
          v-if="!noHeader"
          :key="componentKey"
          :model-value="modelValue"
          @update:modelValue="$emit('update:modelValue', modelValue)"
          :color="color"
          :only-time="onlyTime"
          :format="format"
          :time-format="timeFormat"
          :transition-name="transitionName"
          :no-time="onlyDate"
          :dark="dark"
          :range="range"
      />
      <!--  Unfortunately needed since this will be updated interactively. -->
      <div class="pickers-container flex" :style="'top:' + positionTop + 'px; left:' + positionLeft + 'px;'">
        <!-- NEED 'YYYY-MM-DD' format -->
        <DatePicker
            v-if="!onlyTime"
            :id="$attrs.id"
            :modelValue="date"
            @update:modelValue="date = $event"
            :dark="dark"
            :month="month"
            :inline="inline"
            :no-weekends-days="noWeekendsDays"
            :disabled-weekly="disabledWeekly"
            :color="color"
            :min-date="minDate"
            :max-date="maxDate"
            :disabled-dates="disabledDates"
            :enabled-dates="enabledDates"
            :range="range"
            :no-shortcuts="noShortcuts"
            :height="height"
            :first-day-of-week="firstDayOfWeek"
            :visible="visible"
            :shortcut="shortcut"
            :custom-shortcuts="customShortcuts"
            :no-keyboard="noKeyboard"
            :locale="locale"
            @change-month="changeMonth"
            @change-year-month="changeYearMonth"
            @close="$emit('close')"
        />
        <!-- NEED 'HH:mm' format -->
        <TimePicker
            v-if="!onlyDate"
            ref="TimePicker"
            v-model="time"
            :dark="dark"
            :color="color"
            :inline="inline"
            :format="timeFormat"
            :only-time="onlyTime"
            :minute-interval="minuteInterval"
            :visible="visible"
            :height="height"
            :disabled-hours="disabledHours"
            :min-time="minTime"
            :max-time="maxTime"
            :behaviour="behaviour"
        />
      </div>
      <!-- Shaving height baby -->
      <!--                <ButtonValidate-->
      <!--                        v-if="!hasNoButton && !(inline && range)"-->
      <!--                        class="button-validate flex-fixed"-->
      <!--                        :dark="dark"-->
      <!--                        :button-color="buttonColor"-->
      <!--                        :button-now-translation="buttonNowTranslation"-->
      <!--                        :only-time="onlyTime"-->
      <!--                        :no-button-now="noButtonNow"-->
      <!--                        :range="range"-->
      <!--                        :has-button-validate="hasButtonValidate"-->
      <!--                        @validate="$emit('validate')"-->
      <!--                        @now="setNow"-->
      <!--                />-->
    </div>
  </div>
</template>

<script>
import moment from 'moment-mini';

import DatePicker from './_subs/DatePicker';
import TimePicker from './_subs/TimePicker';
import HeaderPicker from './_subs/HeaderPicker';
import ButtonValidate from './_subs/ButtonValidate';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';

import Month from '@/vue/components/general/form/date-time-picker/modules/month';
import Utility from '@/vue/utility/utility';

export default {
  name: 'PickersContainer',
  components: {
    DatePicker, TimePicker, HeaderPicker, ButtonValidate
  },
  emits: ['update:modelValue','close','validate'],
  mixins: [UsesMediaQueries, WorksWithParentsSiblingsAndChildren],
  inheritAttrs: false,
  props: {
    modelValue: {type: [String, Object], default: null},
    visible: {type: Boolean, required: true, default: false},
    position: {type: String, default: 'bottom'},
    inline: {type: Boolean, default: false},
    dark: {type: Boolean, default: false},
    noHeader: {type: Boolean, default: null},
    color: {type: String, default: null},
    onlyDate: {type: Boolean, default: false},
    onlyTime: {type: Boolean, default: null},
    minuteInterval: {type: [String, Number], default: 1},
    format: {type: String, default: 'YYYY-MM-DD hh:mm a'},
    locale: {type: String, default: null},
    maxDate: {type: String, default: null},
    minDate: {type: String, default: null},
    hasButtonValidate: {type: Boolean, default: null},
    hasNoButton: {type: Boolean, default: null},
    noWeekendsDays: {type: Boolean, default: null},
    disabledWeekly: {type: Array, default: null},
    disabledDates: {type: Array, default: null},
    disabledHours: {type: Array, default: null},
    enabledDates: {type: Array, default: null},
    range: {type: Boolean, default: null},
    noShortcuts: {type: Boolean, default: null},
    buttonColor: {type: String, default: null},
    buttonNowTranslation: {type: String, default: null},
    noButtonNow: {type: Boolean, default: false},
    firstDayOfWeek: {type: Number, default: null},
    shortcut: {type: String, default: null},
    customShortcuts: {type: Array, default: null},
    noKeyboard: {type: Boolean, default: false},
    right: {type: Boolean, default: false},
    behaviour: {type: Object, default: () => ({})}
  },
  data() {
    return {
      month: this.getMonth(),
      transitionName: 'slidevnext',
      componentKey: 0,

      // Used to programmatically position the PickerContainer based on the position in the browser.
      // Only way to get it working properly cross-browser
      positionTop: 0,
      positionLeft: 0,
      // Used to detect if the <input> is visible onscreen so we can hide it if it scrolls out of view
      visibleInParent: false,
    };
  },
  computed: {
    width() {
      const size = this.inline
        ? '100%'
        : this.onlyTime
          ? '76px'
          : !this.range
            ? this.onlyDate
              ? '260px'
              : '340px'
            : '340px';
      return {
        width: size,
        maxWidth: size,
        minWidth: size
      };
    },
    responsivePosition() {
      if (typeof window === 'undefined') return null;
      return !this.inline
        ? window.innerWidth < 412 && this.sm
          ? null
          : this.position === 'bottom'
            ? {top: '100%', marginBottom: '0'}
            : {bottom: '100%', marginTop: '0'}
        : null;
    },
    timeFormat() {
      return this.onlyTime
        ? this.format
        : this.onlyDate ? null : this.getTimeFormat();
    },
    dateFormat() {
      return this.onlyTime
        ? null
        : this.getDateFormat();
    },
    height() {
      return !this.onlyTime
        ? this.month
          ? (this.month.getMonthDays().length + this.month.getWeekStart()) > 35 ? 347 : 307
          : 180
        : 200;
    },
    time: {
      set(value) {
        this.emitValue({
          value: value,
          type: 'time'
        });
      },
      get() {
        return this.modelValue
          ? moment(this.modelValue, 'YYYY-MM-DD HH:mm').format('HH:mm')
          : null;
      }
    },
    date: {
      set(value) {
        this.emitValue({
          value: value,
          type: 'date'
        });
      },
      get() {
        const date = this.modelValue
          ? this.onlyTime
            ? null
            : this.range
              ? {
                start: this.modelValue.start ? moment(this.modelValue.start).format('YYYY-MM-DD') : null,
                end: this.modelValue.end ? moment(this.modelValue.end).format('YYYY-MM-DD') : null
              }
              : moment(this.modelValue, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
          : this.range
            ? {start: null, end: null}
            : null;
        return date;
      }
    },
    minTime() {
      const time = moment(this.minDate, 'YYYY-MM-DD HH:mm').format(this.timeFormat);
      if (
        this.minDate &&
          time !== '00:00' &&
          moment(this.date).isSame(moment(this.minDate, 'YYYY-MM-DD'))
      ) {
        return time;
      }
      return '';
    },
    maxTime() {
      const time = moment(this.maxDate, 'YYYY-MM-DD HH:mm').format(this.timeFormat);
      if (this.maxDate &&
          time !== '00:00' &&
          moment(this.date).isSame(moment(this.maxDate, 'YYYY-MM-DD'))) {
        return time;
      }
      return '';
    },
    medimoDateTimePickerParent() {
      // Set to check 6 levels up. Needs to be increased when the MedimoDateTimePicker is altered with more layers.
      return this.find_matching_parent('medimoDateTimePickerUid', 6);
    },
    mediModalParent() {
      return this.find_matching_parent('mediModalUid', 12);
    },
    mediModalParentBody() {
      return this.mediModalParent.$el.querySelector('.modal-body');
    },
    isInModal() {
      return typeof this.mediModalParent !== 'undefined';
    },
    // We use this one to track if a parent modal is being scrolled. If it is, we want to update the position of the modal.
    modalScrollCounter: function () {
      const mediModal = this.mediModalParent;
      if (typeof mediModal !== 'undefined') {
        return mediModal.scrollCounter;
      }
      return 0;
    },
    pickerOpen: {
      get() {
        return this.$parent.pickerOpen;
      },
      set(value) {
        this.$parent.pickerOpen = value;
      }
    }

  },
  mounted() {
    //
  },
  watch: {
    modalScrollCounter(value, oldValue) {
      // Update the "Fixed" position when there's scrolling in the modal
      this.updateFixedPosition();
      // Check if the DateTimePicker is still visible within the parent element.
      this.updateVisibleInParent();
    },
    pickerOpen(isOpen, oldValue) {
      if (isOpen) {
        this.updateFixedPosition();
        this.updateVisibleInParent();
      }
    },
    visibleInParent(value, oldValue) {
      // Close the picker when the parent scrolls out of view.
      if (value !== oldValue && value === false) {
        this.pickerOpen = false;
      }
    },
    modelValue(value) {
      this.month = this.getMonth(value);
    },
    locale() {
      this.month = this.getMonth();
      this.componentKey += 1;
    }
  },
  methods: {
    setNow(event) {
      this.$emit('update:modelValue', event);
      this.$emit('close');
    },
    emitValue(payload) {
      const dateTime = this.range ? payload.value : this.getDateTime(payload);
      this.$emit('update:modelValue', dateTime);
      if (!this.range) {
        this.getTransitionName(dateTime);
      }
    },
    getDateTime({value, type}) {
      return this.onlyTime
        ? `${moment().format('YYYY-MM-DD')} ${value}`
        : type === 'date'
          ? this.time ? `${value} ${this.time}` : `${value} ${moment().format('HH:mm')}`
          : this.date ? `${this.date} ${value}` : `${moment().format('YYYY-MM-DD')} ${value}`;
    },
    getTransitionName(date) {
      const isBigger = moment(date) > moment(`${this.date || moment().format('YYYY-MM-DD')} ${this.time || moment().format('HH:mm')}`);
      this.transitionName = isBigger ? 'slidevnext' : 'slidevprev';
    },
    getDateFormat() {
      const hasTime = this.format.includes('T');
      return hasTime ? this.format.split('T')[0] : this.format.split(' ')[0];
    },
    getTimeFormat() {
      const formatLower = this.format.toLowerCase();
      const hasTimeFormat = formatLower.includes('h');
      if (hasTimeFormat) {
        const hasTime = this.format.includes('T');
        return hasTime ? this.format.split('T')[1] : this.format.split(' ').slice(1).join(' ');
      } else {
        window.console.warn('A time format must be indicated');
      }
    },
    getMonth(payload) {
      if (this.range) {
        const rangeVal = payload || this.modelValue;
        const date = rangeVal && (rangeVal.end || rangeVal.start) ? moment(rangeVal.end ? rangeVal.end : rangeVal.start) : moment();
        return new Month(date.month(), date.year());
      } else if (this.modelValue) {
        return new Month(moment(this.modelValue, 'YYYY-MM-DD').month(), moment(this.modelValue, 'YYYY-MM-DD').year(), this.locale);
      } else {
        return new Month(moment().month(), moment().year(), this.locale);
      }
    },
    changeMonth(val) {
      let month = this.month.month + (val === 'prev' ? -1 : +1);
      let year = this.month.year;
      if (month > 11 || month < 0) {
        year += (val === 'prev' ? -1 : +1);
        month = (val === 'prev' ? 11 : 0);
      }
      this.month = new Month(month, year, this.locale);
      if (this.$refs.TimePicker) {
        this.$refs.TimePicker.initPositionView();
      }
    },
    changeYearMonth({month, year}) {
      this.month = new Month(month, year, this.locale);
    },
    updateFixedPosition() {
      const boundingBox = this.medimoDateTimePickerParent.$el.getBoundingClientRect();
      this.positionTop = boundingBox.top + boundingBox.height;
      this.positionLeft = boundingBox.left;
    },
    updateVisibleInParent: function () {
      if (this.isInModal && typeof this.medimoDateTimePickerParent.$el !== 'undefined') {
        // We have a special use-case that checks if the element is in a MediModal, since it can scroll out of view in the modal.
        const datePicker = this.medimoDateTimePickerParent.$el;
        const datePickerHeight = datePicker.getBoundingClientRect().height;
        // Ofset with the height so it will register as not visible as soon as the bottom hits the bottom of the modal
        this.visibleInParent = Utility.element_is_visible_inside_another_element(datePicker, this.mediModalParentBody, 'bottom', datePickerHeight);
      } else if (typeof this.medimoDateTimePickerParent.$el !== 'undefined') {
        this.visibleInParent = Utility.element_is_visible_on_page(this.medimoDateTimePickerParent.$el);
      }
    }
  }
};
</script>
