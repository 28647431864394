import {AddressObject} from '@/domain/models/AddressObject';
import {CodeValue} from '@/domain/models/CodeValue';

export class GeneratedAddressObject { 
  public street: string | null;
  public number: string | null;
  public numberLetter: string | null;
  public numberAddition: string | null;
  public postcode: string | null;
  public city: string | null;
  public country: string | null;
  public addressType: CodeValue | null;

  constructor(street: string | null = null, number: string | null = null, numberLetter: string | null = null, numberAddition: string | null = null, postcode: string | null = null, city: string | null = null, country: string | null = null, addressType: CodeValue | null = null) {
    this.street = street;
    this.number = number;
    this.numberLetter = numberLetter;
    this.numberAddition = numberAddition;
    this.postcode = postcode;
    this.city = city;
    this.country = country;
    this.addressType = addressType;
  }

  public static fromJson(json: unknown): AddressObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AddressObject(
      parsedJson['street'] as string,
      parsedJson['number'] as string,
      parsedJson['numberLetter'] as string,
      parsedJson['numberAddition'] as string,
      parsedJson['postcode'] as string,
      parsedJson['city'] as string,
      parsedJson['country'] as string,
      parsedJson['addressType'] ? CodeValue.fromJson(parsedJson['addressType']) : null,
    );
  }
}
