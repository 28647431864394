import {AbstractMedication} from "@/controllers/AbstractMedication";
import {VariableDosingRegimen} from "@/domain/models/VariableDosingRegimen";
import { store } from "@/vue/store";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export class VariableDosingRegimenController extends AbstractMedication<VariableDosingRegimen> {
  protected storeName = 'mp9/variable_dosing_regimens';
  protected apiEndpoint = 'variable-dosing-regimen';

  public async createDefault(patientId: number, medicationAgreementId: number): Promise<void> {
    try {
      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/create-default/' + medicationAgreementId,
      });

      const drug = response.data['drug'];
      store.commit('drugs/add_to_data', drug, {root: true});

      this.setConceptOnStore(response.data['variableDosingRegimen'], drug);
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error();
    }
  }

  protected fromJson(data: unknown): VariableDosingRegimen {
    return VariableDosingRegimen.fromJson(data);
  }
}

export type VariableDosingRegimenData = AbstractMedicationData<VariableDosingRegimen>
