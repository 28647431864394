import {HealthcareProfessionalObject} from '@/domain/models/HealthcareProfessionalObject';

export class GeneratedHealthcareProfessionalObject { 
  public text: string;
  public firstName: string;
  public lastName: string;

  constructor(text: string, firstName: string, lastName: string) {
    this.text = text;
    this.firstName = firstName;
    this.lastName = lastName;
  }

  public static fromJson(json: unknown): HealthcareProfessionalObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new HealthcareProfessionalObject(
      parsedJson['text'] as string,
      parsedJson['firstName'] as string,
      parsedJson['lastName'] as string,
    );
  }
}
