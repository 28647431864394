import {CodeValue} from '@/domain/models/CodeValue';
import {CodeSystem} from '@/domain/models/CodeSystem';

export class GeneratedCodeValue { 
  public name: string;
  public code: string;
  public description: string;
  public codeSystem: CodeSystem;

  constructor(name: string, code: string, description: string, codeSystem: CodeSystem) {
    this.name = name;
    this.code = code;
    this.description = description;
    this.codeSystem = codeSystem;
  }

  public static fromJson(json: unknown): CodeValue {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new CodeValue(
      parsedJson['name'] as string,
      parsedJson['code'] as string,
      parsedJson['description'] as string,
      CodeSystem.fromJson(parsedJson['codeSystem']),
    );
  }
}
