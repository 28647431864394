<template>
  <medimo-button-link :outline="true" :icon="icon" :transparent="!active" @click="toggleQuickFilter"
                      :padding_class="'py-0 pr-2 pl-2 text-small'"
                      class="quick-filter-button"
  >
    {{ quick_filter_label }}
  </medimo-button-link>
</template>

<script>

import MedimoButtonLink from '../MedimoButtonLink';
import MedimoCheckbox from '@/vue/components/general/form/base/MedimoCheckbox';
import MedimoButton from '../MedimoButton';

export default {
  components: {
    MedimoButtonLink,
    MedimoCheckbox,
    MedimoButton,
  },
  emits: ['toggled'],

  props: {
    'store_name': {default: null}, // Met de store name op null kan deze ook buiten de store om gebruikt worden
    'quick_filter_name': {required: true}, // het_label_is_dan_zo
    'is_active': {default: null}, // En met deze stuur je de state van het icoon e.d.
    'quick_filter_value': {default: null} // By default is dit een boolean, maar het kan optioneel ook een object zijn met keys en values
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    active() {
      if (this.is_active !== null) {
        return this.is_active;
      }
      const filter = this.$store.getters[this.store_name + '/quick_filters'][this.quick_filter_name];
      if (typeof filter === 'object') {
        return filter.value;
      }

      return filter;
    },
    icon() {
      return this.active ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
    },

    /**
     * Als er een custom language is met dezelfde naam, dan gebruiken we die ipv de property
     * Dus stel, current_user.language.alleen_paracetamol bestaat,
     * en de quick_filter hier is "Alleen Paracetamol" of "Alleen paracetamol", dan gebruiken we de waarde in current_user.language
     *
     * @returns {string}
     */
    quick_filter_label() {
      // Sommige entries in custom language kunnen niet gebruikt worden, of het label wat we tonen moet specifiek anders dan wat we naar de backend sturen.
      // In dat geval kunnen we met de label property van een quick_filter de default behaviour overschrijven
      if (typeof this.quick_filter_value.label !== 'undefined') {
        return this.quick_filter_value.label;
      }

      // By default de quickfilter
      let label = this.quick_filter_name;

      // Loop door label om te vertalen
      const labelParts = [];
      for (let part of label.split(' ')) {
        // Label veranderen in snake_case, zodat hij overeenkomt met de waarden in de CurrentUserResource->language
        const language_key = part.toLowerCase().replace(' ', '');

        // Als een vergelijkbare bestaat in de language array, gebruiken we die
        if (typeof this.$store.getters['current_user/language'][language_key] !== 'undefined') {
          part = this.$store.getters['current_user/language'][language_key];
        }

        labelParts.push(part);
      }

      label = labelParts.join(' ');

      // Uppercase first letter
      return label.charAt(0).toUpperCase() + label.slice(1);
    },
    current_user() {
      return this.$store.state.current_user.data;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    toggleQuickFilter() {
      if (this.store_name !== null) {
        this.$store.commit(this.store_name + '/toggle_quick_filter', this.quick_filter_name);
        return true;
      }
      this.$emit('toggled');
    }
  },

  watch: {
    //
  }
};
</script>
