<template>
  <medimo-form class="data-viewer">
    <json-tree v-if="hasJsonStructure"
               :withSearch="withSearch"
               :auto-collapse-after="autoCollapseAfter"
               :raw-data="rawData"
    ></json-tree>
    <xml-tree v-else-if="isXML"
              :withSearch="withSearch"
              :auto-collapse-after="autoCollapseAfter"
              :raw-data="rawData"
    ></xml-tree>
    <!-- Als hij met niks matched tonen we gewoon formatted text -->
    <pre v-else>{{ rawData }}</pre>
  </medimo-form>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import JsonTree from './Json/JsonTree';
import XmlTree from './Xml/XmlTree';
import MedimoTextarea from '@/vue/components/general/form/base/MedimoTextarea';

export default {
  components: {MedimoTextarea, XmlTree, JsonTree},

  props: {
    rawData: {type: String, required: true},
    // Deze zijn nodig om de filtering ook recursive werkend te krijgen
    withSearch: {default: true},
    queryPlaceholder: {default: 'Zoek...'},
    queryValue: {default: ''},

    // Collapsed de regels als ze 3 niveaus + diep zijn. De root telt al voor 1
    autoCollapseAfter: {default: 3},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      query: '',
      onlyShowMatches: true,
      fadeMatchContent: true,
    };
  },

  computed: {
    hasJsonStructure() {
      if (typeof this.rawData !== 'string') return false;
      try {
        const result = JSON.parse(this.rawData);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
      } catch (err) {
        return false;
      }
    },
    isXML() {
      if (this.rawData === '') {
        return false;
      }
      try {
        $.parseXML(this.rawData); //is valid XML
        return true;
      } catch (error) {
        return false;
      }
    }
  },
};
</script>
