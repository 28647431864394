import {GeneratedMedicationAgreement} from '@/domain/models/generated/GeneratedMedicationAgreement';
import moment from "moment";
import SnomedCt from "@/enums/SnomedCt";

export class MedicationAgreement extends GeneratedMedicationAgreement { 
  public isActive(): boolean {
    if (moment(this.startDateTime).isBefore(moment()) && this.endDateTime === null) {
      return true;
    } else if (moment(this.startDateTime).isBefore(moment()) && moment(this.endDateTime).isAfter(moment())) {
      return true;
    }

    return false;
  }

  public definitiveStop(): boolean {
    if (this.periodOfUse?.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.DEFINITIVE_STOPPED;
  }

  public temporaryStop(): boolean {
    if (this.periodOfUse?.endDateTimeOrDuration() === null) {
      return false;
    }

    return this.stopType?.code === SnomedCt.TEMPORARY_STOPPED;
  }
}
