<script>
import NavSearch from '@/vue/components/nav/NavSearch';
import BreadCrumbsContainer from '@/vue/components/nav/BreadCrumbsContainer';
import BreadCrumbs from '@/vue/components/nav/BreadCrumbs';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedimoNotificationsDropdown from '@/vue/components/nav/MedimoNotificationsDropdown';
import AdminSidePanel from './AdminSidePanel';

export default {
  // Nodig voor Vue3 "inline" template
  template: `
    <div v-bind="$attrs">
      <slot v-bind="self"/>
    </div>`,

  components: {
    NavSearch,
    BreadCrumbsContainer,
    BreadCrumbs,
    MedimoNotificationsDropdown,
    AdminSidePanel,
  },

  props: ['class', 'style', 'key'],

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      show_user_menu: false,
      loading_cluster_selector: false,
      highContrast: null
    };
  },

  computed: {
    // This one is mandatory since Vue3 deprecated the inline templates. This is a smart
    // work-around through which we can use v-slot="vm" and then reference vm.property
    // in the header.blade.php
    self() {
      return this;
    },
    current_user() {
      return this.$store.state.current_user.data;
    },
    showClusterSelector: {
      get() {
        return this.$store.state.settings.navigation.showClusterSelector;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowClusterSelector', value);
      }
    },
    navSearchNavigationIsOpen() {
      return this.$store.state.settings.navigation.navSearchNavigation;
    },
    showAdminSidePanel: {
      get() {
        return this.$store.state.settings.navigation.adminPanel.show;
      },
      set(value) {
        this.$store.commit('settings/navigation/setAdminPanel', {property: 'show', value: value});
      }
    },
    showLogo: {
      get() {
        return this.$store.state.settings.navigation.showLogo;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowLogo', value);
      }
    },
  },
  created() {
    this.highContrast = window.medimo.getCookie('high-contrast') === 'true';
  },
  mounted() {
    //
  },
  methods: {
    hideClusterSelector() {
      if (this.showClusterSelector) {
        this.showClusterSelector = false;
      }
    },
    toggleContrast() {
      window.medimo.toggleContrast();
      this.highContrast = window.medimo.getCookie('high-contrast') === 'true';
    }
  },

  watch: {
    //
  }
};
</script>
