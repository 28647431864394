import {QuantityObject} from '@/domain/models/QuantityObject';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedQuantityObject { 
  public value: number;
  public code: CodeObject | null;

  constructor(value: number, code: CodeObject | null = null) {
    this.value = value;
    this.code = code;
  }

  public static fromJson(json: unknown): QuantityObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new QuantityObject(
      parsedJson['value'] as number,
      parsedJson['code'] ? CodeObject.fromJson(parsedJson['code']) : null,
    );
  }
}
