import {GeneratedMedicationUse} from '@/domain/models/generated/GeneratedMedicationUse';
import moment from "moment";

export class MedicationUse extends GeneratedMedicationUse {
  public isActive() {
    if (moment(this.startDateTime).isBefore(moment()) && this.endDateTime === null) {
      return true;
    } else if (moment(this.startDateTime).isBefore(moment()) && moment(this.endDateTime).isAfter(moment())) {
      return true;
    }

    return false;
  }
}
