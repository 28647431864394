import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedTimeIntervalObject { 
  public startDateTime: string | null;
  public endDateTime: string | null;
  public duration: QuantityObject | null;
  public condition: string | null;

  constructor(startDateTime: string | null = null, endDateTime: string | null = null, duration: QuantityObject | null = null, condition: string | null = null) {
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.duration = duration;
    this.condition = condition;
  }

  public static fromJson(json: unknown): TimeIntervalObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new TimeIntervalObject(
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      parsedJson['duration'] ? QuantityObject.fromJson(parsedJson['duration']) : null,
      parsedJson['condition'] as string,
    );
  }
}
