// initial state
import Base_module from "@/vue/store/modules/base_module";

const state = {
  store_namespace: 'medication_overview',
  ...Base_module.state
};

// getters
const getters = {
  ...Base_module.getters
};

// actions
const actions = {
  ...Base_module.actions
};

// mutations
const mutations = {
  ...Base_module.mutations
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
