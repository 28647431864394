// This file is autogenerated. It contains all icons used in this project. You can regenerate this file via `npm run update-icons`. After regenerating this file, execute `npm run prod`
// Your icons should have the following format in order to be found: <fa-icon icon='fa-(duotone|light|regular|solid|thin|kit) (fa-sharp )*fa-[a-z-]+' />
// To speed things up, the script currently only checks the app and resources directories. If your icon exists in another directory, feel free to add it as well.

import {library} from '@fortawesome/fontawesome-svg-core';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

import {faBell as fadBell, faBellRing as fadBellRing, faCalendarDays as fadCalendarDays, faClock as fadClock, faFileExclamation as fadFileExclamation, faFloppyDisk as fadFloppyDisk, faHatWizard as fadHatWizard, faMessages as fadMessages, faRotateLeft as fadRotateLeft} from 'fontawesome/pro-duotone-svg-icons';
import {faCapsule as fakCapsule} from '@/vue/icons/custom_icons';
import {faCalendarAlt as falCalendarAlt, faCalendarDays as falCalendarDays, faFileLines as falFileLines} from 'fontawesome/pro-light-svg-icons';
import {faArrowRightFromBracket as farArrowRightFromBracket, faArrowsMaximize as farArrowsMaximize, faBuilding as farBuilding, faCircle as farCircle, faCircleCheck as farCircleCheck, faCircleDot as farCircleDot, faCircleExclamation as farCircleExclamation, faCircleInfo as farCircleInfo, faCircleNodes as farCircleNodes, faClipboardCheck as farClipboardCheck, faClock as farClock, faDotCircle as farDotCircle, faEyeSlash as farEyeSlash, faFileCertificate as farFileCertificate, faFiles as farFiles, faHeart as farHeart, faLightbulb as farLightbulb, faMortarPestle as farMortarPestle, faRectangleList as farRectangleList, faShuffle as farShuffle, faSnowflake as farSnowflake, faSquare as farSquare, faStar as farStar, faTriangleExclamation as farTriangleExclamation, faUser as farUser} from 'fontawesome/pro-regular-svg-icons';
import {faQrcode as fasrQrcode} from 'fontawesome/sharp-regular-svg-icons';
import {
  faAdd as fasAdd,
  faAdjust as fasAdjust,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faArrowRightFromBracket as fasArrowRightFromBracket,
  faBan as fasBan,
  faBell as fasBell,
  faBellRing as fasBellRing,
  faBirthdayCake as fasBirthdayCake,
  faBomb as fasBomb,
  faBriefcase as fasBriefcase,
  faBriefcaseMedical as fasBriefcaseMedical,
  faCancel as fasCancel,
  faCaretDown as fasCaretDown,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
  faCaretUp as fasCaretUp,
  faChartSimple as fasChartSimple,
  faCheck as fasCheck,
  faCircleExclamation as fasCircleExclamation,
  faCircleInfo as fasCircleInfo,
  faCircleQuestion as fasCircleQuestion,
  faCircleXmark as fasCircleXmark,
  faClock as fasClock,
  faClockRotateLeft as fasClockRotateLeft,
  faClose as fasClose,
  faDatabase as fasDatabase,
  faDisplay as fasDisplay,
  faDownload as fasDownload,
  faEarth as fasEarth,
  faExclamation as fasExclamation,
  faExclamationTriangle as fasExclamationTriangle,
  faExternalLink as fasExternalLink,
  faFloppyDisk as fasFloppyDisk,
  faGear as fasGear,
  faGraduationCap as fasGraduationCap,
  faHandHoldingMedical as fasHandHoldingMedical,
  faHouse as fasHouse,
  faInbox as fasInbox,
  faKey as fasKey,
  faList as fasList,
  faLocationDot as fasLocationDot,
  faLock as fasLock,
  faMagnifyingGlass as fasMagnifyingGlass,
  faO as fasO,
  faPen as fasPen,
  faPlus as fasPlus,
  faPrint as fasPrint,
  faRemove as fasRemove,
  faReplyAll as fasReplyAll,
  faRetweet as fasRetweet,
  faRotateLeft as fasRotateLeft,
  faSearch as fasSearch,
  faShieldQuartered as fasShieldQuartered,
  faShuffle as fasShuffle,
  faSpinner as fasSpinner,
  faSquareCaretLeft as fasSquareCaretLeft,
  faSquareCaretRight as fasSquareCaretRight,
  faSquareCheck as fasSquareCheck,
  faStar as fasStar,
  faStethoscope as fasStethoscope,
  faTimes as fasTimes,
  faTrashCan as fasTrashCan,
  faUpRightFromSquare as fasUpRightFromSquare,
  faUser as fasUser,
  faUserGroup as fasUserGroup
} from 'fontawesome/pro-solid-svg-icons';
import {faCircleInfo as fassCircleInfo, faMessages as fassMessages} from 'fontawesome/sharp-solid-svg-icons';

library.add(fadBell, fadBellRing, fadCalendarDays, fadClock, fadFileExclamation, fadFloppyDisk, fadHatWizard, fadMessages, fadRotateLeft, fakCapsule, falCalendarAlt, falCalendarDays, falFileLines, farArrowRightFromBracket, farArrowsMaximize, farBuilding, farCircle, farCircleCheck, farCircleDot, farCircleExclamation, farCircleInfo, farCircleNodes, farClipboardCheck, farClock, farDotCircle, farEyeSlash, farFileCertificate, farFiles, farHeart, farLightbulb, farMortarPestle, farRectangleList, fasrQrcode, farShuffle, farSnowflake, farSquare, farStar, farTriangleExclamation, farUser, fasAdd, fasAdjust, fasArrowLeft, fasArrowRight, fasArrowRightFromBracket, fasBan, fasBell, fasBellRing, fasBirthdayCake, fasBomb, fasBriefcase, fasBriefcaseMedical, fasCancel, fasCaretDown, fasCaretLeft, fasCaretRight, fasCaretUp, fasChartSimple, fasCheck, fasCircleExclamation, fasCircleInfo, fasCircleQuestion, fasCircleXmark, fasClock, fasClockRotateLeft, fasClose, fasDatabase, fasDisplay, fasDownload, fasEarth, fasExclamation, fasExclamationTriangle, fasExternalLink, fasFloppyDisk, fasGear, fasGraduationCap, fasHandHoldingMedical, fasHouse, fasInbox, fasKey, fasList, fasLocationDot, fasMagnifyingGlass, fasO, fasPen, fasPlus, fasPrint, fasRemove, fasReplyAll, fasRetweet, fasRotateLeft, fasSearch, fassCircleInfo, fassMessages, fasShieldQuartered, fasShuffle, fasSpinner, fasSquareCaretLeft, fasSquareCaretRight, fasSquareCheck, fasStar, fasStethoscope, fasTimes, fasTrashCan, fasUpRightFromSquare, fasUser, fasUserGroup, fasLock);

export default FontAwesomeIcon;
