import {Ward} from '@/domain/models/Ward';
import {Location} from '@/domain/models/Location';

export class GeneratedWard { 
  public id: number;
  public name: string;
  public deletedAt: string;
  public stR1: string;
  public stR2: string;
  public stR3: string;
  public stR4: string;
  public stR5: string;
  public stR6: string;
  public deadlineTodayMinutes: number;
  public deadlinevandaag: number;
  public createdAt: string;
  public location: Location;

  constructor(id: number, name: string, deletedAt: string, stR1: string, stR2: string, stR3: string, stR4: string, stR5: string, stR6: string, deadlineTodayMinutes: number, deadlinevandaag: number, createdAt: string, location: Location) {
    this.id = id;
    this.name = name;
    this.deletedAt = deletedAt;
    this.stR1 = stR1;
    this.stR2 = stR2;
    this.stR3 = stR3;
    this.stR4 = stR4;
    this.stR5 = stR5;
    this.stR6 = stR6;
    this.deadlineTodayMinutes = deadlineTodayMinutes;
    this.deadlinevandaag = deadlinevandaag;
    this.createdAt = createdAt;
    this.location = location;
  }

  public static fromJson(json: unknown): Ward {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Ward(
      parsedJson['id'] as number,
      parsedJson['name'] as string,
      parsedJson['deletedAt'] as string,
      parsedJson['stR1'] as string,
      parsedJson['stR2'] as string,
      parsedJson['stR3'] as string,
      parsedJson['stR4'] as string,
      parsedJson['stR5'] as string,
      parsedJson['stR6'] as string,
      parsedJson['deadlineTodayMinutes'] as number,
      parsedJson['deadlinevandaag'] as number,
      parsedJson['createdAt'] as string,
      Location.fromJson(parsedJson['location']),
    );
  }
}
