import {AdministrationAgreement} from '@/domain/models/AdministrationAgreement';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {CodeValue} from '@/domain/models/CodeValue';

export class GeneratedAdministrationAgreement { 
  public id: number;
  public description: string;
  public patientId: number;
  public externalIdentifierSystem: CodeSystem;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public pharmaceuticalTreatmentId: number;
  public medicationAgreementId: number | null;
  public supplier: HealthcareProvider;
  public startDateTime: string;
  public endDateTime: string | null;
  public instructionsForUse: InstructionsForUse;
  public periodOfUse: TimeIntervalObject;
  public additionalInformation: CodeObject | null;
  public stopType: CodeValue | null;
  public comment: string | null;
  public reasonModificationOrDiscontinuation: CodeValue | null;

  constructor(id: number, description: string, patientId: number, externalIdentifierSystem: CodeSystem, pharmaceuticalProduct: PharmaceuticalProduct, pharmaceuticalTreatmentId: number, medicationAgreementId: number | null = null, supplier: HealthcareProvider, startDateTime: string, endDateTime: string | null = null, instructionsForUse: InstructionsForUse, periodOfUse: TimeIntervalObject, additionalInformation: CodeObject | null = null, stopType: CodeValue | null = null, comment: string | null = null, reasonModificationOrDiscontinuation: CodeValue | null = null) {
    this.id = id;
    this.description = description;
    this.patientId = patientId;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.medicationAgreementId = medicationAgreementId;
    this.supplier = supplier;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.instructionsForUse = instructionsForUse;
    this.periodOfUse = periodOfUse;
    this.additionalInformation = additionalInformation;
    this.stopType = stopType;
    this.comment = comment;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
  }

  public static fromJson(json: unknown): AdministrationAgreement {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AdministrationAgreement(
      parsedJson['id'] as number,
      parsedJson['description'] as string,
      parsedJson['patientId'] as number,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['medicationAgreementId'] as number,
      HealthcareProvider.fromJson(parsedJson['supplier']),
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['additionalInformation'] ? CodeObject.fromJson(parsedJson['additionalInformation']) : null,
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['comment'] as string,
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
    );
  }
}
