import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';
import moment from 'moment-mini';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,

  data: [],

  // Gebruikt om niet onnodig veel data op te halen
  // Als deze 2 onveranderd zijn, is de dataset ook onveranderd
  last_patient_id: null,
  last_hours_back: null,

  quick_filters: {
    'Toegediend': false,
    'Vergeten': false,
    'Geweigerd': false,
    'Te laat': false,
    'Zonodig': false,
    'Continue': false,
    'Vandaag': false,
  },
  filter_columns: [
    // 'example'
  ],
};

// getters
const getters = {
  ...BaseModule.getters,

  last_patient_id(state, getters, rootState, rootGetters) {
    return state.last_patient_id;
  },
  last_hours_back(state, getters, rootState, rootGetters) {
    return state.last_hours_back;
  },
  toegediend_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.state === 'Toegediend' && ! model.too_late;
      });
    };
  },
  vergeten_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.state === 'Vergeten';
      });
    };
  },
  geweigerd_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.state === 'Geweigerd';
      });
    };
  },
  te_laat_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.too_late;
      });
    };
  },
  zonodig_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.type === 'Zonodig';
      });
    };
  },
  continue_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.type === 'Continue';
      });
    };
  },
  vandaag_quick_filter(state, getters, rootState, rootGetters) {
    return (data, filter_state) => {
      if (!filter_state) return data;

      return data.filter((model) => {
        return model.date === moment().format('YYYY-MM-DD');
      });
    };
  },
};

// actions
const actions = {
  ...BaseModule.actions,
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    const endpoint = '/api/v1/patients/' + payload.patient_id + '/tdr';
    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: payload.data,
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  // dummy_mutation (state, id) {
  //     state.id = id;
  // },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
