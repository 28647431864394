import { store } from '@/vue/store';
import {Patient} from "@/domain/models/Patient";

export class PatientController {
  private storeName = 'patients';

  public async read(patientId: number): Promise<Patient|null> {
    const patient = this.dataFromStore(patientId);

    // When we already have the patient in the store use it.
    if (patient !== null && patient.id === patientId) {
      return Promise.resolve(patient);
    }

    try {
      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/patients/' + patientId,
      });

      return this.setDataOnStore(response.data.data);
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error();
    }
  }

  private setDataOnStore(data: unknown): Patient {
    const patient = Patient.fromJson(data);
    store.commit(this.storeName + '/add_to_data', patient);
    return this.dataFromStore(patient.id);
  }

  private dataFromStore(patientId: number): Patient {
    return store.getters[this.storeName + '/find'](patientId);
  }
}
