<template>
  <medimo-form-row :no_margins="no_margins">
    <medimo-form-column v-if="label.length" :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
      <label :class="'select2 d-block' + ' ' + validation_class" :for="name">
        {{ label }}
      </label>
    </medimo-form-column>
    <medimo-form-column :no_margin="no_margins" :column_classes="inline ? select2_column_classes : 'col-12'">
      <!-- Zo kun je een custom VueSelect labeled maken, zoals een Location of Ward select -->
      <slot v-if="hasSlot" name="custom"></slot>
      <medimo-vue-select2
          v-else
          :id="id"
          :disabled="disabled"
          :name="name"
          :placeholder="placeholder"

          :options="options"
          :inline="inline"
          :multiple="multiple"

          :modelValue="modelValue"
          :loading="loading"

          @update:modelValue="onInput($event)"
          @option:selected="onSelect($event)"
          @option:deselected="onDeselect($event)"

          :extra_classes="extra_classes"
          :validation_class="validation_class"

          :help_text="help_text"
          :minimum_results_for_search="minimum_results_for_search"
          :clearable="clearable"

          v-bind="$attrs"
          :minimum_input_length="minimum_input_length"
      ></medimo-vue-select2>
    </medimo-form-column>
  </medimo-form-row>
</template>

<script>

import MedimoTooltip from '@/vue/components/general/MedimoTooltip';
import MedimoFormRow from './../MedimoFormRow';
import MedimoFormColumn from './../MedimoFormColumn';
import MedimoVueSelect2 from './MedimoVueSelect2';

export default {

  components: {
    MedimoVueSelect2,
    MedimoTooltip,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['update:modelValue','option:selected','option:deselected'],

  props: {
    id: {},
    'no_margins': {default: true},
    'disabled': {default: false},
    'loading': {default: false},
    'inline': {default: true},
    'modelValue': {default: '', type: [String, Number, Array]},
    'options': {
      default: function () {
        return [];
      }
    },
    'minimum_input_length': {default: 3},
    'multiple': {default: false},
    'name': {default: 'select2'},
    'placeholder': {default: 'Maak een keuze'},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},

    'help_text': {type: [String, null], default: ''},
    'label': {default: ''},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},
    'select2_column_classes': {default: 'col'},

    // Set the number of options there need to be for the search to be enabled
    'minimum_results_for_search': {default: 10},
    'clearable': {default: false},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    hasHelpText() {
      return this.help_text.length;
    },
    hasSlot() {
      return !!this.$slots.custom;
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    onInput(value) {
      // To hopefully prevent some hairpulling mistakes in the future we log to the console when
      // the value is a number, and a string, meaning it will start to fail somewhere down the line if someone
      // expects an integer to be returned (it won't, Select2 transforms integers to string numbers)
      // if NOT Not a Number (i.e. is a number, sorry, no other way)
      if (!isNaN(value) && typeof value === 'string') {
        if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
          console.log('Please be wary that MedimoLabeledVueSelect2 is returning the number value as a string:', value);
        }
      }
      this.$emit('update:modelValue', value);
    },
    onSelect(value) {
      this.$emit('option:selected', value);
    },
    onDeselect(value) {
      this.$emit('option:deselected', value);
    }
  },

  watch: {
    //
  }
};
</script>
