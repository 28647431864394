<template>
  <!-- Als het een Element of Text type is, renderen we hem -->
  <div class="json data-tree-branch"
       :style="'margin-left: ' + getMarginValue(layer)"
  >
    <!--
      als de value een integer, float of string is, tonen we hem normaal
    -->
      <div class="dataRow v1" v-if="!hasChildren">
        <div class="key" v-if="jsonKey.length" v-show="show" :style="'opacity: ' + (keyMatchesFilter ? '1' : opacityHidden)">
          {{ jsonKey }}:
        </div>
        <div v-show="show" :style="'opacity: ' + (valueMatchesFilter ? '1' : opacityHidden)">
          <json-data-value
              :json-value="jsonValue"
          ></json-data-value>
        </div>
      </div>
    <!--
      als het een array of object is, gaan we dieper
    -->
    <div class="dataRow v2" v-if="hasChildren">
      <div v-if="layer>1 && hasChildren && show">
        <!-- Dit zijn de collapse icons -->
        <div @click.stop="collapsed=false"
             style="cursor:pointer"
             v-if="collapsed"
        >
          <fa-icon :icon="collapsedIcon" size="lg" />
        </div>
        <!-- Dit zijn de collapse icons -->
        <div @click.stop="collapsed=true"
             style="cursor:pointer"
             v-if="!collapsed"
        >
          <fa-icon :icon="expandedIcon" size="lg" />
        </div>
      </div>
      <div class="key" v-show="show" :style="'opacity: ' + (keyMatchesFilter ? '1' : opacityHidden)" @click.stop="collapsed=!collapsed">
        <span class="data-tree-tag-value">{{ jsonKey }}</span>
      </div>

      <div class="value">
        <!--          <div v-show="show || collapsed" :style="'opacity: ' + (valueMatchesFilter ? '1' : opacityHidden)">-->
        <!--            <div v-if="isArray">[</div>-->
        <!--            <div v-if="isObject">{</div>-->
        <!--          </div>-->
        <div v-show="!collapsed">
          <json-tree-branch v-for="(jsonChildValue, jsonChildKey) in jsonValue"
                            :key="layer + '' + jsonChildKey"
                            :json-key="jsonChildKey"
                              :json-value="jsonChildValue"
                              :only-show-matches="onlyShowMatches"
                              :fade-match-content="fadeMatchContent"
                              :query-value="queryValue"
                              :layer="(layer + 1)"
                              :auto-collapse-after="autoCollapseAfter"
                              :show-match-content="showMatchContent"
                              :parent-matches-filter="matchesFilter || parentMatchesFilter"
            >
            </json-tree-branch>
          </div>
          <div v-show="collapsed" class="clickable" @click.stop="collapsed = false">
            ...
          </div>
          <!--          <div v-show="show || collapsed" :style="'opacity: ' + (valueMatchesFilter ? '1' : opacityHidden)">-->
          <!--            <div v-if="isArray">]</div>-->
          <!--            <div v-if="isObject">}</div>-->
          <!--          </div>-->
        </div>
      </div>

  </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import utility from '@/vue/utility/utility';
import JsonDataValue from './JsonDataValue';

export default {
  name: 'jsonTreeBranch',

  components: {JsonDataValue},

  props: {
    jsonKey: {required: true},
    jsonValue: {required: true},

    autoCollapseAfter: {required: true},
    queryValue: {required:true}, // De zoek input
    onlyShowMatches: {required:true},
    fadeMatchContent: {default: true}, // Content van matches krijgt dan opacity X
    showMatchContent: {default: true}, // Content van matches is zichtbaar
    parentMatchesFilter: {default: false},
    layer: {required:true}, // gebruiken we voor de marge, geeft aan hoe diep we zitten in de recursiveness
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      collapsed: false,
      collapsedIcon: 'fa-solid fa-caret-up',
      expandedIcon: 'fa-solid fa-caret-down',
    };
  },

  computed: {
    opacityHidden() {
      return this.fadeMatchContent ? '0.15' : 1;
    },
    show() {
      if (this.parentMatchesFilter && this.showMatchContent) {
        return true;
      }
      if (this.onlyShowMatches) {
        return this.matchesFilter;
      }
      return true;
    },
    showContent() {
      return this.matchesFilter;
    },
    matchesFilter() {
      return this.keyMatchesFilter || this.valueMatchesFilter;
    },
    hasChildren() {
      return (this.isObject || this.isArray);
    },

    keyMatchesFilter() {
      // Om integers te ondersteunen moeten we er een string van maken
      return this.matchesFilterInput("" + this.jsonKey);
    },
    valueMatchesFilter() {
      if (this.queryValue === '') {
        return true;
      }
      if (this.isArray || this.isObject) {
        return false;
      }
      // Om integers te ondersteunen moeten we er een string van maken
      return this.matchesFilterInput("" + this.jsonValue);
    },

    isArray() {
      return Array.isArray(this.jsonValue);
    },
    isObject() {
      return typeof this.jsonValue === 'object' && !this.isArray && this.jsonValue !== null;
    },
    hasContent() {
      return ((this.jsonValue !== null && this.jsonValue.length) || this.hasChildren) && !this.collapsed;
    },
  },

  created() {
    if (this.layer > this.autoCollapseAfter && this.hasContent) {
      this.collapsed = true;
    }
  },

  mounted() {
    //
  },

  methods: {
    matchesFilterInput(value) {
      if (this.queryValue === '') {
        return true;
      }
      return utility.string_contains_query(value, this.queryValue);
    },
    getMarginValue(layer) {
      if (layer < 3) {
        return '0em;';
      }
      return '1.5em;';
    },
    autoCollapse() {
      if (this.layer > this.autoCollapseAfter && this.hasContent) {
        this.collapsed = true;
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
    }
  },

  watch: {
    queryValue(query) {
      // Als er een query is willen we hem automatisch open klappen, anders geldt de auto-collapse
      if (query.length) {
        this.collapsed = false;
        return;
      }
      this.autoCollapse();
    }
  }
};
</script>
<style lang="scss">
  .json.data-tree-branch {
    display: block;
  }
  // Om de weergave consistent te krijgen en houden zetten we hem hier vast
  // Door hem vervolgens X scrollable te maken is het alsnog overzichtelijk.
  .dataRow {
    width: 350px;
    overflow-x: visible;
    white-space: nowrap;

    div {
      display: inline;
    }

    .key {
      width: 100px;
    }
    .value {
      width: 250px;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
</style>
