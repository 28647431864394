import {PharmaceuticalTreatmentAdministrationAgreementController} from "@/controllers/PharmaceuticalTreatmentAdministrationAgreementController";
import {configureCompat, createApp, nextTick} from 'vue';

import Utility from '@/vue/utility/utility';
import {store} from '@/vue/store';
// // All the Application routes:
// // import VueRouter from 'vue-router';
import {router} from '/vue/routes/auto-route-generator';
import MedimoFooterNav from '@/vue/components/nav/MedimoFooterNav';
import BreadCrumbsContainer from '/vue/components/nav/BreadCrumbsContainer';
import NavSearch from '/vue/components/nav/NavSearch';
import MedimoNotificationsDropdown from '/vue/components/nav/MedimoNotificationsDropdown';
import MedimoTopNav from '@/vue/components/nav/MedimoTopNav';
import FileUpload from '@/vue/components/general/FileUpload';
import ClusterSelector from '@/vue/components/nav/cluster-selector/ClusterSelector';
import PatientInfoSummary from '@/vue/components/patients/PatientInfoSummary';
import MedimoStyle from '@/vue/components/general/layout/MedimoStyle';
import ProtocolBuilder from '@/vue/components/protocol/ProtocolBuilder';
import ManualDosingSchema from '@/vue/components/status/dosage-instructions/manual-dosing-schema/ManualDosingSchema';
import MedimoAlertContainer from '@/vue/components/general/MedimoAlertContainer';
import StatusOverviewMedicationTable from '@/vue/components/status/status-overview-modal/StatusOverviewMedicationTable';
import CombinedStatusColumns from '@/vue/components/status/combined/CombinedStatusColumns';
import MfbProtocolViewer from '@/vue/components/mfb/MfbProtocolViewer';
import ProtocolViewerModal from '@/vue/components/status/medication-guard/ProtocolViewerModal';
import MedimoDataViewer from '@/vue/components/data-viewers/MedimoDataViewer';
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import MedimoUnsavedChangesModal from '@/vue/components/general/layout/MedimoUnsavedChangesModal';
import NavigationController from "@/vue/controllers/NavigationController";

// Vue Bootstrap
import ChecksIfVuePage from '@/vue/mixins/navigation/ChecksIfVuePage';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import AdminSidePanel from '@/vue/components/nav/AdminSidePanel';
// Load the WebSocket and NavigationController into an instance to inject in all components
import InstanceContainer from '@/vue/utility/InstanceContainer';
import {MedicationUseController} from "@/controllers/MedicationUseController";
import {InitialDataController} from "@/controllers/InitialDataController";
import {MedicationOverviewController} from "@/controllers/MedicationOverviewController";
import {PatientController} from "@/controllers/PatientController";
import {MedicationAgreementController} from "@/controllers/MedicationAgreementController";
import {AdministrationAgreementController} from "@/controllers/AdministrationAgreementController";
import {MedicationUseVerificationController} from "@/controllers/MedicationUseVerificationController";
import {VariableDosingRegimenController} from "@/controllers/VariableDosingRegimenController";
import {PharmaceuticalTreatmentMedicationAgreementController} from "@/controllers/PharmaceuticalTreatmentMedicationAgreementController";
import {PharmaceuticalTreatmentVariableDosingRegimenController} from "@/controllers/PharmaceuticalTreatmentVariableDosingRegimenController";
import {PharmaceuticalTreatmentMedicationUseController} from "@/controllers/PharmaceuticalTreatmentMedicationUseController";
import {DosingInstructionController} from "@/controllers/DosingInstructionController";

// Sentry
import {JsUncatchedError, ManualVueSentry, VueError, VueWarning} from '@/vue/utility/errors';
import * as Sentry from '@sentry/vue';
import {BrowserTracing} from '@sentry/tracing';
import ScriptTagTracker from "@/modules/ScriptTagTracker.js";

// Add SweetAlert2 to a $swal property so every component can access it.
// import Swal from 'sweetalert2/dist/sweetalert2.js';
import moment from 'moment-mini';
import localization from '@/vue/components/general/form/date-time-picker/nl-locale';
import stickybits from 'stickybits';
import Icons from '@/vue/icons/icons';
import {Vue3Mq} from "vue3-mq";
import {isNavigationFailure, NavigationFailureType} from 'vue-router';
import draggable from '/vue/directives/Draggable';
import ClickOutside from '/vue/directives/ClickOutside';
import MedimoLocalDataTable from "@/vue/components/general/table/MedimoLocalDataTable.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";

require('@/../scss/app.scss');
require('/vue/bootstrap');

// Initiate Websocket instance, WebSocketConnector is loaded through medimo.js, io is loaded through vendor.js.
// Nice to know: window.WebSocket is a native js function in at least Chrome and Safari.
const token = document.head.querySelector('meta[name=ws-token]').content;
window.medimo.WebSocket = new window.WebSocketConnector(window.io, token);

const instanceContainer = new InstanceContainer();
instanceContainer.register('WebSocket', window.medimo.WebSocket);
instanceContainer.register('NavigationController', new NavigationController(router));
instanceContainer.register('MedicationUseController', new MedicationUseController());
instanceContainer.register('MedicationAgreementController', new MedicationAgreementController());
instanceContainer.register('AdministrationAgreementController', new AdministrationAgreementController());
instanceContainer.register('InitialDataController', new InitialDataController());
instanceContainer.register('MedicationAgreementController', new MedicationAgreementController());
instanceContainer.register('MedicationOverviewController', new MedicationOverviewController());
instanceContainer.register('MedicationUseController', new MedicationUseController());
instanceContainer.register('MedicationUseVerificationController', new MedicationUseVerificationController());
instanceContainer.register('NavigationController', new NavigationController(router));
instanceContainer.register('PatientController', new PatientController());
instanceContainer.register('PharmaceuticalTreatmentMedicationAgreementController', new PharmaceuticalTreatmentMedicationAgreementController());
instanceContainer.register('PharmaceuticalTreatmentMedicationUseController', new PharmaceuticalTreatmentMedicationUseController());
instanceContainer.register('PharmaceuticalTreatmentVariableDosingRegimenController', new PharmaceuticalTreatmentVariableDosingRegimenController());
instanceContainer.register('PharmaceuticalTreatmentAdministrationAgreementController', new PharmaceuticalTreatmentAdministrationAgreementController());
instanceContainer.register('VariableDosingRegimenController', new VariableDosingRegimenController());
instanceContainer.register('DosingInstructionController', new DosingInstructionController());
instanceContainer.register('WebSocket', window.medimo.WebSocket);

moment.updateLocale('nl', localization.get());

window.medimo.debugOtp = [];
// window.medimo.debugPrescribe = {
//   logClick: function (message) {
//     if (this.isParnassia()) {
//       Sentry.addBreadcrumb({
//         category: 'ui.click',
//         message: 'Debugging Voorschrijven: ' + message,
//       });
//     }
//   },
//   logKey: function (message) {
//     if (this.isParnassia()) {
//       Sentry.addBreadcrumb({
//         category: 'ui.key',
//         message: 'Debugging Voorschrijven: ' + message,
//       });
//     }
//   },
//   logInput: function (message) {
//     if (this.isParnassia()) {
//       Sentry.addBreadcrumb({
//         category: 'ui.input',
//         message: 'Debugging Voorschrijven: ' + message,
//       });
//     }
//   },
//   isDebugging: function () {
//     return document.location.hostname === 'ggz.medimo.nl' && store?.state?.current_user?.data?.cluster_id === 100000241;
//   }
// };

const app = createApp({
  components: {
    MedimoTopNav,
    PatientInfoSummary,
    MedimoStyle,
    MedimoFooterNav,
    ProtocolBuilder,
    ManualDosingSchema,
    MedimoAlertContainer,
    StatusOverviewMedicationTable,
    ClusterSelector,
    MfbProtocolViewer,
    CombinedStatusColumns,
    ProtocolViewerModal,
    AdminSidePanel,
    FileUpload,
    MedimoDataViewer,
    BreadCrumbsContainer,
    NavSearch,
    MedimoNotificationsDropdown,
    MedimoUnsavedChangesModal,
    MedimoLocalDataTable,
    MedimoThead,
    MedimoTbody,
    MedimoTh,
    MedimoTr,

    // Way to Async Load Components that can be used in Laravel Blades. Since we don't want general components loaded everywhere
    // 'ComponentName': () => import('/vue/components/ComponentName.vue'),
  },

  data() {
    return {
      lastConsoleError: '',
    };
  },

  mixins: [
    BaseComponentsMixin,
    ChecksIfVuePage,
  ],

  provide: {
    'instanceContainer': instanceContainer,
  },

  beforeMount() {
    Sentry.addBreadcrumb({
      message: 'before mount app.js',
    });
  },

  beforeCreate() {
    Sentry.addBreadcrumb({
      message: 'before create app.js',
    });
  },

  mounted() {
    Sentry.addBreadcrumb({
      message: 'mounting app.js',
    });

    // Log current_user store mutations to debug otp-wall issue.
    this.$store.subscribe((mutation, state) => {
      if (mutation.type.startsWith('current_user/')) {
        window.medimo.debugOtp.push({
          mutation: mutation.type,
          mutationRaised: mutation.payload.otp_wall_raised,
          stateRaised: state.current_user.data.otp_wall_raised,
          dateTime: new Date().toISOString(),
          url: this.$route.fullPath,
        });

        if (window.medimo.debugOtp.length > 20) {
          window.medimo.debugOtp.shift(); // keep it from getting too big
        }
      }
    });

    this.$store.subscribeAction((action, state) => {
      if (action.type.startsWith('current_user/')) {
        window.medimo.debugOtp.push({
          action: action.type,
          returnUrl: action.payload,
          stateRaised: state.current_user.data.otp_wall_raised,
          dateTime: new Date().toISOString(),
          url: this.$route.fullPath,
        });

        if (window.medimo.debugOtp.length > 20) {
          window.medimo.debugOtp.shift(); // keep it from getting too big
        }
      }
    });

    // We need to use a non-standard way of passing data into the root object via the root component
    // This way it works on VueRouter pages, and Laravel Blade pages alike
    // The standard props won't work. After a bit of trial and error, this puts the CurrentUserResource in
    // the user store on every Hard pageload. If user data isn't up-to-date, this means an F5 or CMD+R would fix it
    //
    // The alternative; requesting this data on each Vue-router pageload or after a blade render via an API call
    // Would potentially put too much strain on the server.
    const dataCurrentUserAttribute = this.$el.parentNode.attributes['data-current-user'];
    if (typeof dataCurrentUserAttribute === 'object') {
      // This value will be an empty string on the login pages, or an invalid JSON string
      const encodedCurrentUserData = dataCurrentUserAttribute.value;
      try {
        if (encodedCurrentUserData.length > 0) {
          const currentUserData = JSON.parse(atob(encodedCurrentUserData));
          // Overwrite backend mobile check, MobileDetect isn't dependable enough any more
          currentUserData.RLpda = !Utility.clientScreenIsLarge() && currentUserData.RLwindows === 'no' ? 'yes' : 'no';
          // Finally, if it's valid, we add it to the store
          this.$store.commit('current_user/set_data', currentUserData);

          // We willen de defaults altijd removen als we net ingelogd zijn
          // Anders kan er bij een nieuwe release nog oude data in de session storage zitten die mogelijk voor errors zorgt
          const latestSessionLogin = this.$store.getters['current_user/data'].latest_session_login;
          if (latestSessionLogin !== null) {
            // Binnen 5 seconden na inloggen clearen we alle default table settings
            if (moment().diff(moment(latestSessionLogin), 'seconds') <= 5) {
              this.$store.commit('settings/data_tables/clear_default_settings');
            }
          }

          // Add news
          this.$store.commit('notifications/set_data', currentUserData.notifications);
        }
      } catch (e) {
        this.sendSentry(e, 'CurrentUserData parse exception: ' + e, {currentUserData: encodedCurrentUserData});
        this.$store.dispatch('notifications/addDangerAlert', {message: 'Ongeldige gebruikersdata. Neem contact op met support.'});
      }

      // Listen for notifications through websocket...
      this.$store.dispatch('application/websocket/initialize');
      this.$store.dispatch('application/online_check/initialize');
    }

    // We need to clear the data in the localstorage if the user logs out, or when they are forced to logout due to keepalive
    // In both instances they will be redirected to /login, so we'll do the cleanup there
    this.clearStorage();

    Sentry.addBreadcrumb({
      message: 'mounted app.js',
    });
  },

  watch: {
    '$route': {
      deep: true,
      handler: function (current_route) {
        // Make sure to clear the error bag when switching pages / views
        this.$store.commit('api/clear_error_bag');

        // And close the Cluster Selector...
        this.$store.commit('settings/navigation/setShowClusterSelector', false);

        this.$store.commit('initial_data/delete');
      }
    },
    '$mq': {
      handler(value) {
        // Use this one to trigger stuff if the browser goes over a different media query
      }
    },
    number_of_modals_open(amount) {
      // Als het aantal modals open groter wordt dan 0 dan willen we alle v2 keyboard navigation
      // blokkeren door die window waarde aan te passen. De hook was er, dus dan maken we daar maar gebruik van :)
      // Als dit er niet was dan ging de objectList keyboard navigatie nog steeds door op de achtergrond
      window.medimo.KeyboardNavigation.enabled = amount <= 0;
    },
    hasUnsavedChanges(value) {
      if (value) {
        // We initializen 'm alleen als hij true wordt, om resources te besparen
        window.addEventListener('beforeunload', this.beforeWindowUnload);
      } else {
        // Triggert op een v2 > v2, v3 > v2 en v3 > v2 navigatie
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
      }
    }
  },

  created() {
    Sentry.addBreadcrumb({
      message: 'creating app.js',
    });

    // Stickybits is een "position: sticky" polyfill zodat dezelfde functionaliteit in IE11 beschikbaar is
    // Zonder deze regel kunnen IE11 gebruikers MedimoAlerts niet zien als ze op een wat langere pagina naar
    // onder gescrollt zijn
    // Repo: https://github.com/yowainwright/stickybits
    // Origineel: https://github.com/dollarshaveclub/stickybits
    stickybits('.sticky-element');

    window.addEventListener('keydown', this.shortcutKeys);

    store.commit('settings/navigation/clearAvailableTableRows');

    Sentry.addBreadcrumb({
      message: 'created app.js',
    });
  },

  unmount() {
    //
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.shortcutKeys);
  },

  computed: {
    store() {
      return this.$store; // Nodig om in window.app de store te accessen
    },
    navSearchNavigation: {
      get() {
        return this.$store.state.settings.navigation.navSearchNavigation;
      },
      set(value) {
        this.$store.commit('settings/navigation/setNavSearchNavigation', value);
      }
    },
    showClusterSelector() {
      return this.$store.state.settings.navigation.showClusterSelector;
    },
    showUnconfirmedChangesModal() {
      return this.$store.getters['settings/navigation/showUnsavedChangesModal'];
    },
    pathname() {
      let path = window.document.location.pathname + window.location.search;
      if (this.currentPageIsVuePage) {
        path = this.$route.fullPath;
      }
      return encodeURIComponent(path);
    },
    number_of_modals_open() {
      return this.$store.state.settings.modals.openMediModalUids.length;
    },
    hasUnsavedChanges() {
      return this.$store.getters['settings/navigation/hasUnsavedChanges'];
    }
  },


  methods: {
    notBeingUsedMethod() {
      console.log('notBeingUsedMethod'); // For treeshaking test
    },
    redirectIfNotLoggedIn() {
      // Check if the user is logged in.
      // Redirect to /login when not.
    },
    beforeWindowUnload(event) {
      if (this.hasUnsavedChanges && !this.allowNavigationOnUnsavedChanges()) {
        // Don't block on zorgid reject logout (set in scripts/zorg-id/handle_logout.js)
        // Don't block on keepalive's expired session redirect.
        // Dit werkt alleen als er interactie met de pagina is geweest.
        // Anders blocked alle grote browsers de preventDefault om van de
        // popup spammers te dwarsbomen
        // Het werkt _niet_ icm een dispatch en custom  modal
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#browser_compatibility
        event.preventDefault(); // This triggers a confirmation dialog: https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        event.returnValue = '';
      }
    },
    allowNavigationOnUnsavedChanges() {
      return window.medimo.allowNavigationOnUnsavedChanges === true;
    },
    shortcutKeys(event) {
      let doGoBack = true;

      // If we press ddd, we check for the element
      if (event.keyCode === 112) {
        event.preventDefault();
        this.navSearchNavigation = true;
      }

      // If we press backspace, we check for the element
      if (event.keyCode === 8) {
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
          // It's Firefox, we don't need to enable the back navigation since that's hardcoded
          return null;
        }

        const eventElement = event.srcElement || event.target;
        // When we're in an <input> or <textarea> element, we don't want to go back.
        if (eventElement.tagName.toUpperCase() === 'INPUT' || eventElement.tagName.toUpperCase() === 'TEXTAREA') {
          doGoBack = eventElement.readOnly || eventElement.disabled;
        }
        // When we're in a contenteditable element, we don't want to go back.
        else if ((eventElement.attributes['contentEditable'] && eventElement.attributes['contentEditable'].value === 'true')) {
          doGoBack = false;
        }
        // Some of our own custom elements should be ignored as well
        if (eventElement.classList.contains('nav-search-link')) {
          doGoBack = false;
        }

        if (doGoBack) {
          this.$router.go(-1);
          return false;
        }
      }
    },
    /**
     * Fired when a user clicks hits the /login page after keepalive or logout
     */
    clearStorage() {
      // Moet met window want op v2 geen router.
      // TODO: How to do this as well for SSO login?
      if (window.location.pathname === '/login') {
        this.$store.commit('settings/breadcrumbs/clear_breadcrumbs');
        this.$store.commit('settings/breadcrumbs/clear_patients');
        this.$store.commit('current_user/set_show_status_switch_table_tooltip', true);
        this.$store.commit('current_user/set_show_status_patient_selector_tooltip', true);
        this.$store.commit('current_user/set_show_advanced_filters_tooltip', true);
      }
    },


    /*
       |--------------------------------------------------------------------------
       | Error Handling Functions
       |--------------------------------------------------------------------------
       |
       |
       |
       |
       */
    handleError(errorMsg, sendAlert = true) {
      // Op development geen redirect, maar tonen we wel in de console.
      if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
        nextTick(() => {
          const consoleErrorMsg = errorMsg.toString();

          if (this.lastConsoleError !== consoleErrorMsg) {
            this.lastConsoleError = consoleErrorMsg;

            if (sendAlert) {
              store.dispatch('notifications/addDangerAlert', {message: 'Check Console: ' + consoleErrorMsg});
            }

            // En na 1 seconde (of een page refresh) resetten we hem weer
            setTimeout(() => {
              this.lastConsoleError = '';
            }, 1000);
          }
        });

        // console.error(errorMsg);
        return Promise.resolve('Done');
      }
      // return this.$router.push('/sentry').then(() => {
      //   this.$store.commit('settings/errors/add_page_error', {
      //     status: 500,
      //     message: 'Er is iets mis gegaan',
      //   });
      // });
      // We disablen de redirect voor nu nog even om te kijken wat de impact is.
      return Promise.resolve('Whoops skipped');
    },
    sendSentry(error, message = null, setExtra = {}) {
      Object.keys(setExtra).forEach(key => {
        scope.setExtra(key, setExtra[key]);
      });
      if (typeof error === 'string') {
        error = new Error(error);
      }
      if (message === null) {
        message = error.message;
      }

      const manualVueSentry = new ManualVueSentry(error, message);
      return setScopeAndSend(manualVueSentry, error, message);
    },
  }
}
);

/*
 |--------------------------------------------------------------------------
 | Usage van plugins / modules
 |--------------------------------------------------------------------------
 |
 |
 |
 |
 */
window.medimo.sentryLimitMap = {};
Sentry.init({
  app,
  dsn: document.getElementById('sentry-script-tag').getAttribute('data-sentry-dns'),
  trackComponents: true,
  debug: false,
  attachStacktrace: true,
  attachProps: true,
  sendClientReports: false, // Dit gebruiken we niet en zorgt voor veel onnodige load op de sentry.medimo.nl server
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  // tracesSampleRate: 1.0,
  beforeSend: (event, hint) => {
    try {
      // We limiten hier het aantal events dat de client kan sturen.
      // Anders krijg je de situatie dat als er een error zit in een Row component
      // en er 10 sentries worden verstuurd bij 1 Table load.
      let msg = event?.message;
      if (msg === null || msg === undefined) {
        msg = '';
      }

      if (typeof hint.originalException !== 'undefined') {
        if (typeof hint.originalException.message !== 'undefined') {
          msg = hint.originalException.message;
        }
      }

      const maxMinutesOld = 10;

      // We rebuilden hem even zodat altijd alle sentries in localStorage gaan met timestamp
      // als ze nog niet bestaan. Op die manier is het goed te testen of ze maar 1x verzonden worden
      let mapKey = null;
      Object.keys(window.medimo.sentryLimitMap).forEach(key => {
        if (key.includes(msg)) {
          mapKey = key;
        }
      });

      // Nu checken we of hij de afgelopen 24 uur niet al verstuurd is
      if (msg && window.medimo.sentryLimitMap[mapKey] !== undefined) {
        const lastSent = window.medimo.sentryLimitMap[mapKey];

        // De waarde van de msg is altijd de tijd dat hij het laaste verstuurd is
        // Als dat langer dan 24 uur geleden is gaan we door
        if (moment().diff(lastSent, 'minutes') < maxMinutesOld) {
          // Dan skippen we 'm
          if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
            console.log('Rate limiting activated (once per 24 hours) for', msg);
          }
          return null;
        }
      }

      // Nu gaan we de huidige en oude Sentries clearen als ze ouder dan de max tijd zijn
      Object.keys(window.medimo.sentryLimitMap).forEach(key => {
        // Als hij een Sentry key is, en ouder dan 24 uur, verwijderen we ze - allemaal
        if (moment().diff(window.medimo.sentryLimitMap[key], 'minutes') >= maxMinutesOld) {
          delete window.medimo.sentryLimitMap[key];
        }
      });

      // En we setten hem voor de volgende keer:
      const timeSent = moment().format('YYYY-MM-DD HH:mm');
      const timeSentSeconds = moment().format('YYYY-MM-DD HH:mm:ss');
      window.medimo.sentryLimitMap[msg + ':' + timeSentSeconds] = timeSent;

      // Geleend van https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/
      // Deze oneliner zet een string om in een hash, deze wordt vervolgens geconcatenate met de timestamp om een zo
      // uniek mogelijke LOGID te genereren
      const JSLOGID = Date.now().toString() + msg.split('').reduce(function (a, b) {
        a = ((a << 5) - a) + b.charCodeAt(0);
        return a & a;
      }, 0).toString();
      // scope.setExtra('JS.LOGID', JSLOGID);
      if (typeof event.tags !== 'undefined') {
        event.tags['JS.LOGID'] = JSLOGID;
      } else {
        event.tags = {'JS.LOGID': JSLOGID};
      }

      // Als hij hier komt wordt hij naar de server verstuurd en slaan we de id dus op
      window.app.$store.commit('settings/errors/set_sentry_event_id', JSLOGID);

      // Sentries worden automatisch gegroupeerd op basis van een fingerprint, maar die
      // is verre van ideaal als het om uncatched errors gaat, die gooit hij op 1 hoop terwijl
      // het juist _die_ errors zijn die we in detail willen zien.
      // https://docs.sentry.io/platforms/javascript/data-management/event-grouping/sdk-fingerprinting/
      event.fingerprint = [
        '{{ default }}',
        hint.originalException,
      ];
    } catch (_) {
      // make sure we still send the even when this all breaks somehow..
    }
    return event;
  },
});

// Set the Sentry scope
const scope = new Sentry.Scope();
scope.setTag('logger', 'javascript');
scope.setTag('framework', 'vue');

window.medimo.scriptTagTracker = new ScriptTagTracker(Sentry);

window.onerror = function (message, source, lineno, colno, error) {
  console.log('Exception: ', error);
};

app.use(Vue3Mq, {
  breakpoints: {
    // THESE NEED TO BE IDENTICAL TO THE $grid-breakpoints IN scss/medimo/variables.scss
    xs: 0,
    sm: 576,
    md: 769,
    lg: 1250,
    xl: 1500,
  },
});

router.afterEach((to, from, failure) => {
  // When not duplicated or cancelled
  if (typeof failure !== 'undefined') {
    /**
     * Deze 3 kunnen we silencen
     * An aborted navigation is a navigation that failed because a navigation guard returned `false` or called `next(false)`
     * A cancelled navigation is a navigation that failed because a more recent navigation finished started (not necessarily finished).
     * A duplicated navigation is a navigation that failed because it was initiated while already being at the exact same location.
     */
    if (!isNavigationFailure(failure, NavigationFailureType.duplicated | NavigationFailureType.cancelled | NavigationFailureType.aborted)) {
      throw failure;
    }
  }
});

// Vue.use(VueRouter);
// This is a global route hook that's triggered _before_ a user is going to the next page.
// Original implementation was to store the next_route for a hard navigation on missing JS chunks.
// But could be used for other things in the future.
// If you want to hook into the nagivation cycle _after_ the navigation has occurred, use the $route watcher in this file.
router.beforeEach((to, from, next) => {
  store.commit('settings/errors/clear_sentry_event_id');
  store.commit('routes/set_next_route', to);
  // Only when the path changes, do we want to clear these rows
  // If not, when appending a query to the url and doing a replace, will clear the table selections
  if (to.path !== from.path) {
    store.commit('settings/navigation/clearAvailableTableRows');
  }

  return store.dispatch('settings/navigation/checkForUnsavedChanges', to).then(() => {
    // nodig voor de navigated_deeper logica
    store.dispatch('settings/breadcrumbs/add_breadcrumb_trail', {path: to.fullPath, title: ''});
    next();
  }).catch((error) => {
    // Deze zorgt er voor dat de URL in de browser bar onveranderd blijft
    next(false);
    // Deze zorgt er voor dat hij netjes aborted wordt zonder uncatched exception
    return false;
  });
});

/*
 |--------------------------------------------------------------------------
 | Error Handling
 |--------------------------------------------------------------------------
 |
 | Hieronder vangen we 3 soorten errors af. De warn en errorHandler komen van Vue
 | De onunhandledrejection is een global method voor als er buiten Vue om dingen
 | stuk gaan, dat we die toch afvangen, en een Sentry krijgen.
 |
 |
 */
import sourceMappedStackTrace from '@/scripts/console-capture/sourcemapped-stacktrace';

async function setScopeAndSend(exception, error, trace = '', errorMsg = '') {
  scope.setExtra('Vue Trace', error + ' ' + trace);

  try {
    const userData = store.getters['current_user/data'];
    scope.setUser(userData);
    const breadCrumbsData = store.getters['settings/breadcrumbs/breadcrumb_trail'];
    scope.setExtra('Medimo Breadcrumbs', breadCrumbsData);
    const sentryData = store.getters['sentry/data'];
    scope.setExtra('Medimo Sentry Store Data', sentryData);
    scope.setExtra('Vue Error Stack', error.stack);
    if (error.stack) {
      await sourceMappedStackTrace.mapStackTrace(error.stack, async function (mappedStack) {
        mappedStack = mappedStack.join("\n");
        scope.setExtra('Vue Error MappedStack', mappedStack);
        return Sentry.captureException(exception, scope);
      });
    }
    else {
      return Sentry.captureException(exception, scope);
    }
  } catch (error) {
    scope.setExtra('setScopeAndSend error', error.message);
    return Sentry.captureException(exception, scope);
  }
}

app.config.errorHandler = async (error, vm, trace) => {
  const errorMsg = `[Medimo Vue Error]: ${error}${trace}`;
  if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
    console.error('Vue.config.errorHandler');
    console.error(errorMsg);
    console.error(error);
    console.error(trace);
  }

  const errorException = new VueError(error, errorMsg, trace);
  await setScopeAndSend(errorException, error, trace, errorMsg);
};

// Works by default
app.config.warnHandler = async (error, vm, trace) => {
  const errorMsg = `[Medimo Vue Warn]: ${error}${trace}`;

  // Vue template errors omdat we met blades en ajax <script> en <style> inladen
  // worden voor nu gesupressed. Niet ideaal, maar de oplossing was erger dan de kwaal
  // Op een later tijdstip nog revisiten, of als ook Provisional en Protocollen naar
  // Vue zijn....
  const isTemplateError = errorMsg.includes('Tags with side effect ');

  if (!isTemplateError) { //  || process.env.APP_ENV === 'development'
    if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
      app._component.methods.handleError(errorMsg, true);
      console.error('Vue.config.warnHandler');
      console.error(errorMsg);
      console.error(error);
      console.error(trace);
    }

    const warningException = new VueWarning(error, errorMsg, trace);
    await setScopeAndSend(warningException, error, trace, errorMsg);
  }
};


let ChunkLoadErrorsHandled = 0;
// Needs this syntax to be IE11 (well, babel) compatible:
window.onunhandledrejection = event => {
  // Custom errors kunnen geformat zijn op een manier waar we niets mee kunnen, die negeren we dan hier.
  if (typeof event.reason !== 'undefined' && event.reason.toString().includes('ChunkLoadError')) {
    console.warn('Javascript chunks missing. Hard navigation to next page.');
    // Keep track of the amount we already handled. We only want to trigger the navigation once.
    if (ChunkLoadErrorsHandled > 0) {
      return false;
    }
    ChunkLoadErrorsHandled++;

    // And a hard navigation to ensure we get the updated JS files along the way.
    window.location = store.state.routes.next_route.path;
  } else {
    window.onUncatchedError(event);
  }
};

app.directive('draggable', draggable);
app.directive('click-outside', ClickOutside);
app.component('fa-icon', Icons);
app.component('medimo-button', MedimoButton);

// De Vuex store
app.use(store);
// vue-router
app.use(router);

app.mount('#medimo_app');
window.app = app;
window.store = store; // werkt dan met window.store.commit() & dispatch()

window.onUncatchedError = (error) => {
  if (typeof error.reason !== 'undefined' && error.reason.message.includes('Navigation aborted from')) {
    // Ignore, komt door de beforeEach guard
    // Zie https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
    error.preventDefault();
    return;
  }
  if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
    console.error('window.onUncatchedError');
    console.error(error);
  }
  let errorMsg = '[Medimo onUncatchedError]';
  // Het kan ook een simpel Event object zijn met {isTrusted: true}, die kun je niet verwerken
  if (typeof error !== 'undefined' && typeof error.reason !== 'undefined') {
    errorMsg += `: ${error.reason}`;
    scope.setExtra('Error', JSON.stringify(error));
  }
  window.app._component.methods.handleError(errorMsg);

  const uncatchedException = new JsUncatchedError(error, errorMsg);
  return setScopeAndSend(uncatchedException, error);
};
