import {MedicationOverviewObject} from "@/domain/models/MedicationOverviewObject";

export class MedicationOverview {
  public activeMedication: Array<MedicationOverviewObject>;
  public otherMedicationUse: Array<MedicationOverviewObject>;

  constructor(activeMedication: Array<MedicationOverviewObject>, otherMedicationUse: Array<MedicationOverviewObject>) {
    this.activeMedication = activeMedication;
    this.otherMedicationUse = otherMedicationUse;
  }

  public static fromJson(json: unknown): MedicationOverview {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationOverview(
      parsedJson['activeMedication'].map((e: unknown) => MedicationOverviewObject.fromJson(e)),
      parsedJson['otherMedicationUse'].map((e: unknown) => MedicationOverviewObject.fromJson(e)),
    );
  }
}
