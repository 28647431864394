import {PractitionerRole} from '@/domain/models/PractitionerRole';
import {CodeObject} from '@/domain/models/CodeObject';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';
import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';

export class GeneratedPractitionerRole { 
  public id: number;
  public speciality: CodeObject;
  public healthcareProvider: HealthcareProvider;
  public healthcareProfessional: HealthcareProfessional;

  constructor(id: number, speciality: CodeObject, healthcareProvider: HealthcareProvider, healthcareProfessional: HealthcareProfessional) {
    this.id = id;
    this.speciality = speciality;
    this.healthcareProvider = healthcareProvider;
    this.healthcareProfessional = healthcareProfessional;
  }

  public static fromJson(json: unknown): PractitionerRole {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new PractitionerRole(
      parsedJson['id'] as number,
      CodeObject.fromJson(parsedJson['speciality']),
      HealthcareProvider.fromJson(parsedJson['healthcareProvider']),
      HealthcareProfessional.fromJson(parsedJson['healthcareProfessional']),
    );
  }
}
