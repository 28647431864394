import {AdministeringScheduleObject} from '@/domain/models/AdministeringScheduleObject';
import {RangeObject} from '@/domain/models/RangeObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {TimeObject} from '@/domain/models/TimeObject';
import {QuantityObject} from '@/domain/models/QuantityObject';

export class GeneratedAdministeringScheduleObject { 
  public frequency: RangeObject | null;
  public amount: RangeObject | null;
  public weekDay: CodeValue[] | null;
  public administrationTime: TimeObject[] | null;
  public interval: QuantityObject | null;
  public isFlexible: boolean | null;
  public partOfDay: CodeValue[] | null;
  public period: number | null;
  public periodUnit: string | null;

  constructor(frequency: RangeObject | null = null, amount: RangeObject | null = null, weekDay: CodeValue[] | null = null, administrationTime: TimeObject[] | null = null, interval: QuantityObject | null = null, isFlexible: boolean | null = null, partOfDay: CodeValue[] | null = null, period: number | null = null, periodUnit: string | null = null) {
    this.frequency = frequency;
    this.amount = amount;
    this.weekDay = weekDay;
    this.administrationTime = administrationTime;
    this.interval = interval;
    this.isFlexible = isFlexible;
    this.partOfDay = partOfDay;
    this.period = period;
    this.periodUnit = periodUnit;
  }

  public static fromJson(json: unknown): AdministeringScheduleObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new AdministeringScheduleObject(
      parsedJson['frequency'] ? RangeObject.fromJson(parsedJson['frequency']) : null,
      parsedJson['amount'] ? RangeObject.fromJson(parsedJson['amount']) : null,
      parsedJson['weekDay'] ? parsedJson['weekDay'].map((e: unknown) => CodeValue.fromJson(e)) : null,
      parsedJson['administrationTime'] ? parsedJson['administrationTime'].map((e: unknown) => TimeObject.fromJson(e)) : null,
      parsedJson['interval'] ? QuantityObject.fromJson(parsedJson['interval']) : null,
      parsedJson['isFlexible'] as boolean,
      parsedJson['partOfDay'] ? parsedJson['partOfDay'].map((e: unknown) => CodeValue.fromJson(e)) : null,
      parsedJson['period'] as number,
      parsedJson['periodUnit'] as string,
    );
  }
}
