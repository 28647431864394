import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  ...BaseModule.state,
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,

  fetchOwn({state, commit, dispatch, getters, rootState}, payload) {
    payload = {};
    payload.endpoint = '/api/v1/medication-evaluation/own';
    return dispatch('medication_evaluation/fetch', payload, {root:true});
  },
  fetchActing({state, commit, dispatch, getters, rootState}, payload) {
    payload = {};
    payload.endpoint = '/api/v1/medication-evaluation/acting';
    return dispatch('medication_evaluation/fetch', payload, {root:true});
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: payload.endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
