import {MedicationUse} from '@/domain/models/MedicationUse';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {CodeValue} from '@/domain/models/CodeValue';
import {PractitionerRole} from '@/domain/models/PractitionerRole';
import {HealthcareProfessional} from '@/domain/models/HealthcareProfessional';
import {HealthcareProvider} from '@/domain/models/HealthcareProvider';

export class GeneratedMedicationUse { 
  public id: number;
  public patientId: number;
  public description: string;
  public externalIdentifierSystem: CodeSystem;
  public externalIdentifier: IdentifierObject;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public medicationAgreementId: number | null;
  public administrationAgreementId: number | null;
  public pharmaceuticalTreatmentId: number;
  public periodOfUse: TimeIntervalObject;
  public startDateTime: string | null;
  public endDateTime: string | null;
  public instructionsForUse: InstructionsForUse;
  public stopType: CodeValue | null;
  public reasonModificationOrDiscontinuation: CodeValue | null;
  public comment: string | null;
  public reasonForUse: string | null;
  public useIndicator: boolean | null;
  public asAgreedIndicator: boolean | null;
  public prescriber: PractitionerRole;
  public assertedDateTime: string;
  public informantIsPatient: boolean;
  public authorIsPatient: boolean;
  public authorProfessional: HealthcareProfessional | null;
  public authorProvider: HealthcareProvider | null;

  constructor(id: number, patientId: number, description: string, externalIdentifierSystem: CodeSystem, externalIdentifier: IdentifierObject, pharmaceuticalProduct: PharmaceuticalProduct, medicationAgreementId: number | null = null, administrationAgreementId: number | null = null, pharmaceuticalTreatmentId: number, periodOfUse: TimeIntervalObject, startDateTime: string | null = null, endDateTime: string | null = null, instructionsForUse: InstructionsForUse, stopType: CodeValue | null = null, reasonModificationOrDiscontinuation: CodeValue | null = null, comment: string | null = null, reasonForUse: string | null = null, useIndicator: boolean | null = null, asAgreedIndicator: boolean | null = null, prescriber: PractitionerRole, assertedDateTime: string, informantIsPatient: boolean, authorIsPatient: boolean, authorProfessional: HealthcareProfessional | null = null, authorProvider: HealthcareProvider | null = null) {
    this.id = id;
    this.patientId = patientId;
    this.description = description;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.externalIdentifier = externalIdentifier;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.medicationAgreementId = medicationAgreementId;
    this.administrationAgreementId = administrationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.periodOfUse = periodOfUse;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.instructionsForUse = instructionsForUse;
    this.stopType = stopType;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
    this.comment = comment;
    this.reasonForUse = reasonForUse;
    this.useIndicator = useIndicator;
    this.asAgreedIndicator = asAgreedIndicator;
    this.prescriber = prescriber;
    this.assertedDateTime = assertedDateTime;
    this.informantIsPatient = informantIsPatient;
    this.authorIsPatient = authorIsPatient;
    this.authorProfessional = authorProfessional;
    this.authorProvider = authorProvider;
  }

  public static fromJson(json: unknown): MedicationUse {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationUse(
      parsedJson['id'] as number,
      parsedJson['patientId'] as number,
      parsedJson['description'] as string,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      IdentifierObject.fromJson(parsedJson['externalIdentifier']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['medicationAgreementId'] as number,
      parsedJson['administrationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
      parsedJson['comment'] as string,
      parsedJson['reasonForUse'] as string,
      parsedJson['useIndicator'] as boolean,
      parsedJson['asAgreedIndicator'] as boolean,
      PractitionerRole.fromJson(parsedJson['prescriber']),
      parsedJson['assertedDateTime'] as string,
      parsedJson['informantIsPatient'] as boolean,
      parsedJson['authorIsPatient'] as boolean,
      parsedJson['authorProfessional'] ? HealthcareProfessional.fromJson(parsedJson['authorProfessional']) : null,
      parsedJson['authorProvider'] ? HealthcareProvider.fromJson(parsedJson['authorProvider']) : null,
    );
  }
}
