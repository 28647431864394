<template>
  <medimo-form-row :no_margins="no_margins">
    <medimo-form-column v-if="label.length" :no_margin="no_margins" :column_classes="inline ? label_column_classes : 'col-12'">
      <label :class="'d-block pr-2 ' + ' ' + validation_class" :for="name">
        {{ label }}
      </label>
    </medimo-form-column>
    <medimo-form-column :no_margin="no_margins" :column_classes="inline ? 'col' : 'col-12'">
      <medimo-date-time-picker
          :class="extra_classes"
          v-bind:modelValue="modelValue"
          v-on:update:modelValue="onInput"
          @clear_date="$emit('clear_date')"

          :custom_classes="custom_classes"
          :name="name"
          :is_invalid="is_invalid"
          :datetime_format="datetime_format"
          :label="label"
          :minDate="minDate"
          :maxDate="maxDate"
          :deadline_today="deadline_today"
          :deadline_tomorrow="deadline_tomorrow"
          :deadline_weekbox="deadline_weekbox"
          :only_time="only_time"
          :shortcuts="shortcuts"
          :show_shortcut_now="show_shortcut_now"
          :show_shortcut_today="show_shortcut_today"
          :show_shortcut_tomorrow="show_shortcut_tomorrow"
          :show_shortcut_weekbox="show_shortcut_weekbox"
          :input_container_styles="input_container_styles"
          :time_disabled="time_disabled"
          :only_date="only_date"
          :time_reset="time_reset"
          :placeholder="placeholder"
          :show_icon="show_icon"
          :close_on_date_change="close_on_date_change"
          :close_on_time_change="close_on_time_change"
          :validation_class="validation_class"
          :help_text="help_text"
          :disabled="disabled"
          :show-period-selector-switch="showPeriodSelectorSwitch"
          @switchToPeriodSelector="onSwitchToPeriodSelector"
      >
        <slot v-if="hasSlot" name="custom"></slot>
        <template v-slot:pre-buttons>
          <slot name="pre-buttons"></slot>
        </template>
      </medimo-date-time-picker>
    </medimo-form-column>
  </medimo-form-row>
</template>

<script>

import MedimoDateTimePicker from './MedimoDateTimePicker';
import MedimoFormRow from './MedimoFormRow';
import MedimoFormColumn from './MedimoFormColumn';

export default {

  components: {
    MedimoDateTimePicker,
    MedimoFormRow,
    MedimoFormColumn,
  },

  emits: ['clear_date','update:modelValue', 'switchToPeriodSelector'],

  props: {
    'no_margins': {default: true},
    'inline': {default: true},
    'extra_classes': {default: ''},
    'validation_class': {default: ''},
    'help_text': {default: ''},

    'custom_classes': {default: null},

    // By default the label takes the space it needs, and no more.
    // You can specify a specific column width here if you want to change this behaviour for mobile, tablet or desktop
    'label_column_classes': {default: 'col-auto'},

    // When set to TRUE, will set the time to 00:00 when selecting a date
    time_reset: {default: true},
    // Set to TRUE to close the DTP when a user clicks on a date button
    close_on_date_change: {default: false},
    // Set to TRUE to close the DTP when a user clicks on a time button
    close_on_time_change: {default: true},

    // Check MedimoDateTimePicker for comments on these properties
    name: {default: ''},
    is_invalid: {default: false},
    datetime_format: {default: 'DD-MM-YYYY HH:mm'},
    label: {default: ''},
    minDate: {default: '-'},
    maxDate: {default: '-'},
    deadline_today: {required: false},
    deadline_tomorrow: {required: false},
    deadline_weekbox: {required: false},
    only_time: {default: false},
    modelValue: {default: false, type: [String, Boolean, Object]}, // Kan ook een Moment zijn
    shortcuts: {default: false},
    show_shortcut_now: {default: true},
    show_shortcut_today: {default: true},
    show_shortcut_tomorrow: {default: true},
    show_shortcut_weekbox: {default: true},
    input_container_styles: {default: ''},
    time_disabled: {default: false},
    only_date: {default: false},
    show_icon: {default: true},
    placeholder: {default: 'Selecteer datum/tijd'},

    disabled: {default: false},
    showPeriodSelectorSwitch: {default: false},
  },
  data: function () {
    return {};
  },
  computed: {
    hasSlot() {
      return !!this.$slots.custom;
    }
  },
  methods: {
    onInput(value) {
      this.$emit('update:modelValue', value);
    },
    onSwitchToPeriodSelector() {
      this.$emit('switchToPeriodSelector');
    }
  }
};
</script>
