import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {CodeObject} from '@/domain/models/CodeObject';

export class GeneratedPharmaceuticalProduct { 
  public id: number;
  public hpk: string;
  public gpk: string;
  public prk: string;
  public description: string;
  public productCode: CodeObject[];

  constructor(id: number, hpk: string, gpk: string, prk: string, description: string, productCode: CodeObject[]) {
    this.id = id;
    this.hpk = hpk;
    this.gpk = gpk;
    this.prk = prk;
    this.description = description;
    this.productCode = productCode;
  }

  public static fromJson(json: unknown): PharmaceuticalProduct {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new PharmaceuticalProduct(
      parsedJson['id'] as number,
      parsedJson['hpk'] as string,
      parsedJson['gpk'] as string,
      parsedJson['prk'] as string,
      parsedJson['description'] as string,
      parsedJson['productCode'] ? parsedJson['productCode'].map((e: unknown) => CodeObject.fromJson(e)) : null,
    );
  }
}
