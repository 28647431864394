import Quantity from '@/vue/utility/quantity';

export default {
  prettifyChange(change) {
    //change is an dosingInstruction change item
    //change.key, change.new, change.old

    const changeTypes = {};
    changeTypes['other_prescriber'] = {prettifyFunction: this.prettifyBoolean, translation: 'Andere voorschrijver'};
    changeTypes['external_supply'] = {prettifyFunction: this.prettifyBoolean, translation: 'Extern geleverd'};
    changeTypes['times'] = {prettifyFunction: this.prettifyTime, translation: 'Dosering'};
    changeTypes['start_moment_datetime'] = {prettifyFunction: this.prettifyDate, translation: 'Start'};
    changeTypes['stop_datetime'] = {prettifyFunction: this.prettifyDate, translation: 'Stop'};
    changeTypes['usage_type'] = {prettifyFunction: this.prettifyUsageType, translation: 'Omgezet naar'};
    changeTypes['schema'] = {prettifyFunction: this.prettifySchema, translation: 'Handmatig schema'};
    changeTypes['canceled'] = {prettifyFunction: this.prettifyCancel, translation: ''};
    changeTypes['drug_name'] = {prettifyFunction: this.prettifyDrugName, translation: 'Geneesmiddelnaam aangepast naar '};
    changeTypes['continuous_schema'] = {prettifyFunction: this.prettifySchemaContinous, translation: ''};
    changeTypes['dosage_interval_id'] = {prettifyFunction: this.prettifyDosageInterval, translation: 'Doseerinterval'};
    changeTypes['dosing_unit'] = {prettifyFunction: this.prettifyString, translation: 'Doseereenheid'};
    changeTypes['administration_route'] = {prettifyFunction: this.prettifyString, translation: 'Toedieningsweg'};
    changeTypes['info'] = {prettifyFunction: this.prettifyString, translation: 'Info'};

    if (changeTypes[change.key]) {
      const changeType = changeTypes[change.key];
      return changeType.translation + changeType.prettifyFunction(change);
    }

    return change.key + ' gewijzigd naar ' + change.new;
  },
  prettifyString(change) {
    return ' gewijzigd naar ' + change.new;
  },
  prettifyBoolean(change) {
    if (change.new) {
      return ' is aangezet';
    } else {
      return ' is uitgezet';
    }
  },
  prettifyCancel(change) {
    if (change.new) {
      return 'Doseerinstructie is geannuleerd';
    }
    else {
      return '';
    }
  },
  prettifyTime(change) {
    return ' is gewijzigd';
  },
  prettifyDosageInterval(change) {
    return ' is gewijzigd';
  },
  prettifyDate(change) {
    return ' is gewijzigd';
  },
  prettifyUsageType(change) {
    if (change.new === 'Z') {
      return ' een zonodig voorschrift';
    }
    else if (change.new === 'S') {
      return ' een handmatig schema';
    }
    else if (change.new === 'T') {
      return ' een tijdelijk voorschrift';
    }
    else if (change.new === 'C') {
      return ' een continu voorschrift';
    }
  },
  prettifySchema(change) {

    const newNonEmptyValues = change.new.values.filter( (value) => { return parseFloat(value.value) !== 0 && value.value !== 'x';} );
    let changeMessage = '';
    if (newNonEmptyValues.length === 0) {
      changeMessage = ' wordt gestopt';
    }
    else {
      changeMessage = ' is gewijzigd';
    }
    return changeMessage;
  },
  prettifySchemaContinous(change) {
    if (change.new) {
      return 'Gewijzigd naar continu schema';
    }
    else {
      return 'Gewijzigd naar tijdelijk schema';
    }
  },
  prettifyDrugName(change) {
    return change.new;
  }
};
