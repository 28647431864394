// initial state
// shape: [{ id, quantity }]
const state = {
  next_route: '',
  // dummy_variable1: [],
  // dummy_variable2: null
};

import Utility from '@/vue/utility/utility';

// getters
const getters = {
  visible_routes(state, getters, rootState, rootGetters) {
    // Converts the 'routes' object to an array and puts the key (the url path) in it's own property
    return Utility.convert_object_to_array(getters.routes('*'), 'path').filter(route => {
      return route.visible;
    });
  },
  next_path(state, getters, rootState, rootGetters) {
    return state.next_route.path;
  },
  routes(state, getters, rootState, rootGetters) {
    const user = rootState.current_user.data;
    const lastYear = (new Date).getFullYear() - 1;

    // Use getters['routes/routes']({to: '/config/certificates?clusterId=_clusterId', params: {_clusterId: 1}}), to pass parameters to the routes
    return (route) => {
      // Convert the string to an object. That way users can input the route, or a route with parameters
      if (typeof route === 'string') {
        const to = route;
        route = {};
        route.to = to;
      }
      // Set the params if it isn't given to prevent errors
      if (typeof route.params === 'undefined') {
        route.params = {};
      }

      const routes = {
        '/': {
          'title': 'Home',
          'description': '',
          'visible': function () {
            return true;
          }(),
        },
        '/certificaat/rootca': {
          'title': 'Download Root Certificate',
          'description': '',
          'visible': function () {
            return true;
          }(),
        },
        '/certificaat/crl': {
          'title': 'Download Certificate Revocation List',
          'description': '',
          'visible': function () {
            return true;
          }(),
        },
        /*
         |--------------------------------------------------------------------------
         | /
         |--------------------------------------------------------------------------
         |
         | Links van de development voorbeelden
         |
         */
        '/examples': {
          'title': 'Development examples',
          'description': 'voorbeelden',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/alerts': {
          'title': 'Example alerts',
          'description': 'voorbeeld alert, dialog, noty, warning',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/notifications': {
          'title': 'Example notifications',
          'description': 'voorbeeld notification',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/data-viewers': {
          'title': 'Medimo Data Viewer',
          'description': 'voorbeeld data viewer',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/medimo-form': {
          'title': 'Example form',
          'description': 'voorbeeld forms',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/medimo-form/medimo-button': {
          'title': 'Example buttons',
          'description': 'voorbeeld medimo buttons',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/medimo-form/medimo-select2': {
          'title': 'Example selectboxes',
          'description': 'voorbeeld select2',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/medimo-form/medimo-date-time-picker': {
          'title': 'Example date-time-picker',
          'description': 'voorbeeld date en datetime picker',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/modals': {
          'title': 'Example modals',
          'description': 'voorbeeld modal',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/medimo-swiper': {
          'title': 'Example swiper',
          'description': 'voorbeeld swiper',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/tables/medimo-table': {
          'title': 'Example medimo-table',
          'description': 'voorbeeld default tabel',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/tables/medimo-local-data-table': {
          'title': 'Example medimo-local-data-table',
          'description': 'voorbeeld lokale tabel',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/tables/medimo-external-data-table': {
          'title': 'Example medimo-external-data-table',
          'description': 'voorbeeld ajax-loaded tabel',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/layout/medimo-tiles': {
          'title': 'Example MedimoTiles',
          'description': 'bouwstenen voor een pagina',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/selectors': {
          'title': 'Diverse Entity datatable prefabs',
          'description': 'Patient, Ward, Location',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/selectors/patient-selector': {
          'title': 'Patient Selector',
          'description': '',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/icons': {
          'title': 'Icons / iconen',
          'description': 'alle iconen die we kunnen gebruiken binnen medimo',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/websocket': {
          'title': 'Voorbeeld websocket implementatie',
          'description': 'voorbeeld websocket listener',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/api/for-tests/responses': {
          'title': 'Example api responses',
          'description': 'voorbeelden van HTTP status code responses',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        '/examples/components/modals/unsaved-changes-modal': {
          'title': 'Unsaved Changes Modal',
          'description': 'Bij onopgeslagen wijzigingen',
          'visible': function () {
            return process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing';
          }(),
        },
        /*
                 |--------------------------------------------------------------------------
                 | /
                 |--------------------------------------------------------------------------
                 |
                 | Links van de index overview pagina, later cleanen
                 |
                 |
                 */
        '/status': {
          'title': 'Status',
          'description': 'Medicatiestatus',
          'visible': function () {
            return rootGetters['current_user/canAny'](['viewPrescriptions', 'listPatientsCallOfDuty']);
          }(),
        },
        '/search-patient': {
          'title': 'Zoek ' + user.language.patient,
          'description': 'Zoek direct op ' + user.language.patient + ' id, mensnummer of BSN',
          'visible': function () {
            return rootGetters['current_user/canAny'](['viewPrescriptions', 'listPatientsCallOfDuty', 'viewPrescriptionsCallOfDuty']);
          }(),
        },
        '/autordashboard': {
          'title': 'Autoriseren',
          'breadcrumb': 'Autorisatie dashboard',
          'description': 'Controleren van medicatieopdrachten',
          'visible': function () {
            return user.userType === 'apotid' &&
              user.RLusertdl;
          }(),
        },
        '/mutations/examine/list': {
          'title': 'Digitaal nakijken',
          'description': 'Eindcontrole door apotheker',
          'visible': function () {
            return user.userType === 'apotid' &&
              user.RLusertdl &&
              user.function === 'pharmacist';
          }(),
        },
        '/medimo-protocols/select-entity': {
          'title': 'Protocollen',
          'breadcrumb': 'Selecteer ' + user.language.afdeling + ' of ' + user.language.locatie,
          'description': 'Protocollen gebruiken voor meerdere ' + user.language.patient_plural + ' ineens',
          'visible': function () {
            return rootGetters['current_user/canAny'](['prescribe']);
          }()
        },
        '/authorizeprovisional': {
          'title': 'Accorderen',
          'description': 'Goedkeuren van medicatievoorstellen',
          'visible': function () {
            return user.userType === 'artsid' && rootGetters['current_user/canAny'](['prescribe']);
          }()
        },
        '/actions/actions-for-me': {
          'title': 'Actielijst',
          'description': 'Open acties',
          'visible': function () {
            return ['artsid', 'apotid', 'afdid', 'instid'].includes(user.userType);
          }()
        },
        '/chats': {
          'title': 'Berichten',
          'description': 'Interne berichten over een ' + user.language.patient,
          'visible': function () {
            return ['artsid', 'apotid', 'afdid', 'instid'].includes(user.userType);
          }()
        },
        '/lsp': {
          'title': 'LSP',
          'description': 'Landelijk schakelpunt',
          'visible': function () {
            return user.has_lsp_webservice && rootGetters['current_user/canAny'](['prescribe', 'queryLSP']);
          }()
        },
        '/readings/unread': {
          'title': 'Meetwaarden',
          'description': '',
          'visible': function () {
            return user.is_physician && rootGetters['current_user/canAny']('showLabresultsAll');
          }()
        },
        '/drugrequest': {
          'title': 'Medicatieaanvraag',
          'breadcrumb': 'Lijsten',
          'description': 'Aanvragen van bestellingen',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestRefill', 'requestEmergencySupply']);
          }()
        },
        '/medication-evaluation': {
          'title': 'MBO\'s',
          'description': 'Medicatiebeoordelingen',
          'visible': function () {
            return user.is_physician_strict || user.is_pharmacist_strict;
          }()
        },
        '/tdl': {
          'title': 'Lijsten',
          'description': 'Toedienlijsten, autorisatielijsten, bestellijsten e.d.',
          'visible': function () {
            return user.status === 'Y' && user.RLusertdl;
          }()
        },
        '/tdr': {
          'title': 'Toedienregistratie',
          'description': 'Alles voor de eTDR',
          'visible': function () {
            return rootGetters['current_user/canAny'](['administerMedications', 'approveAdministerPhoto']);
          }()
        },
        '/dds': {
          'title': 'GDS-verwerking',
          'breadcrumd': 'GDS-bestanden',
          'description': 'Aanmaken en wijzigen van medicijnrollen',
          'visible': function () {
            return user.userType === 'apotid';
          }()
        },
        '/reports': {
          'title': 'Overzichten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/invoice': {
          'title': 'Declaratie',
          'description': 'Aanmaken van AP304-bestanden voor ELV-' + user.language.patient_plural,
          'visible': function () {
            return user.userType === 'apotid' && rootGetters['current_user/canAny'](['editPharmacyInformation', 'editPharmacyPermissions']);
          }()
        },
        '/config/resource/maintenance': {
          'title': 'Onderhoud',
          'description': '',
          'visible': function () {
            return user.RLuserauthid > 0;
          }()
        },
        '/config': {
          'title': 'Beheer',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource': {
          'title': 'Administrator',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/my-pharmacy': {
          'title': 'Mijn apotheek',
          'description': '',
          'visible': function () {
            return user.is_pharmacist || user.is_admin;
          }()
        },
        '/config/resource/manual': {
          'title': 'Help',
          'breadcrumb': 'Hulpbronnen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/authority': {
          'title': 'Authority',
          'description': '',
          'visible': function () {
            return user.RLuseradmin === 'Y' || user.is_fabber;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /tdr
                 |--------------------------------------------------------------------------
                 |
                 | Links van de /tdr overview pagina, later cleanen
                 | nog heel veel hardlinks met de redirect e.d. er in
                 |
                 */
        '/agentsqueue/photoreviewlist': {
          'title': "Controlefoto's toedienregistratie",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('approveAdministerPhoto');
          }()
        },
        '/tdr/dailylist': {
          'title': "Werklijst",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/tdr/dailywardlist': {
          'title': "Werklijst " + user.language.afdeling,
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/tdr/ward': {
          'title': Utility.ucfirst(user.language.afdeling),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/tdr/mobile/patient': {
          'title': Utility.ucfirst(user.language.patient),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=tdr.patient.single&redirectKey=patientId': {
          'title': Utility.ucfirst(user.language.patient),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=tdr.patient.12_4&redirectKey=patientId': {
          'title': Utility.ucfirst(user.language.patient) + " historie 12 wk - toekomst 4 wk",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=tdr.patient.year&redirectKey=patientId': {
          'title': Utility.ucfirst(user.language.patient) + " historie < 1 jaar",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=tdr.patient.year_1_2&redirectKey=patientId': {
          'title': Utility.ucfirst(user.language.patient) + " historie 1-2 jaar",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=patient.drugsettings&redirectKey=patientId': {
          'title': "Eigen beheer",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/config/entities/patients?neededPermission=viewPrescriptions&redirectTo=patient.drugsettings&type=2&redirectKey=patientId': {
          'title': "Plaats van toediening",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('administerMedications');
          }()
        },
        '/api/v1/users/current/tdr-initials': {
          'title': "Wis gebruikercode " + user.RLtdrinitials,
          'description': '',
          'visible': function () {
            return user.RLtdrinitials && user.auth_method !== 'sso';
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/customer': {
          'title': "Klantenportaal",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/cluster': {
          'title': "Clustertabellen",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editClusterPermissions', 'editClusterTerminology', 'editClusterMedicationGuardPermission']);
          }()
        },
        '/config/webservice': {
          'title': "Koppelvlakbeheer",
          'description': '',
          'visible': function () {
            return user.cluster_permissions.one || user.is_admin;
          }()
        },
        '/reports/monitor': {
          'title': "Medimo monitor",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/scores/scoreOverview': {
          'title': "Medimo kenmerken - alle clusters",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/import': {
          'title': "Apotheek-importconfiguratie",
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /tdl
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/tdl/indextdl': {
          'title': "Toedienlijsten",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/indexamo': {
          'title': "Basisset medicatiegegevens (AMO/BMG)",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/authorization-lists': {
          'title': "Autorisatielijst",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/deeltijden': {
          'title': "Overzicht " + user.language.afdeling + " deeltijden",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/indexorders': {
          'title': "Bestellijst",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/indexaddress': {
          'title': "Adresetiketten",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/travel-document/patients': {
          'title': "Reisdocument",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/medicationprofile': {
          'title': "Medicatieprofiel",
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /autordashboard
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/autordashboard/type/prescriptionsToday': {
          'title': "Medicatie-opdrachten vandaag",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/autordashboard/type/prescriptionsTomorrow': {
          'title': "Medicatie-opdrachten morgen",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/autordashboard/type/prescriptionsLater': {
          'title': "Medicatie-opdrachten later",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/autordashboard/type/requestsToday': {
          'title': "Herhaalaanvragen vandaag",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/autordashboard/type/requestsTomorrow': {
          'title': "Herhaalaanvragen morgen",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/autordashboard/type/requestsLater': {
          'title': "Herhaalaanvragen later",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/stock-management/pharmacy-returns': {
          'title': 'Opiaat retour apotheek',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /medimoProtocols/selectEntity
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/medimoProtocols/selectWard': {
          'title': "Per " + user.language.afdeling,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/medimoProtocols/selectLocation': {
          'title': "Per " + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /drugrequest
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/drugrequest/orderlist': {
          'title': Utility.ucfirst(user.language.order_list),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestRefill']);
          }()
        },
        '/drugrequest/ward': {
          'title': Utility.ucfirst(user.language.afdeling) + " herhaalmedicatie",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestRefill']);
          }()
        },
        '/drugrequest/patient': {
          'title': Utility.ucfirst(user.language.patient) + " herhaalmedicatie",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestRefill']);
          }()
        },
        '/drugrequest/emergency': {
          'title': Utility.ucfirst(user.language.noodvoorraad) + " op naam",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestRefill']);
          }()
        },
        '/drugrequest/emergencyGeneral': {
          'title': Utility.ucfirst(user.language.noodvoorraad) + " algemeen",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['prescribe', 'requestEmergencySupplyGeneric']);
          }()
        },
        '/drugrequest/faxrecept': {
          'title': "Faxrecept",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['prescribe', 'requestFaxPrescription']);
          }()
        },
        '/drugrequest/discharge-prescription': {
          'title': "Ontslagrecept",
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['prescribe', 'requestDischargePrescription']);
          }()
        },

        '/stock-management': {
          'title': 'Voorraadbeheer',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock', 'editEmergencyStock']);
          }()
        },
        '/stock-management/emergency-location': {
          'title': 'Voorraadbeheer ' + Utility.lcfirst(user.language.noodvoorraad),
          'description': 'Inboeken van bestellingen en uitgeven reserveringen',
          'visible': function () {
            return rootGetters['current_user/canAny']('editEmergencyStock');
          }()
        },
        '/stock-management/patient': {
          'title': 'Voorraadbeheer ' + Utility.lcfirst(user.language.patient),
          'description': 'Inboeken van bestellingen',
          'visible': function () {
            return rootGetters['current_user/canAny']('editPatientStock');
          }()
        },
        '/stock-management/ward': {
          'title': Utility.ucfirst(user.language.afdeling) +  ' bestellingen',
          'description': 'Inboeken van bestellingen voor alle ' + Utility.lcfirst(user.language.patient_plural) + ' van een ' + Utility.lcfirst(user.language.afdeling),
          'visible': function () {
            return rootGetters['current_user/canAny']('editPatientStock');
          }()
        },
        '/stock-management/card': {
          'title': 'Voorraadkaarten',
          'description': 'Printbare (pdf)lijsten van de huidige voorraad',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock', 'editEmergencyStock']);
          }()
        },
        '/stock-management/card/patient': {
          'title': 'Voorraadkaart ' + Utility.lcfirst(user.language.patient),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock']);
          }()
        },
        '/stock-management/card/emergency-location': {
          'title': 'Voorraadkaart ' + Utility.lcfirst(user.language.noodvoorraad),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editEmergencyStock']);
          }()
        },
        '/stock-management/card/ward': {
          'title': 'Voorraadkaart ' + Utility.lcfirst(user.language.afdeling),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock']);
          }()
        },
        '/stock-management/card/location': {
          'title': 'Voorraadkaart ' + Utility.lcfirst(user.language.locatie),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock']);
          }()
        },
        // '/stock/emergency': {
        //   'title': Utility.ucfirst(user.language.noodvoorraad),
        //   'description': '',
        //   'visible': function () {
        //     return rootGetters['current_user/canAny']('editEmergencyStock');
        //   }()
        // },
        // '/stock/patient': {
        //   'title': 'Voorraad ' + user.language.patient,
        //   'description': '',
        //   'visible': function () {
        //     return rootGetters['current_user/canAny']('editPatientStock');
        //   }()
        // },
        // '/stock/ward': {
        //   'title': 'Afdeling bestellingen',
        //   'description': '',
        //   'visible': function () {
        //     return rootGetters['current_user/canAny']('editPatientStock');
        //   }()
        // },
        // '/stock/reports/expireDates/emergency': {
        //   'title': 'Overzicht vervaldata ' + Utility.ucfirst(user.language.noodvoorraad) + ' ' + user.language.locatie,
        //   'description': '',
        //   'visible': function () {
        //     return rootGetters['current_user/canAny']('editEmergencyStock');
        //   }()
        // },
        // '/stock/reports/expireDates/patient': {
        //   'title': 'Overzicht vervaldata voorraad ' + user.language.patient,
        //   'description': '',
        //   'visible': function () {
        //     return rootGetters['current_user/canAny']('editPatientStock');
        //   }()
        // },

        /*
         |--------------------------------------------------------------------------
         | /dds
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/bulk/location': {
          'title': 'Bulk-leveringen - per ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/location': {
          'title': 'GDS-bestand verzenden - per ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/ward': {
          'title': 'GDS-bestand verzenden - per ' + user.language.afdeling,
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/patient': {
          'title': 'GDS-bestand verzenden - per ' + user.language.patient,
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/suppress': {
          'title': 'Onderdruk middel in GDS',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/suppress-list': {
          'title': 'Overzicht onderdruk middel in GDS',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/suspend': {
          'title': 'Rol weggooien',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/mutate': {
          'title': 'Rol aanpassen',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/dds/check-mutations': {
          'title': 'Rol aanpassen met foto\'s',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },
        '/config/resource/entities/dds-customer': {
          'title': 'GDS klanten beheren',
          'description': '',
          'visible': function () {
            // Helpdesk also needs this link
            return user.is_pharmacist || user.is_admin;
          }()
        },
        '/config/resource/druglist/druglist-gds': {
          'title': 'GDS assortiment',
          'description': '',
          'visible': function () {
            // Helpdesk also needs this link
            return user.is_pharmacist || user.is_admin;
          }()
        },
        '/dds/check-mutations/approvals': {
          'title': 'Controlefoto\'s aanpassing rol',
          'description': '',
          'visible': function () {
            return user.is_pharmacist;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/maintenance
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/config/resource/maintenance/user': {
          'title': 'Gebruiker',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/drugs/photos': {
          'title': 'Geneesmiddelafbeeldingen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/maintenance/patient': {
          'title': Utility.ucfirst(user.language.patient_plural),
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('NON_MEDICAL');
          }()
        },
        '/config/resource/maintenance/list': {
          'title': 'Geneesmiddellijsten',
          'description': '',
          'visible': function () {
            return user.RLuserformularium === 'Y';
          }()
        },
        '/config/resource/maintenance/gds': {
          'title': 'GDS',
          'description': '',
          'visible': function () {
            return user.RLuserformularium === 'Y';
          }()
        },
        '/config/resource/maintenance/malen': {
          'title': 'Malen / sonde',
          'description': '',
          'visible': function () {
            return user.RLuserformularium === 'Y' && user.userType === 'apotid';
          }()
        },
        '/config/entities': {
          'title': 'Entiteiten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        // '/config/resource/cluster': {},
        // '/config/resource/authority': {},
        '/config/resource/entities/administration-list': {
          'title': 'Toedienlijsten beheren',
          'description': 'Controleparaaf en andere opties van toedienlijsten',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/entities/location-ai-level': {
          'title': 'Automatische medicatie herkenning (AI)',
          'description': 'Instelling per locatie',
          'visible': function () {
            return user.is_fabber && user.ai_enabled;
          }()
        },
        '/config/resource/location/action-plugin': {
          'title': 'Actieplugins',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/pharmacom': {
          'title': 'Pharmacom import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'Pharmacom' || user.is_admin;
          }()
        },
        '/import/cgm': {
          'title': 'CGM import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'CGM' || user.is_admin;
          }()
        },
        '/import/farmasys': {
          'title': 'Farmasys import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'Farmasys' || user.is_admin;
          }()
        },
        '/import/hix': {
          'title': 'HiX import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'Hix' || user.is_admin;
          }()
        },
        '/import/vidi-vici': {
          'title': 'VidiVici import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'VidiVici' || user.is_admin;
          }()
        },
        '/import/promedico-vdf': {
          'title': 'Promedico VDF import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'PromedicoVdf' || user.is_admin;
          }()
        },
        '/import/apro': {
          'title': 'Sanday import',
          'description': '',
          'visible': function () {
            return user.ais_type === 'Apro' || user.is_admin;
          }()
        },
        '/agentsqueue/escalation_settings': {
          'title': 'Toedienregistratie escalatiesettings',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editClusterPermissions', 'editLocationInformation']);
          }()
        },

        /*
               |--------------------------------------------------------------------------
               | /config/resource
               |--------------------------------------------------------------------------
               |
               |
               |
               |
               */
        '/config/resource/implementation': {
          'title': 'Implementatie',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/certificates': {
          'url': '/config/certificates',
          'title': 'Certificaatbeheer',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/drugs/zindex': {
          'title': 'Z-index raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/mfb-protocol': {
          'title': 'MFB raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/drug-mfb-protocol': {
          'title': 'MFB per geneesmiddel raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/parameter-mfb-protocol': {
          'title': 'MFB per parameter raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/attribuut-mfb-protocol': {
          'title': 'MFB per attribuut raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/vraag-mfb-protocol': {
          'title': 'MFB per vraag raadplegen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/drug-dosage-limit': {
          'title': 'Doseer-grenzen per geneesmiddel',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/drug-administration-area': {
          'title': 'Toedienplaatsen per middel',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs': {
          'title': 'Logs',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/copy': {
          'title': 'Kopieer scripts',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts': {
          'title': 'Beheer scripts',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables': {
          'title': 'Tabellen divers',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/medimo-services': {
          'title': 'Overzichten',
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/linktables/wardgroups': {
          'title': 'TDR groeperen ' + user.language.afdeling_plural,
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/lsp': {
          'title': 'Landelijk schakelpunt',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/medication-guard': {
          'title': 'G-standaard medicatiebewaking',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         |  /config/resource/manual
         |--------------------------------------------------------------------------
         |
         */
        '/manual/category/news': {
          'title': 'Nieuws',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/manual/category/version': {
          'title': 'Versiedocumentatie',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/manual/contact': {
          'title': 'Contactgegevens',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/manual/care-organizations-contacts': {
          'title': 'Contactgegevens zorgorganisaties',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/e-learning': {
          'title': 'E-learning',
          'url': '/sso/procademy',
          'description': '',
          'visible': function () {
            return user.proc_id !== '';
          }()
        },
        '/easygen-zorg-web': {
          'title': 'E-learning zorg-web',
          'url': '/sso/easygenerator?crs=' + user.easygen_zorg_web,
          'description': '',
          'visible': function () {
            return user.easygen_zorg_web !== '';
          }()
        },
        '/easygen-zorg-app': {
          'title': 'E-learning zorg-app',
          'url': '/sso/easygenerator?crs=' + user.easygen_zorg_app,
          'description': '',
          'visible': function () {
            return user.easygen_zorg_app !== '';
          }()
        },
        '/easygen-prescribe': {
          'title': 'E-learning' + (user.easygen_prescribe_poly ? ' klinisch' : '') + ' voorschrijven',
          'url': '/sso/easygenerator?crs=' + user.easygen_prescribe,
          'description': '',
          'visible': function () {
            return user.easygen_prescribe !== '';
          }()
        },
        '/easygen-poly': {
          'title': 'E-learning' + (user.easygen_prescribe ? ' poliklinisch' : '') + ' voorschrijven',
          'url': '/sso/easygenerator?crs=' + user.easygen_prescribe_poly,
          'description': '',
          'visible': function () {
            return user.easygen_prescribe_poly !== '';
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /reports
                 |--------------------------------------------------------------------------
                 */
        '/reports/list': {
          'title': "Mijn overzichten",
          'description': 'Alle recent gegenereerde overzichten',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily': {
          'title': "Dagrapport",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/changes': {
          'title': "Wijzigingen",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/dispenses': {
          'title': "Uitgiftes",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DISPENSES');
          }()
        },
        '/reports/episodes': {
          'title': "Episodes",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_EPISODES');
          }()
        },
        '/reports/opium': {
          'title': "Opiumwet",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/stock': {
          'title': "Voorraad",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock', 'editEmergencyStock']);
          }()
        },
        '/reports/g-standaard': {
          'title': "Lijsten G-standaard",
          'description': 'Algemene lijsten uit de G-standaard',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication-evaluations': {
          'title': "Medicatiebeoordelingen (MBO)",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_fabber || user.is_physician_strict || user.is_pharmacist_strict;
          }()
        },
        '/reports/drug-requests': {
          'title': "Medicatieaanvragen",
          'description': '',
          'isIndexPage': true,
          'tags': ['fabber'],
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication': {
          'title': "Medicatiekenmerken",
          'description': 'Overzichten van medicatie op basis van kenmerken van het voorschrift',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/medication-users': {
          'title': "Medicatiegebruikers",
          'description': 'Overzichten van medicatie op basis van geneesmiddelgroepen',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/medimo-lists': {
          'title': "Geneesmiddellijsten",
          'description': 'Algemene lijsten zoals formularium en ' + user.language.noodvoorraad,
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/patients': {
          'title': Utility.ucfirst(user.language.patient_plural),
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patient-assignments': {
          'title': 'Locatietoewijzingen',
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/fpz-searches': {
          'title': "FPZ Searches",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/tdr': {
          'title': "Toedienregistratie",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/statistics': {
          'title': "Statistiek",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_STATISTICS');
          }()
        },
        '/reports/lsp': {
          'title': "LSP",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_fabber || rootGetters['current_user/canAny'](['manageLSPtokens']);
          }()
        },
        '/reports/permissions': {
          'title': "Rechten en privacy",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/benchmark': {
          'title': "Benchmarks",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },
        '/reports/custom': {
          'title': "Maatwerk",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/other': {
          'title': "Overig",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations': {
          'title': "Vaccinaties",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            // return rootGetters['current_user/canAny']('viewPrescriptions');
            return true;
          }()
        },
        '/reports/vaccinations/covid19/stats': {
          'title': "Covid19 vaccinatie statistieken",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/covid19/list': {
          'title': "Covid19 vaccinatie overzicht",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/covid19/actions': {
          'title': "Covid19 overzicht acties",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/influenza/stats': {
          'title': "Influenza vaccinatie statistieken",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/influenza/list': {
          'title': "Influenza vaccinatie overzicht",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/influenza/actions': {
          'title': "Influenza overzicht acties",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/generic/list': {
          'title': "Vaccinatie overzicht",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/vaccinations/generic/actions': {
          'title': "Vaccinatie overzicht acties",
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/actions': {
          'title': "Acties",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/chats': {
          'title': "Berichten",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /reports/changes
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/reports/changes/mut': {
          'title': 'Overzicht wijzigingen afgelopen ' + user.language.weekdoos,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/changes/mut1': {
          'title': 'Overzicht wijzigingen komende week',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/changes/mut7': {
          'title': 'Overzicht wijzigingen 7 dagen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/changes/mut14': {
          'title': 'Overzicht wijzigingen 14 dagen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/changes/mutmaand': {
          'title': 'Overzicht wijzigingen per maand',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/medication-requests
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/reports/v3/drug-requests/general-requests': {
          'title': 'Overzicht aanvragen ' + user.language.order_list,
          'description': 'Bestellingen voor de ' + user.language.afdeling + ' niet op naam',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/drug-requests': {
          'title': 'Overzicht herhaalaanvragen',
          'description': 'Bestellingen voor de ' + user.language.patient + ' op naam',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/fax-and-poli-requests': {
          'title': 'Overzicht fax- en polirecepten',
          'description': 'Recepten voor de ' + user.language.patient + ' per fax of poli',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/immediate-requests': {
          'title': 'Overzicht tussentijdse herhaalaanvragen',
          'description': 'Bestellingen voor de ' + user.language.patient + ' per vandaag of morgen leveren',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/opium-requests': {
          'title': 'Overzicht herhaalaanvragen opiumwet',
          'description': 'Bestellingen voor opiumwetmiddelen voor de ' + user.language.patient + ' op naam',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/opium-deliveries': {
          'title': 'Overzicht leveringen opiumwet',
          'description': 'Bestellingen én leveringen voor opiumwetmiddelen op naam',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/emergency-requests': {
          'title': 'Overzicht registraties ' + user.language.noodvoorraad,
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/v3/drug-requests/opium-emergency-requests': {
          'title': 'Overzicht registraties ' + user.language.noodvoorraad + ' opiumwet',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DRUG_REQUESTS');
          }()
        },
        '/reports/medication-requests/maandowarts': {
          'title': 'Opiumwetleveringen maand per ' + user.language.prescriber,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication-requests/maandnoodarts': {
          'title': Utility.ucfirst(user.language.noodvoorraad) + ' leveringen maand per ' + user.language.prescriber,
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/medication
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/reports/medication/mymed': {
          'title': 'Overzicht medicatie ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedinst': {
          'title': 'Overzicht medicatie ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedpf2': {
          'title': 'Overzicht polyfarmacie (>=5) ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedpf': {
          'title': 'Overzicht polyfarmacie (>=9) ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedpf3': {
          'title': 'Overzicht polyfarmacie (>=7 med, >=75 jr) ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedblok': {
          'title': 'Overzicht geblokkeerde artikelen ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymednfinst': {
          'title': 'Overzicht niet-formularium ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedsubstinst': {
          'title': 'Overzicht substitutie ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mysubstformul': {
          'title': 'Overzicht substitutie formularium',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/medication/mymedeigenbeheer': {
          'title': 'Overzicht eigen beheer medicatie',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/fpz-searches
         |--------------------------------------------------------------------------
         */
        '/reports/v3/fpz-searches/chronic-paracetamol': {
          'title': 'Chronisch 4g paracetamol',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/nsaid-without-ppi': {
          'title': 'NSAID >60jr zonder maagbeschermer',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/nsaid-ssri-without-ppi': {
          'title': 'NSAID & SSRI/trazodon zonder maagbeschermer',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/opium-without-laxative': {
          'title': 'Opiaatgebruiker zonder laxans',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/nitrate-without-anticoagulation': {
          'title': 'Nitraatgebruiker zonder bloedverdunner',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/nitrate-without-anticholesterol': {
          'title': 'Nitraatgebruiker zonder cholesterolverlager',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/cortico-without-bisphosphonate': {
          'title': 'Corticosteroidgebruiker zonder bisfosfonaat',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/ace-with-at2': {
          'title': 'ACE-remmer icm AT2-antagonist',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/lis-with-thiazide': {
          'title': 'Lis-diureticum icm thiazide-diureticum',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/clozapine-with-antibiotic': {
          'title': 'Clozapine icm antibioticum',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/clozapine-with-nicotine': {
          'title': 'Clozapine icm stoppen met roken',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/digoxine-with-kidney': {
          'title': 'Digoxine gebruikers met nierfunctie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/doac-with-kidney': {
          'title': 'Doac gebruikers met nierfunctie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/multiple-coagulants': {
          'title': 'Meerdere bloedverdunners',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/custom/knmp': {
          'title': "Uitvraag KNMP",
          'description': '',
          'isIndexPage': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/indicator-2023-mbo': {
          'title': 'Indicator 2023 3.3.C met een medicatiebeoordeling',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/indicator-2023-doac-with-kidney': {
          'title': 'Indicator 2023 3.4.A Doac met recente nierfunctie',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/indicator-2023-multiple-coagulants': {
          'title': 'Indicator 2023 3.4.B Meerdere bloedverdunners',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/indicator-2022-doac-with-kidney': {
          'title': 'Indicator 2022 3.4.B Doac met recente nierfunctie',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/fpz-searches/indicator-2022-multiple-coagulants': {
          'title': 'Indicator 2022 3.4.C Meerdere bloedverdunners',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/tdr
         |--------------------------------------------------------------------------
         |
         |
         | /reports/tdr/percentage-gifts
         |
         */
        '/reports/v3/tdr/charts': {
          'title': 'Overzichten toedienregistratie',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_STATISTICS');
          }()
        },
        '/reports/v3/tdr/approvals': {
          'title': 'Details controlefoto\'s',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/forgotten-gifts': {
          'title': 'Details vergeten giften',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/when-needed-gifts': {
          'title': 'Details zo-nodig giften',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/other-gifts': {
          'title': 'Details overige giften',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/deviant-gifts': {
          'title': 'Details giften met afwijkend aantal',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/double-checked-gifts': {
          'title': 'Details giften met dubbele controle',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/v3/tdr/time-difference-gifts': {
          'title': 'Details giften met tijdsverschil',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_TDR');
          }()
        },
        '/reports/tdr/approvals-by-cluster': {
          'title': 'Details controlefoto\'s (per cluster)',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/statistics
         |--------------------------------------------------------------------------
         |
         */
        '/reports/custom/igz2016': {
          'title': 'Overzicht IGJ Psychofarmaca',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kosten': {
          'title': 'Overzicht medicijnkosten per maand',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kosten6mnd': {
          'title': 'Overzicht medicijnkosten 6 maanden',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kosten2': {
          'title': 'Overzicht medicijnkosten per ' + user.language.afdeling,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kosteninst': {
          'title': 'Overzicht medicijnkosten per ' + user.language.patient,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kosteneuro': {
          'title': 'Overzicht dure middelen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/kostengds': {
          'title': 'Overzicht GDS-kosten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/mutab': {
          'title': 'Overzicht antibiotica voorschriften',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/dds-mutations': {
          'title': 'Overzicht GDS-mutaties',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/antibiotics': {
          'title': 'Overzicht antibiotica rapport',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/statistics/mutation-count-by-location': {
          'title': 'Overzicht aantal mutaties per ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/statistics/mutation-count-by-ward': {
          'title': 'Overzicht aantal mutaties per ' + user.language.afdeling,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/statisuser': {
          'title': 'Overzicht aantal mutaties per gebruiker',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/statistics/statisinsttota': {
          'title': 'Overzicht aantal leveringen per ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/permissions/permissions-by-security-groups': {
          'title': 'Rechten per beveiligingsgroep',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/v3/permissions/permissions-by-user': {
          'title': 'Rechten per gebruiker',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/permissions/privacy-log': {
          'title': 'Privacy-log',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/permissions/nen-7513-patient-access': {
          'title': 'NEN7513 Toegangslog ' + user.language.patient_plural,
          'description': '',
          'with_filters': true,
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/v3/permissions/nen-7513-unauthorized-access': {
          'title': 'NEN7513 Ongeautoriseerde toegang',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/permissions/nen7513': {
          'title': 'NEN7513 dashboard',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/permissions/nen7513/settings': {
          'title': 'NEN7513 settings',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/custom
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/reports/v3/other/location-tdl-options': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' opties toedienlijst',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/location-cp-options': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' opties controleparaaf',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/ward-weekdoos': {
          'title': 'Overzicht ' + user.language.afdeling_plural + ' ' + user.language.weekdoos,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/ward-standard': {
          'title': 'Overzicht ' + user.language.afdeling_plural + ' standaard ' + user.language.prescriber + '/apotheek',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/ward-times': {
          'title': 'Overzicht ' + user.language.afdeling_plural + ' standaard deeltijden',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/ladis': {
          'title': 'LADIS rapportage',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/v3/other/rivm-sniv': {
          'title': 'RIVM SNIV rapportage',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/custom/overzichtab_csv?offset=1': {
          'title': 'Antibiotica startvoorschriften vorig jaar',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/overzichtab_csv?offset=0': {
          'title': 'Antibiotica startvoorschriften lopend jaar',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/numberpatients': {
          'title': 'Overzicht aantal ' + user.language.patient_plural + ' ' + lastYear,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/numberpatients?view=only-clinical': {
          'title': 'Overzicht aantal ' + user.language.patient_plural + ' ' + lastYear + ' (alleen klinisch)',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/numberpatients2': {
          'title': 'Overzicht huidig aantal ' + user.language.patient_plural,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/wardswithusers': {
          'title': 'Overzicht ' + user.language.afdeling_plural + ' met gebruikers',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/v3/other/location-lists': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' met lijsten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/location-with-action-plugins': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' met actieplugins',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/custom/physicianswithusers': {
          'title': 'Overzicht ' + user.language.prescriber_plural + ' met gebruikers',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/v3/other/locations-with-wards': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' en ' + user.language.afdeling_plural,
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/custom/gdsdetailregels': {
          'title': 'CSV detailregels GDS afgelopen maand',
          'description': '',
          'visible': function () {
            return user.userType === 'apotid';
          }()
        },
        '/reports/custom/ngdsdetailregels': {
          'title': 'CSV detailregels buiten GDS afgelopen maand',
          'description': '',
          'visible': function () {
            return user.userType === 'apotid';
          }()
        },
        '/reports/custom/mypataddon2': {
          'title': 'Overzicht add-on medicatie uitgebreid',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/custom/patdays': {
          'title': 'Overzicht aantal ' + user.language.patient + 'dagen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/other
         |--------------------------------------------------------------------------
         |
         */
        '/reports/other/myformul': {
          'title': 'Overzicht formularium',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/other/myformulatc': {
          'title': 'Overzicht formularium op ATC',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/other/mybest': {
          'title': 'Overzicht bestellijst',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/cooperated-pharmacies': {
          'title': 'Overzicht apotheken',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/other/cooperated-physicians': {
          'title': 'Overzicht ' + user.language.prescriber_plural,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/other/myusers': {
          'title': 'Overzicht gebruikersaccounts',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/other/latestlogin': {
          'title': 'Gebruikers laatste inlog',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/other/mobileAppUsers': {
          'title': 'Gebruikers Medimo-app',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 MedimoList Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/medimo-lists/add-on': {
          'title': 'Lijst artikelen met add-on regeling',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/koelkast': {
          'title': 'Lijst artikelen met een bewaarconditie',
          'description': 'Lijst met koelkast- of diepvries-artikelen',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/opiumwet': {
          'title': 'Lijst artikelen onder de opiumwet',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/carcinogeen': {
          'title': 'Lijst carcinogene en cytostatica stoffen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/reprotoxisch': {
          'title': 'Lijst reprotoxische stoffen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/reden-verplicht': {
          'title': 'Lijst reden van voorschrijven verplicht',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/risicovol': {
          'title': 'Lijst landelijke lijst risicovolle medicatie',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/bewerken': {
          'title': 'Lijst gezondheidsrisico bij bewerken',
          'description': 'Lijst met arbostoffen',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/mfb-protocollen': {
          'title': 'Lijst van MFB protocollen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/gds-assortiment': {
          'title': 'Overzicht GDS-assortiment',
          'description': 'Het actieve GDS-assortiment per locatie',
          'with_filters': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/pharmacy-drug-list': {
          'title': 'Overzicht apotheek-assortiment',
          'description': 'Het actieve apotheek-assortiment per locatie',
          'with_filters': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/maal-lijst': {
          'title': 'Overzicht maaladvies',
          'description': 'Overzicht per middel met advies bij slikproblemen',
          'with_filters': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/emergency-list': {
          'title': 'Overzicht ' + user.language.noodvoorraad,
          'description': 'De actieve ' + user.language.noodvoorraad + 'lijst op een ' + user.language.locatie,
          'with_filters': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/emergency-lists': {
          'title': 'Overzicht ' + user.language.noodvoorraad + ' in cluster',
          'description': 'Overzicht alle middelen en op welke ' + user.language.locatie + ' deze behoren te liggen',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/medimo-lists/cross-emergency-lists': {
          'title': 'Overzicht ' + user.language.noodvoorraad + 'lijsten',
          'description': 'Overzicht alle middelen per ' + user.language.noodvoorraad + 'lijst',
          'visible': function () {
            return true;
          }()
        },
        /*
         |--------------------------------------------------------------------------
         | /config/resource/maintenance/user
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/maintenance/user/settings': {
          'title': 'Voorkeuren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/maintenance/user/settings2': {
          'title': 'Gebruikersgegevens',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/maintenance/user/password': {
          'title': 'Wachtwoord wijzigen certificaatinlog',
          'description': '',
          'visible': function () {
            return user.auth_method === 'certificate';
          }()
        },
        '/maintenance/user/pin1': {
          'title': 'Wachtwoord wijzigen',
          'description': '',
          'visible': function () {
            return user.token_id === 'sms' || user.token_id === 'fixed';
          }()
        },
        '/maintenance/user/pin2': {
          'title': 'Pincode wijzigen',
          'description': '',
          'visible': function () {
            return user.token_id !== 'sms' && user.token_id !== 'fixed';
          }()
        },
        '/maintenance/user/report': {
          'title': 'Rapporten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/maintenance/user/message': {
          'title': 'Berichten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/directPrint/config': {
          'title': 'Configureer printers voor DirectPrint',
          'description': '',
          'visible': function () {
            return user.RLuserDirectPrint === 'Y';
          }()
        },
        '/authentication/settings': {
          'title': 'UZI-pas instellingen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /config/resource/maintenance/patient
         |--------------------------------------------------------------------------
         */
        '/config/entities/patients?neededPermission[]=viewPatientData&neededPermission[]=editPatientBasicInfo&neededPermission[]=editPatientAssignments&neededPermission[]=editPatientTemporaryAbsences&redirectTo=config.patients.tabs&redirectKey=patientId': {
          'title': Utility.ucfirst(user.language.patient_plural) + ' beheren',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('NON_MEDICAL');
          }()
        },
        // '/reports/patients/mypatdub': {
        //     'title': 'Overzicht dubbele ' + user.language.patient_plural,
        //     'description': '',
        //     'visible': function () {
        //         return user.overzichten === 'Y' || user.overzichten === 'A';
        //     }()
        // },


        /*
         |--------------------------------------------------------------------------
         | /config/resource/maintenance/list
         |--------------------------------------------------------------------------
         */
        '/config/resource/druglist/druglist-formulary': {
          'title': 'Formularium',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-alias': {
          'title': 'Aliassen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-protocol': {
          'title': 'Beheer protocollen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-block': {
          'title': 'Blokkeerlijst',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-order': {
          'title': 'Bestellijst',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-emergency': {
          'title': Utility.ucfirst(user.language.noodvoorraad) + ' lijst',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-manual': {
          'title': 'Eigen preparaten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        // '/config/resource/druglist/druglist-gds': {},
        '/config/resource/druglist/druglist-grind': {
          'title': 'Malen van geneesmiddelen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/druglist/druglist-pharmacy': {
          'title': 'Apotheek assortiment',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/medimo-list': {
          'title': 'Lijsten beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        '/maintenance/list/grindlist/unknown': {
          'title': 'Malen van geneesmiddelen - alleen onbekend',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/maintenance/list/grindlist/populate': {
          'title': 'Malen van geneesmiddelen - tabel aanvullen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/entities
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/config/resource/entities/physician': {
          'title': Utility.ucfirst(user.language.prescriber_plural) + ' beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/physician-outpatient': {
          'title': Utility.ucfirst(user.language.prescriber_plural) + ' (landelijk) beheren',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/entities/pharmacy': {
          'title': 'Apotheken beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/pharmacy/{id}': {
          'title': 'Apotheek beheren',
          'url': '/config/resource/entities/pharmacy/' + user.entityId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/pharmacy-outpatient': {
          'title': 'Apotheken (landelijk) beheren',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/entities/location': {
          'title': Utility.ucfirst(user.language.locatie_plural) + ' beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/ward': {
          'title': Utility.ucfirst(user.language.afdeling_plural) + ' beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/third-party': {
          'title': 'Derden beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/clustertables/emergency-location': {
          'title': Utility.ucfirst(user.language.noodvoorraad) + 'locaties beheren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/entities/users': {
          'title': 'Gebruikers beheren',
          'description': '',
          'visible': function () {
            return user.RLuseradmin === 'Y' || user.is_fabber;
          }()
        },
        '/config/resource/entities/cluster': {
          'title': 'Clusters beheren',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/entities/module': {
          'title': 'Modules beheren',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/patients/batcheditassignments': {
          'title': Utility.ucfirst(user.language.patient_plural) + ' overboeken',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/authority
                 |--------------------------------------------------------------------------
                 |
                 |
                 | /config/authority/cooperations
                 |
                 */
        '/config/authority/cooperations': {
          'title': 'Zakelijke verbanden beheren',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/authority/security-groups': {
          'title': 'Beveiligingsgroepen beheren',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['delegateSecurityGroupManagement', 'editSecurityGroup']);
          }()
        },
        '/config/resource/import/pharmacy-validate': {
          'title': 'Apotheken valideren',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/import/pharmacy-import': {
          'title': 'Import configureren',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/permission/pharmacy-permission': {
          'title': 'Apotheekgebruikers instellen',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/permission/physician-permission': {
          'title': Utility.ucfirst(user.language.prescriber) + 'gebruikers instellen',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/pharmacom
                 |--------------------------------------------------------------------------
                 |
                 | /import/pharmacom
                 |
                 |
                 */
        '/import/pharmacom': {
          'title': 'Pharmacom import webservice',
          'description': '',
          'visible': function () {
            if (user.ais_type === 'Pharmacom' || user.is_admin) {
              return true;
            }
            return false;
          }()
        },
        '/import/pharmacom-mail': {
          'title': 'Pharmacom import e-mails',
          'description': '',
          'visible': function () {
            return user.ais_type === 'Pharmacom' || user.is_admin;
          }()
        },
        '/config/resource/import/pharmacom-locations': {
          'title': 'Pharmacom ' + user.language.afdeling_plural,
          'description': '',
          'visible': function () {
            return user.ais_type === 'Pharmacom' || user.is_admin;
          }()
        },
        '/config/resource/import/pharmacom-physicians': {
          'title': 'Pharmacom ' + user.language.prescriber_plural,
          'description': '',
          'visible': function () {
            return user.ais_type === 'Pharmacom' || user.is_admin;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/implementation
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/config/resource/implementation/create-cluster': {
          'title': 'Aanmaken cluster',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/initialise-locations': {
          'title': Utility.ucfirst(user.language.locatie_plural) + ' inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/download-locations': {
          'title': Utility.ucfirst(user.language.locatie_plural) + ' downloaden (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/initialise-wards': {
          'title': Utility.ucfirst(user.language.afdeling_plural) + ' inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/download-wards': {
          'title': Utility.ucfirst(user.language.afdeling_plural) + ' downloaden (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/initialise-patients': {
          'title': Utility.ucfirst(user.language.patient_plural) + 'bestand inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/initialise-physician-users': {
          'title': 'Artsgebruikers bestand inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/initialise-formulary-list': {
          'title': 'Formularium inlezen (csv)',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/initialise-dds-list': {
          'title': 'GDS-assortiment inlezen (csv)',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/initialise-emergency-list': {
          'title': 'Werkvoorraad-lijst inlezen (csv)',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/initialise-pharmacy-list': {
          'title': 'Apotheek assortiment inlezen (csv)',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/initialise-ysis-patients': {
          'title': 'Ysis ' + user.language.patient_plural + 'bestand inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin && user.cluster_permissions.webservices.showYsisNaw;
          }()
        },
        '/config/resource/implementation/update-open-id-security-group': {
          'title': 'Batch-update openidconnect koppelvlak',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/update-webservice-table': {
          'title': 'Koppeltabel inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/discharge-external-patient-ids': {
          'title': 'Batch-discharge extern mensnummer',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/webservice/update-sso-user-table': {
          'title': 'Single sign-on koppeltabel inlezen (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/webservice/download-webservice-table': {
          'title': 'Koppeltabel downloaden (csv)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/implementation/update-external-patient-ids': {
          'title': 'Update mensnummers (csv)',
          'description': '',
          'visible': function () {
            return user.svrlevel > 3;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /config/certificates
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/config/resource/certificates/manager': {
          'title': 'Certificaat manager',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/certificates/incoming': {
          'title': 'Inkomende verzoeken',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/certificates/outgoing': {
          'title': 'Uitgaande verzoeken',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/certificaat': {
          'title': 'Root CA en CRL',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/certificaat?stage=regen_crl': {
          'title': 'CRL regenereren',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /logs
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/logs/webservices': {
          'title': 'Log Webservices',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs/usersessions': {
          'title': 'Log Usersessions',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs/queueysis': {
          'title': 'Queue Ysis',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs/mobile-app': {
          'title': 'Log mobiele app',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/tdr-event-queue': {
          'title': 'TDR event queue',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs/zorgidsessions': {
          'title': 'Log ZorgID sessies',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/logs/lsp': {
          'title': 'Log LSP Interacties',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/edi': {
          'title': 'Log receptberichten',
          'description': '',
          'visible': function () {
            return user.is_fabber || user.is_pharmacist;
          }()
        },
        '/logs/import': {
          'title': 'Log apotheekimport',
          'description': '',
          'visible': function () {
            return user.is_fabber || user.is_pharmacist;
          }()
        },
        '/logs/fax': {
          'title': 'Log fax',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/notifications': {
          'title': 'Log notificaties',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/bsn': {
          'title': 'Log bsn',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/sms': {
          'title': 'Log sms',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/sso': {
          'title': 'Log single-sign-on (SSO)',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/naw': {
          'title': 'Log ' + user.language.patient + '-koppeling (NAW)',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/copy
                 |--------------------------------------------------------------------------
                 |
                 |
                 | /config/resource/copy/copy-dds-list
                 |
                 */
        '/config/resource/copy/copy-dds-list': {
          'title': 'Kopieer GDS-assortiment',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/copy/copy-formulary-list': {
          'title': 'Kopieer formularium',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/copy/copy-grind-list': {
          'title': 'Kopieer maaltabel',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/copy/copy-emergency-list': {
          'title': 'Kopieer werkvoorraad',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/admintables
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/config/resource/admintables/customer': {
          'title': 'Klanten',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/lifeline-sender': {
          'title': 'Lifeline afzendadres',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/news': {
          'title': 'Nieuws beheren',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/help-search': {
          'title': 'Hulpbronnen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/customer-score-question': {
          'title': 'Medimo kenmerken vragen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/benchmark-admin': {
          'title': 'Benchmark vragen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/table-25-unit': {
          'title': 'Tabel25 eenheden',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/misc-unknown': {
          'title': 'Handverkoop merk/generiek',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/admintables/safe-mail-domain': {
          'title': 'Veilige maildomeinen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /reports/medimo-services
         |--------------------------------------------------------------------------
         |
         |/reports/medimo-services/koppelvlakken
         |
         |
         */
        '/reports/v3/medimo-services/koppelvlakken': {
          'title': 'Overzicht koppelvlakken',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/locations-without-customer': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' klant onbekend',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/locations-with-import': {
          'title': 'Overzicht ' + user.language.locatie_plural + ' met import',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/pharmacies-with-import': {
          'title': 'Overzicht apotheken met import',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/pharmacies-national': {
          'title': 'Overzicht apotheken (alle landelijke)',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/medimoservices/faxsms': {
          'title': 'Overzicht fax- en smskosten - afgelopen kwartaal',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/medimoservices/smsdetail': {
          'title': 'Detail SMS overzicht - afgelopen kwartaal',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/medimoservices/faxsms2': {
          'title': 'Overzicht fax- en smskosten - kwartaal daar voor',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/customer-services': {
          'title': 'Overzicht ' + user.language.patient_plural + ' per ' + user.language.locatie + ' per klant',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/reports/v3/medimo-services/clusters-with-physicians': {
          'title': 'Overzicht aantal ' + user.language.prescriber_plural + ' per cluster',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/lsp
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/config/lsp/verwijsindex': {
          'title': 'Verwijsindex bestand aanmaken',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /tdl/indextdl
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/tdl/patient': {
          'title': Utility.ucfirst(user.language.patient) + ' vandaag - op scherm',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/patient/mail': {
          'title': Utility.ucfirst(user.language.patient) + ' vandaag - mailen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/patient/nextperiod': {
          'title': Utility.ucfirst(user.language.patient) + ' komende periode - op scherm',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/patient/twoweeks': {
          'title': Utility.ucfirst(user.language.patient) + ' komende 14 dagen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/patient/history': {
          'title': Utility.ucfirst(user.language.patient) + ' per datum - op scherm',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/show': {
          'title': Utility.ucfirst(user.language.afdeling) + ' vandaag - op scherm',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/mail': {
          'title': Utility.ucfirst(user.language.afdeling) + ' vandaag - mailen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/nextperiod': {
          'title': Utility.ucfirst(user.language.afdeling) + ' komende periode - op scherm',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/mailnextperiod': {
          'title': Utility.ucfirst(user.language.afdeling) + ' komende periode - mailen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/ward/twoweeks': {
          'title': Utility.ucfirst(user.language.afdeling) + ' komende 14 dagen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/location/show': {
          'title': Utility.ucfirst(user.language.locatie) + ' vandaag',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/location/nextperiod': {
          'title': Utility.ucfirst(user.language.locatie) + ' komende periode',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/location/twoweeks': {
          'title': Utility.ucfirst(user.language.locatie) + ' komende 14 dagen',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/mutated': {
          'title': 'Vandaag gewijzigd',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/authorized': {
          'title': 'Vandaag geautoriseerd',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /tdl/indexamo
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/tdl/amo/patient': {
          'title': Utility.ucfirst(user.language.patient) + ' AMO/BMG',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/amo/ward': {
          'title': Utility.ucfirst(user.language.afdeling) + ' AMO/BMG',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/amo/location': {
          'title': Utility.ucfirst(user.language.locatie) + ' AMO/BMG',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /tdl/authorisation-lists
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         |
         |
         */
        '/tdl/authorization-list/patients?months=0': {
          'title': 'Autorisatielijst - per datum',
          'description': '',
          'componentName': 'NavSearchAuthorisationListLink',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/authorization-list/ward?months=0': {
          'title': 'Autorisatielijst ' + user.language.afdeling + ' - per datum',
          'description': '',
          'componentName': 'NavSearchAuthorisationListLink',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/authorization-list/polypharmacy?months=0': {
          'title': 'Autorisatielijst polyfarmacie - per datum',
          'description': '',
          'componentName': 'NavSearchAuthorisationListLink',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/authorization-list/new-residents?months=0': {
          'title': 'Autorisatielijst nieuwe ' + user.language.patient_plural + ' - per datum',
          'description': '',
          'componentName': 'NavSearchAuthorisationListLink',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /tdl/indexorders
                 |--------------------------------------------------------------------------
                 |
                 | /tdl/orderlist/ward
                 | /tdl/orderlist/location
                 |
                 */
        '/tdl/orderlist/ward': {
          'title': Utility.ucfirst(user.language.afdeling) + ' bestellijst',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/orderlist/location': {
          'title': Utility.ucfirst(user.language.locatie) + ' bestellijst',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /tdl/indexaddress
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         |
         */
        '/tdl/address/patient': {
          'title': Utility.ucfirst(user.language.patient) + ' adresetiket',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/address/ward': {
          'title': Utility.ucfirst(user.language.afdeling) + ' adresetiketten',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/address/location': {
          'title': Utility.ucfirst(user.language.locatie) + ' adresetiketten',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /tdl/medicationprofile
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         |
         */
        '/tdl/medicationprofile/patients': {
          'title': 'Medicatieprofiel ' + user.language.patient_plural,
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/medicationprofile/ward': {
          'title': 'Medicatieprofiel ' + user.language.afdeling,
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/medicationprofile/polypharmacy': {
          'title': 'Medicatieprofiel polyfarmacie',
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },
        '/tdl/medicationprofile/new-residents': {
          'title': 'Medicatieprofiel nieuwe ' + user.language.patient_plural,
          'description': '',
          'visible': function () {
            return user.RLusertdl;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /customer
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 */
        '/customer/patients': {
          'title': Utility.ucfirst(user.language.patient_plural) + ' & modules per ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/scores': {
          'title': 'Medimo kenmerken',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/sso/zoho': {
          'title': 'Helpcentrum',
          'description': 'Handleiding, tickets en RfC',
          'visible': function () {
            return true;
          }()
        },
        // '/customer/otrs': {
        //   'title': 'Overzicht tickets helpdesk',
        //   'description': '',
        //   'visible': function () {
        //     return user.is_fabber;
        //   }()
        // },
        // '/customer/requests-for-change': {
        //   'title': 'Verzoeken voor aanpassingen (RfC)',
        //   'description': '',
        //   'visible': function () {
        //     return user.is_fabber;
        //   }()
        // },
        '/customer/jira': {
          'title': 'Ontwikkelagenda Medimo',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/customer/jira/release': {
          'title': 'Release-agenda Medimo',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/customer/newrelic': {
          'title': 'SLA statistieken',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/customer/reports': {
          'title': 'Overzichten',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/customer/certificates': {
          'title': 'Certificering',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        'https://secure.medimo.nl/customer/versions': {
          'title': 'Medimo vragenlijsten & feedback',
          'description': '',
          'visible': function () {
            return !user.app_url.includes('ggz');
          }()
        },
        'https://ggz.medimo.nl/customer/versions': {
          'title': 'Medimo vragenlijsten & feedback',
          'description': '',
          'visible': function () {
            return user.app_url.includes('ggz');
          }()
        },
        '/customer/usercertificates': {
          'title': 'Gebruikerscertificaten',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/clustertables/cluster-files': {
          'title': 'Clusterbestanden',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/import
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |
                 |
                 |
                 */
        // '/config/resource/import/pharmacy-import': {},
        // '/config/resource/permission/pharmacy-permission': {},
        '/config/resource/import/merge-pharmacy': {
          'title': 'Apotheek ontdubbelen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/import/close-pharmacy': {
          'title': 'Apotheek sluiten',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
               |--------------------------------------------------------------------------
               | /reports/daily
               |--------------------------------------------------------------------------
               |
               |
               |
               */
        '/reports/daily/dagrapport': {
          'title': 'Dagrapport receptmutaties',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/daglever': {
          'title': 'Dagrapport leveringen/herhaalaanvragen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagwerkv': {
          'title': 'Dagrapport ' + user.language.noodvoorraad,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/patientmutations': {
          'title': 'Dagrapport ' + user.language.patient + 'wijzigingen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/v3/daily/adt-events': {
          'title': 'Dagrapport opnames, ontslagen en verhuizingen',
          'description': 'Eventlog op moment van wijzigen',
          'with_filters': true,
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/patient2': {
          'title': 'Dagrapport opnames, ontslagen en verhuizingen',
          'description': 'Alle wijzigingen in locatietoewijzingen en afwezigheden',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagbsn': {
          'title': 'Dagrapport BSN',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagmbBasis': {
          'title': 'Dagrapport medicatiebewaking',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagmb': {
          'title': 'Dagrapport mutaties met bewaking',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagTrombose': {
          'title': 'Dagrapport trombosedienstmeldingen',
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/reports/daily/dagGds': {
          'title': 'Dagrapport GDS mutaties',
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /config/resource/cluster
         |--------------------------------------------------------------------------
         |
         |
         |
         |
         */
        '/config/resource/entities/cluster/_clusterId': {
          'title': 'Cluster beheren',
          'url': '/config/resource/entities/cluster/' + user.cluster_id,
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/config/resource/clustertables/main-terminology': {
          'title': 'Basisterminologie wijzigen',
          'url': '/config/resource/clustertables/main-terminology',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.one;
          }()
        },
        '/config/resource/clustertables/independence-classifications': {
          'title': 'BEM-classificering',
          'url': '/config/resource/clustertables/independence-classifications',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/hyperlinks': {
          'title': 'Links voor toedienregistratie',
          'url': '/config/resource/clustertables/hyperlinks',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/absence-reasons': {
          'title': 'Redenen voor tijdelijke afwezigheden',
          'url': '/config/resource/clustertables/absence-reasons',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/external-client-id': {
          'title': 'Externe clientIds',
          'url': '/config/resource/clustertables/external-client-id',
          'description': '',
          'visible': function () {
            return user.is_fabber && (user.cluster_id === 100000241 || user.cluster_id === 100000252);
          }()
        },
        '/config/resource/entities/cluster-privacy/_clusterId': {
          'title': 'Privacy- en andere clustersettings',
          'url': '/config/resource/entities/cluster-privacy/' + user.cluster_id,
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/med-guard-required-settings': {
          'title': 'Verplichte medicatiebewakingssignalen',
          'url': '/config/resource/clustertables/med-guard-required-settings',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.three;
          }()
        },
        '/config/resource/clustertables/webhook': {
          'title': 'Webhook',
          'url': '/config/resource/clustertables/webhook',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/menu-links': {
          'title': 'Menu links',
          'url': '/config/resource/clustertables/menu-links',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/red-flag': {
          'title': 'TDR-waarschuwingen',
          'url': '/config/resource/clustertables/red-flag',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/drug-batch': {
          'title': 'Batchnummers vaccinaties',
          'url': '/config/resource/clustertables/drug-batch',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },
        '/config/resource/clustertables/prescribe-reason': {
          'title': 'Reden van voorschrijven afdwingen voor ATC-code',
          'url': '/config/resource/clustertables/prescribe-reason',
          'description': '',
          'visible': function () {
            // Needs to have the right cluster permissions, and we have to check that the user
            // is currently of that Cluster. Check CurrentUserResource for the values
            return user.cluster_permissions.two;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/scripts
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |/config/resource/scripts/authorize-mutations
                 */
        '/config/resource/scripts/authorize-mutations': {
          'title': 'Autoriseren openstaande mutaties',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/set-ais-import': {
          'title': 'Stel AIS import in',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/import/pharmacy-conversion-mode': {
          'title': 'Conversie-modus apotheek',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/undo-invoice-run': {
          'title': 'Maak declaratierun ongedaan',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/undo-invoice': {
          'title': 'Maak 1 declaratiefactuur ongedaan',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/ip-address': {
          'title': 'IP adres wijzigen',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/set-gds-x-days': {
          'title': 'Stel x-dagen in voor GDS-lijst',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/set-arsenary-week-day': {
          'title': 'Stel ingang in voor formularium',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/cluster-scripts': {
          'title': 'Clusterscripts',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/cluster-pharmacy': {
          'title': 'Apotheek wijzigen voor cluster',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/location-scripts': {
          'title': Utility.ucfirst(user.language.locatie) + 'scripts',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/ward-scripts': {
          'title': Utility.ucfirst(user.language.afdeling) + 'scripts',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/close-chats': {
          'title': 'Berichten sluiten',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/close-cluster': {
          'title': 'Cluster sluiten',
          'description': '',
          'visible': function () {
            return user.svrlevel > 7;
          }()
        },
        '/config/resource/scripts/plan-vektis': {
          'title': 'Inplannen Vektis update',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/location-actions': {
          'title': 'Acties voor ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/location-provisionals': {
          'title': 'Medicatievoorstellen voor ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/delete-emergency-stock': {
          'title': 'Voorraadrecord werkvoorraad',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/location-pharmacy': {
          'title': 'Apotheek wijzigen voor ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/ward-move': {
          'title': Utility.ucfirst(user.language.afdeling) + ' omhangen naar andere ' + user.language.locatie,
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/location-move': {
          'title': Utility.ucfirst(user.language.locatie) + ' omhangen naar ander cluster',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/resource/scripts/change-status-times': {
          'title': 'Tijden medicatieregels ' + user.language.locatie + ' omboeken',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/dds-assortment
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |/config/resource/implementation/initialise-dds-list
                 |/config/resource/copy/copy-dds-list
                 */
        '/config/resource/implementation/compare-dds-list-with-list?targetListId=_targetListId': {
          'title': 'Vergelijk GDS-assortiment met andere lijst',
          'url': '/config/resource/implementation/compare-dds-list-with-list?targetListId=' + route.params.targetListId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/compare-dds-list-with-csv?targetListId=_targetListId': {
          'title': 'Vergelijk GDS-assortiment met csv',
          'url': '/config/resource/implementation/compare-dds-list-with-csv?targetListId=' + route.params.targetListId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
                 |--------------------------------------------------------------------------
                 | /config/resource/pharmacy-assortment
                 |--------------------------------------------------------------------------
                 |
                 |
                 |
                 |/config/resource/implementation/compare-pharmacy-list-with-csv?targetListId=
                 */
        '/config/resource/implementation/compare-pharmacy-list-with-csv?targetListId=_targetListId': {
          'title': 'Vergelijk Apotheek-assortiment met CSV',
          'url': '/config/resource/implementation/compare-pharmacy-list-with-csv?targetListId=' + route.params.targetListId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/compare-pharmacy-list-with-list?targetListId=': {
          'title': 'Vergelijk Apotheek-assortiment met andere lijst',
          'url': '/config/resource/implementation/compare-pharmacy-list-with-list?targetListId=' + route.params.targetListId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/implementation/initialise-pharmacy-list?targetListId=': {
          'title': 'Importeer CSV in leeg Apotheek-assortiment',
          'url': '/config/resource/implementation/initialise-pharmacy-list?targetListId=' + route.params.targetListId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },
        '/config/resource/entities/medimo-list/edit': {
          'title': 'Lijst beheren',
          'url': '/config/resource/entities/medimo-list/' + route.params.listId,
          'description': '',
          'visible': function () {
            return true;
          }()
        },

        /*
               |--------------------------------------------------------------------------
               | /config/webservice
               |--------------------------------------------------------------------------
               |
               |
               |
               |
               */
        '/config/webservice/koppelvlak': {
          'title': 'Koppelvlak',
          'description': '',
          'visible': function () {
            return user.is_admin || user.is_fabber;
          }()
        },
        '/config/webservice/koppelvlak/list/mail': {
          'title': 'Koppelvlakken mail',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/koppelvlak/list/zorgmail': {
          'title': 'Koppelvlakken meetwaarden',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/koppelvlak/list/tromb': {
          'title': 'Koppelvlakken trombosedienst',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/koppelvlak/list/expiring': {
          'title': 'Koppelvlakken expiring',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/koppelvlak/list/lsp': {
          'title': 'Koppelvlakken LSP',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/koppelvlak/list/recent': {
          'title': 'Koppelvlakken recent',
          'description': '',
          'visible': function () {
            return user.is_admin;
          }()
        },
        '/config/webservice/linktableshl7': {
          'title': 'Koppelvlaktabel HL7',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showHl7;
          }()
        },
        '/config/webservice/linktableswebservice': {
          'title': 'Koppelvlaktabel webservices',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showWebservice;
          }()
        },
        '/config/resource/webservice/absence-table': {
          'title': 'Koppelvlaktabel afwezigheden',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/logs/meetwaarden': {
          'title': 'Log meetwaarden',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showReadings;
          }()
        },
        '/logs/tromb': {
          'title': 'Log trombosedienst',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showTromb;
          }()
        },
        '/config/resource/webservice/sso-user-table': {
          'title': 'Koppelvlaktabel single sign-on',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showSSO;
          }()
        },
        '/config/resource/webservice/nedap-finance-products-table': {
          'title': 'Koppelvlaktabel Nedap Financetypes',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showNedap;
          }()
        },
        '/config/resource/clustertables/link-table': {
          'title': 'Koppeltabel OpenId-Connect',
          'url': '/config/resource/clustertables/link-table',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showOpenIdLink;
          }()
        },
        '/config/resource/clustertables/link-table-security-group': {
          'title': 'OpenId-Connect Beveiligingsgroep',
          'url': '/config/resource/clustertables/link-table-security-group',
          'description': '',
          'visible': function () {
            return user.is_fabber && user.cluster_permissions.webservices.showOpenIdLink;
          }()
        },
        '/config/resource/scripts/ysis-queue-patients': {
          'title': 'Ysis plan ' + user.language.patient_plural + ' in voor queue',
          'description': '',
          'visible': function () {
            return user.is_admin && user.cluster_permissions.webservices.showYsis;
          }()
        },

        /*
               |--------------------------------------------------------------------------
               | /reports/lsp
               |--------------------------------------------------------------------------
               |
               |
               |
               |
               */
        // '/reports/patients/mypatoptin': {
        //     'title': 'Overzicht opt-ins ' + user.language.patient_plural,
        //     'description': '',
        //     'visible': function () {
        //       return user.is_fabber;
        //     }()
        // },
        '/reports/lsp/physicians': {
          'title': 'Overzicht LSP ' + user.language.prescriber_plural,
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/lsp/pharmacies': {
          'title': 'Overzicht LSP apotheken',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/lsp/inschrijftokens': {
          'title': 'Overzicht LSP inschrijftokens',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/lsp/token/cluster': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder inschrijftokens (per cluster)',
          'description': '',
          'visible': function () {
            return user.has_lsp_webservice && (user.is_fabber || rootGetters['current_user/canAny'](['manageLSPtokens']));
          }()
        },
        '/reports/lsp/token/ward': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder inschrijftokens (per ' + user.language.afdeling + ')',
          'description': '',
          'visible': function () {
            return user.has_lsp_webservice && (user.is_fabber || rootGetters['current_user/canAny'](['manageLSPtokens']));
          }()
        },
        '/reports/lsp/mandaattokens': {
          'title': 'Overzicht LSP mandaattokens',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },
        '/reports/lsp/myusers': {
          'title': 'Overzicht ID\'s gebruikeraccounts',
          'description': '',
          'visible': function () {
            return user.is_fabber;
          }()
        },

        /*
                |--------------------------------------------------------------------------
                | /reports/benchmark
                |--------------------------------------------------------------------------
                |
                |
                |
                |
                */
        '/reports/benchmark/_clusterId/kenmerken': {
          'title': 'Benchmark voorschrijfkenmerken',
          'url': '/reports/benchmark/' + user.cluster_id + '/kenmerken',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },
        '/reports/benchmark/_clusterId/antibiotica': {
          'title': 'Benchmark antibiotica',
          'url': '/reports/benchmark/' + user.cluster_id + '/antibiotica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },
        '/reports/benchmark/_clusterId/psychofarmaca': {
          'title': 'Benchmark psychofarmaca',
          'url': '/reports/benchmark/' + user.cluster_id + '/psychofarmaca',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },
        '/reports/benchmark/_clusterId/gebruikers': {
          'title': 'Benchmark gebruikers',
          'url': '/reports/benchmark/' + user.cluster_id + '/gebruikers',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },
        '/reports/benchmark/_clusterId/tdr': {
          'title': 'Benchmark toedienregistratie',
          'url': '/reports/benchmark/' + user.cluster_id + '/tdr',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny']('overviewStatistics');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | /dispenses
         |--------------------------------------------------------------------------
         |
         */
        '/dispense': {
          'title': 'Uitgifte',
          'url': '/dispense',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['dispenseMedications', 'requestDispenseMedications']);
          }()
        },
        '/dispense/dispense-list': {
          'title': 'Uitgifte-lijst',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['dispenseMedications']);
          }()
        },
        '/dispense/ward': {
          'title': 'Uitgifte-leveringen - per ' + user.language.afdeling,
          'description': '',
          'visible': function () {
            return rootGetters['current_user/canAny'](['requestDispenseMedications']);
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/selector': {
          'title': 'Alle overzichten',
          'description': 'Lijst met alle beschikbare overzichten',
          'isIndexPage': true,
          'visible': function () {
            return true;
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Action Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/actions/completed-actions': {
          'title': 'Afgeronde acties overzicht',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/actions/current-actions': {
          'title': 'Openstaande acties overzicht',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Chats Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/chats/incoming-chats': {
          'title': 'Overzicht alle inkomende berichten',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/chats/outgoing-chats': {
          'title': 'Overzicht alle uitgaande berichten',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Patient Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/patients/patient-ids': {
          'title': 'Overzicht ' + user.language.patient + 'nummers',
          'description': '',
          'visible': function () {
            return user.is_fabber && rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-bem': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met bem',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-last-import': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met laatste apotheek-import',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-medication': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/without-medication': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/without-bsn': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder BSN',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/without-bsn-validated': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder gevalideerd BSN',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/without-mensnr': {
          'title': 'Overzicht ' + user.language.patient_plural + ' zonder extern ' + user.language.patient + 'nummer',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/multiple-assignments': {
          'title': 'Overzicht ' + user.language.patient_plural + ' meerdere toewijzingen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/multiple-clinical-assignments': {
          'title': 'Overzicht ' + user.language.patient_plural + ' meerdere klinische toewijzingen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/multiple-main-assignments': {
          'title': 'Overzicht ' + user.language.patient_plural + ' meerdere hoofdtoewijzingen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/home-data': {
          'title': 'Overzicht ' + user.language.patient_plural + ' thuisgegevens',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-pharmacy-import': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met import vanuit apotheeksysteem',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-thrombosis-agency': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met trombosedienst-gegevens',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/current-elv-dbc': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met huidig ELV/DBC',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/current-patients': {
          'title': 'Overzicht ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patients/with-care-providers': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met ' + user.language.prescriber + '/apotheek',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 PatientAssignment Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/patient-assignments/assignments': {
          'title': 'Overzicht alle locatietoewijzingen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patient-assignments/new-assignments': {
          'title': 'Overzicht nieuwe locatietoewijzingen',
          'description': 'Overzicht nieuwe ' + user.language.patient_plural + '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patient-assignments/ended-assignments': {
          'title': 'Overzicht vervallen locatietoewijzingen',
          'description': 'Overzicht vervallen ' + user.language.patient_plural + '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/v3/patient-assignments/ending-assignments': {
          'title': 'Overzicht aflopende locatietoewijzingen',
          'description': 'Overzicht vertrekkende ' + user.language.patient_plural + '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },


        /*
         |--------------------------------------------------------------------------
         | v3 Dispenses Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/dispenses/dispenses-next-day': {
          'title': 'Overzicht uitgiftes tot komende werkdag',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DISPENSES');
          }()
        },
        '/reports/v3/dispenses/dispenses-future': {
          'title': 'Overzicht nog uit te geven uitgiftes',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DISPENSES');
          }()
        },
        '/reports/v3/dispenses/dispenses': {
          'title': 'Overzicht uitgegeven uitgiftes',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_DISPENSES');
          }()
        },
        '/reports/v3/dispenses/dispense-setting': {
          'title': 'Overzicht actuele uitgifteschema\'s',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Dispenses Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/episodes/episodes': {
          'title': 'Overzicht actuele episodes',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_EPISODES');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Medication-Evaluation Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/medication-evaluations/closed': {
          'title': 'Overzicht afgesloten MBO\'s',
          'description': '',
          'visible': function () {
            return user.is_fabber || user.is_physician_strict || user.is_pharmacist_strict;
          }()
        },
        '/reports/v3/medication-evaluations/most-recent': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met meest recente datum MBO',
          'description': '',
          'visible': function () {
            return user.is_fabber || user.is_physician_strict || user.is_pharmacist_strict;
          }()
        },


        /*
         |--------------------------------------------------------------------------
         | v2 Patient Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/patients/mypatdub': {
          'title': 'Overzicht dubbele ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatafw': {
          'title': 'Overzicht tijdelijke afwezigheden ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatelv': {
          'title': 'Overzicht eerste lijns verblijf ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatoptin': {
          'title': 'Overzicht LSP Opt-ins ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatdbc': {
          'title': 'Overzicht DBC ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatit': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met intolerantie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTDOSSIER');
          }()
        },
        '/reports/patients/mypatci': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met contra-indicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTDOSSIER');
          }()
        },
        '/reports/patients/mypatac': {
          'title': 'Overzicht ' + user.language.patient_plural + ' met afgeleide contra-indicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTDOSSIER');
          }()
        },
        '/reports/patients/mypatvipp': {
          'title': 'VIPP - Overzicht ' + user.language.patient_plural + '',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatvippopvragingen2': {
          'title': 'Overzicht tellingen VIPP rapportage',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatvippb2': {
          'title': 'VIPP - Overzicht verzonden recepten',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatvippmedicatieafspraken': {
          'title': 'VIPP - Overzicht medicatieafspraken',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatvipptotals': {
          'title': 'VIPP - Overzicht ' + user.language.patient_plural + ' (tellingen)',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatvippopvragingen': {
          'title': 'VIPP - Overzicht opvragingen (tellingen)',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTS');
          }()
        },
        '/reports/patients/mypatdia': {
          'title': 'Overzicht diabetes',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTDOSSIER');
          }()
        },
        '/reports/patients/mypathf': {
          'title': 'Overzicht hartfalen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_PATIENTDOSSIER');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Medication Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/medication/niet-taxe': {
          'title': 'Overzicht niet-taxe medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/eigen-preparaten': {
          'title': 'Overzicht eigen-preparaten medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/uit-de-handel': {
          'title': 'Overzicht uit-de-handel medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/extern-geleverd': {
          'title': 'Overzicht extern-geleverde medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/andere-voorschrijver': {
          'title': 'Overzicht andere-voorschrijver medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/handmatig-schema': {
          'title': 'Overzicht handmatig-schema medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/lang-interval': {
          'title': 'Overzicht lang-interval medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/lang-interval-parenteralia': {
          'title': 'Overzicht lang-interval injecties',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/add-on': {
          'title': 'Overzicht add-on medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/zo-nodig': {
          'title': 'Overzicht zo-nodig medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/in-rol': {
          'title': 'Overzicht ' + user.language.gds,
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/buiten-rol': {
          'title': 'Overzicht ' + user.language.ngds,
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/dure-medicatie': {
          'title': 'Overzicht dure medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/zeer-dure-medicatie': {
          'title': 'Overzicht zeer dure medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/malen-sonde': {
          'title': 'Overzicht medicatie voor malen/sonde',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/opium-wet': {
          'title': 'Overzicht opiumwet-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/koelkast': {
          'title': 'Overzicht koelkast-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/risicovolle-middelen': {
          'title': 'Overzicht risicovolle medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/halfjes': {
          'title': 'Overzicht halve/halfjes medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        '/reports/v3/medication/parenteralia': {
          'title': 'Overzicht medicatie voor parenterale toediening',
          'description': 'Overzicht van injecties',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/transdermaal': {
          'title': 'Overzicht medicatie voor transdermale toediening',
          'description': 'Overzicht van pleisters',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/landelijk-buiten-rol': {
          'title': 'Overzicht risicomedicatie met dubbele controle',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/zwangerschapspreventie': {
          'title': 'Overzicht medicatie met zwangerschapspreventie (ZPP)',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/zonder-dosering': {
          'title': 'Overzicht medicatie zonder dosering',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/continu-zonder-dosering': {
          'title': 'Overzicht continu medicatie zonder dosering',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication/temporary-stop': {
          'title': 'Overzicht tijdelijk gestopte medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Medication-Users Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/medication-users/anemie': {
          'title': 'Overzicht gebruikers anti-anemie middelen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/inhalatie': {
          'title': 'Overzicht gebruikers long-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/ras': {
          'title': 'Overzicht gebruikers ras-remmers',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        '/reports/v3/medication-users/dementie': {
          'title': 'Overzicht gebruikers anti-dementie middelen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/parkinson': {
          'title': 'Overzicht gebruikers anti-parkinson middelen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/antibiotica': {
          'title': 'Overzicht gebruikers antibiotica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/antidepressiva': {
          'title': 'Overzicht gebruikers antidepressiva',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/antiepileptica': {
          'title': 'Overzicht gebruikers antiepileptica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/coumarines': {
          'title': 'Overzicht gebruikers coumarines',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/dermatica': {
          'title': 'Overzicht gebruikers dermatica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/diuretica': {
          'title': 'Overzicht gebruikers diuretica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/insulines': {
          'title': 'Overzicht gebruikers insulines',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/neus': {
          'title': 'Overzicht gebruikers neus-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/oog': {
          'title': 'Overzicht gebruikers oog-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/schildklier': {
          'title': 'Overzicht gebruikers schildklier-medicatie',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/sedativa': {
          'title': 'Overzicht gebruikers sedativa',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/neuroleptica': {
          'title': 'Overzicht gebruikers neuroleptica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/sedativa-neuroleptica': {
          'title': 'Overzicht gebruikers sedativa/neuroleptica',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/psychofarmaca': {
          'title': 'Overzicht gebruikers psychofarmaca',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/probleem-gedrag': {
          'title': 'Overzicht gebruikers gedrag beïnvloedende middelen',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/vitamine-d': {
          'title': 'Overzicht gebruikers vitamine D',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/no-vitamine-d': {
          'title': 'Overzicht gebruikers zónder vitamine d',
          'description': '',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/name-user': {
          'title': 'Overzicht gebruikers',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/medication-users/atc-user': {
          'title': 'Overzicht gebruikers op atc',
          'description': '',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },

        /*
         |--------------------------------------------------------------------------
         | v3 Stock Report Routes
         |--------------------------------------------------------------------------
         |
         */
        '/reports/v3/stock-management/emergency-location-stock': {
          'title': 'Overzicht voorraad ' + user.language.noodvoorraad.toLowerCase() + 'locatie',
          'description': 'Wat is de huidige voorraad',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editEmergencyStock']);
          }()
        },
        '/reports/v3/stock-management/emergency-location-expiration-date': {
          'title': 'Overzicht vervaldata alle ' + user.language.noodvoorraad.toLowerCase() + 'locatie',
          'description': 'Welke middelen gaan er komende 4 maanden over de datum',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editEmergencyStock']);
          }()
        },
        '/reports/v3/stock-management/emergency-location-opium-stock': {
          'title': 'Overzicht opiumwet voorraad ' + user.language.noodvoorraad.toLowerCase() + 'locatie',
          'description': 'Wat is de huidige voorraad van opiumwetmiddelen',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editEmergencyStock']);
          }()
        },
        '/reports/v3/stock-management/emergency-location-opium-details-amount': {
          'title': 'Overzicht opiumwet voorraad ' + user.language.noodvoorraad.toLowerCase() + 'locatie vs verbruik',
          'description': 'Hoeveel voorraadmutaties zijn er tov verbruik in de voorraad',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editEmergencyStock']);
          }()
        },
        '/reports/v3/stock-management/patient-stock': {
          'title': 'Overzicht voorraad ' + user.language.patient.toLowerCase(),
          'description': 'Wat is de huidige voorraad',
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock']);
          }()
        },
        '/reports/v3/stock-management/patient-expiration-date': {
          'title': 'Overzicht vervaldata voorraad ' + user.language.patient_plural,
          'description': 'Welke middelen gaan er de komende 6 weken over de datum',
          'with_filters': true,
          'visible': function () {
            return rootGetters['current_user/canAny'](['editPatientStock']);
          }()
        },
        '/reports/v3/stock-management/patient-opium-stock': {
          'title': 'Overzicht opiumwet voorraad ' + user.language.patient.toLowerCase(),
          'description': 'Wat is de huidige voorraad van opiumwetmiddelen',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/stock-management/patient-opium-details-amount': {
          'title': 'Overzicht opiumwet voorraad ' + user.language.patient.toLowerCase() + ' vs verbruik',
          'description': 'Hoeveel voorraadmutaties zijn er tov verbruik in de voorraad',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
        '/reports/v3/stock-management/opium-requests-vs-use': {
          'title': 'Overzicht opiumwet aanvragen vs verbruik',
          'description': 'Hoeveel is er geleverd tov verbruik uit de toedienregistratie',
          'visible': function () {
            return rootGetters['current_user/hasPermissionsFor']('REPORT_MEDICATIONS');
          }()
        },
      };

      // Used for the filter
      if (route.to === '*') {
        return routes;
      }

      return routes[route.to];
    };
  },
  history(state, getters, rootState, rootGetters) {
    return rootGetters['settings/breadcrumbs/breadcrumb_trail'].map((crumb) => {
      return crumb.path;
    });
  },
  previous_route(state, getters) {
    const history = getters['history'];
    if (history.length <= 1) {
      return false;
    }
    return history[history.length - 2];
  },
  current_route(state, getters) {
    const history = getters['history'];
    if (history.length < 1) {
      return false;
    }
    return history[history.length - 1];
  },
};

// actions
const actions = {

  // dummy_action ({state, commit, dispatch, getters, rootState}, payload) {
  //     //
  // }
};

// mutations
const mutations = {
  set_next_route(state, next_route) {
    state.next_route = next_route;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
