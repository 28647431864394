
// initial state
const state = {
  store_namespace: 'settings',
  showTimeFields: 0,
  showExtensiveFields: 0,
  showMaximumDoseField: 0,
};

// getters
const getters = {
  showTimeFields(state) {
    return state.showTimeFields;
  },
  showExtensiveFields(state) {
    return state.showExtensiveFields;
  },
  showMaximumDoseField(state) {
    return state.showMaximumDoseField;
  },
};

// actions
const actions = {

};

// mutations
const mutations = {
  set_show_time_fields(state, value) {
    state.showTimeFields = value;
  },
  set_show_extensive_fields(state, value) {
    state.showExtensiveFields = value;
  },
  set_show_maximum_dose_field(state, value) {
    state.showMaximumDoseField = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
