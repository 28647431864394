<script>
export default {
  computed: {
    // We need this one as long as we have a v2 and v3 frontend
    // By checking for an exception we force every component
    // that checks if it's currently on a vue page (NavBottom, Links)
    // to think it's on an old page
    exceptionThrown() {
      if (typeof this.$el === 'undefined' || this.$el === null) {
        return false;
      }
      return false;
    },
  },
  methods: {
    currentPageIsVuePage() {
      // Met deze setting kunnen we HTML status codes ook in vue een eigen pagina geven zonder de route aan te hoeven passen
      if (this.$store.state.settings.navigation.isVueErrorPage === true) {
        return true;
      }

      // Als het een v2 pagina is, is het sowieso geen Vue page
      const body = document.querySelector('body.v2');
      if (body !== null) {
        return false; // Hij is gevonden, dus geen vue page
      }

      if (this.exceptionThrown) {
        return false;
      }
      // Sommige pagina's zijn v2 maar omdat er in Vue een wildcard
      // route bestaat denkt vue dan dat deze bestaan. Bijvoorbeeld:
      // /reports/_report_id
      // die matched met:
      // /reports/medimomonitor
      // Voor nu kunnnen we die zo snel excluden. Beter is dat deze simpelweg nooit matchen
      const v2Urls = [
        '/reports/medimomonitor',
        '/reports/scores',
        '/reports/scores/scoreOverview'
      ];

      // Checks if the current page is a Vue page, with a <router-view> component
      // Het idee is nu dat alle URLs met een :model_id of :modelId route variabel die hier veranderd wordt in :id  voor de check.
      // Zowel in de Vue routes, als de daadwerkelijke window.path.
      // Op die manier wordt:
      // /status/:patient_id/dosage-instruction/:dosage_instruction_id
      // /status/:id/dosage-instruction/:id
      // en /status/1234/dosage-instruction/5678
      // veranderd dan in
      // /status/:id/dosage-instruction/:id
      // Waardoor beide te vergelijken zijn.
      const path = window.location.pathname;
      if (v2Urls.includes(path)) {
        return false;
      }

      let normalizePath = this.normalizeNewIdPath(path);
      normalizePath = this.normalizeIdPath(normalizePath);
      // Then check the actual Vue pages for a match with this path
      if (this.$router.options.routes.filter((route) => {
        return normalizePath === this.normalizeRoutePath(route.path);
      }).length > 0) {
        return true;
      }

      // Deze vangt de case af dat een variabel in de url geen ID is maar een string
      return typeof this.$route.name !== 'undefined' && this.$route.name !== null;
    },
    pathIsVueRoute(path) {
      // Checks if the current page is a Vue page, with a <router-view> component
      // Het idee is nu dat alle URLs met een :model_id of :modelId route variabel die hier veranderd wordt in :id  voor de check.
      // Zowel in de Vue routes, als de daadwerkelijke window.path.
      // Op die manier wordt:
      // /status/:patient_id/dosage-instruction/:dosage_instruction_id
      // /status/:id/dosage-instruction/:id
      // en /status/1234/dosage-instruction/5678
      // veranderd dan in
      // /status/:id/dosage-instruction/:id
      // Waardoor beide te vergelijken zijn.

      // Werkt niet met strings, enkel met IDs

      path = this.removeUrlParameters(path);

      // Checks if the current link is a Vue route that's generated by our
      // Folder structure
      const routerMatches = this.$router.options.routes.filter((route) => {
        // Here we compare the normalized link; /status/12345/episodes -> /patient/:id/episodes
        // To the normalized route; /status/:patient_id/episodes -> /patient/:id/episodes
        let normalizePath = this.normalizeNewIdPath(path);
        normalizePath = this.normalizeIdPath(normalizePath);

        return normalizePath === this.normalizeRoutePath(route.path);
      });

      return routerMatches.length > 0;
    },
    normalizeRoutePath(path) {
      return path.replace(/(:[a-z])\w+/g, ":id");
    },
    normalizeIdPath(path) {
      path = path.replace(/\/([0-9])+/g, "/:id");

      // If the last character is /, and the path is not the homepage
      if (path[path.length - 1] === '/' && path.length > 1) {
        path = path.slice(0, -1);
      }
      return path;
    },
    normalizeNewIdPath(path) {
      return path.replace(/(new-[0-9]*)+/g, ":id");
    },
    removeUrlParameters(path) {
      return path.split('?')[0];
    }
  }
};
</script>
