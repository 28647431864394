<template>
    <medimo-tr class="cluster-select-row"
               @triggered="switchCluster"

               :link="'/clusterselect/' + cluster.id"
               :navigateOnClick="false"
    >
        <medimo-td class="col-12 col-sm-7">
            {{ cluster.name }}
        </medimo-td>
        <medimo-td class="col-12 col-sm-5">
            {{ cluster.city }}
        </medimo-td>
    </medimo-tr>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  components: {},
  emits: ['startLoading','stopLoading'],
  props: [
    'cluster',
    'returnPath',
  ],

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    showClusterSelector: {
      get() {
        return this.$store.state.settings.navigation.showClusterSelector;
      },
      set(value) {
        this.$store.commit('settings/navigation/setShowClusterSelector', value);
      }
    },
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    switchCluster() {
      this.$emit('startLoading');

      this.$store.dispatch('clusters/switch_to_cluster', {
        cluster_id: this.cluster.id,
      }).then((response) => {
        // If all went well, we get the new CurrentUser back.
        if (this.returnPath.length > 0) {
          // Navigate to the returnPath
          window.location.href = this.returnPath;
        }
        // When there's no return path, it's been activated through the NavBar cluster select.
        // We can now just close it since the CurrentUser has been updated in the clusters/switch_to_cluster action.
        else {
          // Just reload the page if no return path is defined (most likely because the NavBar cluster select was triggered)
          // This will automatically update all the data on that page with the latest cluster. Not as clean as a store-based
          // solution, but safer cross v2 / v3
          location.reload();
        }
      }).catch(() => {
        this.$emit('stopLoading');
      });
    }
  },

  watch: {
    //
  }
};
</script>
