<template>
  <div id="patient-info-summary" class="page-component mx-auto" style="position: relative; min-height: 47px; padding: 8px 20px 10px 20px;">
    <medimo-loader-overlay size="32" :loading="! patient"></medimo-loader-overlay>
    <transition name="fade">
      <div v-if="patient">
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <medimo-link :to="'/config/entities/patients/photo/' + patient.id" aria-label="thumbnail patient">
                <patient-thumbnail :left="-4" :patient="patient"></patient-thumbnail>
              </medimo-link>
              <div class="mt-1">
                <strong class="text-large ml-1 mr-1">
                  <span id="patient-name">{{ patient.name }}</span>
                  <medimo-link class="text-small text-muted" v-if="patient.authorizedBasicInfo && show_additional_info" :to="'/config/patients/tabs/' + patient.id" aria-label="edit patient">
                    <fa-icon icon="fa-solid fa-pen" size="lg"/>
                  </medimo-link>
                </strong>
                <template v-if="show_additional_info">
                  <div class="d-print-none d-sm-block d-md-none w-100"/>
                  <span class="status badge badge-sm badge-medimo-50 ml-1" v-if="patient.currentlyELV">ELV</span>
                  <span class="status badge badge-sm badge-medimo-50 ml-1" v-if="patient.currentlyDBC">DBC</span>

                  <patient-import-menu-link :patient="patient"/>
                  <patient-bsn-menu-link :patient="patient"/>
                  <patient-lsp-opt-in-menu-link :patient="patient" :language="current_user.language"/>
                  <patient-bem-menu-link :patient="patient" :language="current_user.language"/>
                  <patient-thrombosis-menu-link :patient="patient"/>
                </template>
              </div>
            </div>
          </div>
          <div class="col-5 d-print-block d-none d-md-none d-lg-block" v-if="lgPlus">
            <patient-info-summary-action-buttons v-if="show_additional_info" :patient="patient"/>
          </div>
        </div>
        <!-- Gegevens -->
        <div class="row mt-1 extra-info-container">
          <div class="col-8">
            <div class="text-medimo-75">
              <div class="text-small mr-3" style="display:inline;">
                <fa-icon icon="fa-duotone fa-calendar-days" size="lg"/>
                {{ patient.birthday }}, {{ patient.age }} jaar
              </div>
              <div class="d-print-none d-sm-block d-md-none w-100"/>
              <div class="text-small mr-3" style="display:inline;" v-if="patient.location.name">
                <fa-icon icon="fa-solid fa-location-dot" size="lg"/>
                <template v-if="useLink">
                  <medimo-link :to="'/config/resource/entities/location/' + patient.location.id">{{ patient.location.name }}</medimo-link>,
                  <medimo-link :to="'/config/resource/entities/ward/' + patient.ward.id">{{ patient.ward.name }}</medimo-link>
                </template>
                <template v-else>{{ patient.location.name }}, {{ patient.ward.name }}</template>
                <template v-if="patient.room">, {{ patient.room }}</template>
              </div>
              <div class="text-small" style="display:inline;" v-if="patient.currentlyImport && show_additional_info">
                <fa-icon icon="fa-regular fa-mortar-pestle" size="lg"/>
                Laatste apotheek-import {{ patient.latestImport }}
              </div>
            </div>
          </div>
          <slot name="extra-info-container"></slot>
        </div>
        <!-- Waarschuwingen -->
        <div class="row mt-1 extra-info-container" v-if="(patient.notice || patient.patientInfo) && show_additional_info">
          <div class="col-12">
            <span class="text-danger text-small mr-3" v-if="patient.notice">
              <fa-icon icon="fa-solid fa-circle-exclamation" size="lg"/> {{ patient.notice }}
            </span>
            <div class="d-print-none d-sm-block d-md-none w-100" v-if="patient.notice && patient.patientInfo"/>
            <span class="text-danger text-small" v-if="patient.patientInfo">
              <fa-icon icon="fa-solid fa-sharp fa-circle-info" size="lg"/> {{ patient.patientInfo }}
            </span>
          </div>
        </div>

        <div class="d-print-none d-sm-block d-md-block d-lg-none" v-if="!lgPlus">
          <hr/>
          <patient-info-summary-action-buttons v-if="show_additional_info" :patient="patient"></patient-info-summary-action-buttons>
        </div>
      </div>
    </transition>

    <unprocessed-action-dialogs v-if="show_additional_info" :patient="patient"></unprocessed-action-dialogs>
  </div>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoSummaryActionButtons from './PatientInfoSummaryActionButtons';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import PatientLspOptInMenuLink from './PatientLspOptInMenuLink';
import PatientBemMenuLink from './PatientBemMenuLink';
import PatientBsnMenuLink from './PatientBsnMenuLink';
import UnprocessedActionDialogs from './action-dialogs/UnprocessedActionDialogs';
import PatientThumbnail from './PatientThumbnail';
import PatientImportMenuLink from "@/vue/components/patients/PatientImportMenuLink.vue";
import PatientThrombosisMenuLink from "@/vue/components/patients/PatientThrombosisMenuLink.vue";

export default {
  components: {
    PatientImportMenuLink,
    PatientThumbnail,
    PatientBsnMenuLink,
    PatientLspOptInMenuLink,
    PatientBemMenuLink,
    PatientThrombosisMenuLink,
    PatientInfoSummaryActionButtons,
    UnprocessedActionDialogs,
  },

  props: {
    patient_id: {},
    patient_data: {default: null},
    show_additional_info: {default: true},

    // This one is used to pass data from the traditional Laravel blade into this component
    // When it's not, we trigger the fetch
    laravel_blade_data: {
      default: () => {
        return {};
      }
    },
  },

  mixins: [
    BaseComponentsMixin,
    UsesMediaQueries,
  ],

  computed: {
    current_user() {
      return this.$store.getters['current_user/data'];
    },
    useLink() {
      const env = process.env.APP_ENV;
      return (env === 'development' || env === 'testing') && this.current_user.is_admin;
    },
    patient() {
      if (this.patient_data !== null) {
        return this.patient_data;
      }
      return this.$store.getters['patients/find'](this.patient_id);
    },
    loading() {
      return typeof this.patient !== 'undefined';
    },
  },

  created() {
    // Add the Patient JSON to the patients store once on page-load.
    if (Object.keys(this.laravel_blade_data).length) {
      this.$store.commit('patients/add_to_data', this.laravel_blade_data);
    }
  },

  mounted() {
    if (this.patient !== null) {
      this.addToPatientBreadcrumbs();
    }
  },

  methods: {
    // We use this one to populate the "Patienten" in the NavSearch
    // All we save is the name and ID
    addToPatientBreadcrumbs() {
      this.$store.dispatch('settings/breadcrumbs/add_patient_breadcrumb', {
        id: this.patient.id,
        name: this.patient.name,
      });
    },
  },

  watch: {
    // When the route changes we need to update the breadcrumbs
    patient_id() {
      this.addToPatientBreadcrumbs();
    },
  }
};
</script>

<style scoped lang="scss">
#patient-info-summary {
  a.badge:hover {
    color: white;
  }
}

.extra-info-container {
  // Needed to get it aligned below the thumbnail properly
  // Extends the .row -5px margin, hence the weird -2. It's actually +3
  margin-left: -2px;
}
</style>
