import Utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  max_breadcrumbs: 4,
  data: [],
  breadcrumb_trail: [],

  // Met deze kunnen we zien of de pagina ververst is of niet
  page_refreshed: false,

  // Patient Breadcrumbs are stored in the NavSearch.vue component
  // Please see there for the required data structure
  patients: [],
  max_patient_breadcrumbs: 5,
};

// getters
const getters = {
  previous_breadcrumb(state, getters, rootState) {

    // If there are no breadcrumbs, return Home
    if (state.data.length === 0 || state.data.length === 1) {
      return '/';
    }

    return state.data[state.data.length - 2].path;
  },
  previous_breadcrumb_trail(state, getters, rootState) {

    // If there are no breadcrumbs, return Home
    if (state.breadcrumb_trail.length === 0 || state.breadcrumb_trail.length === 1) {
      return '/';
    }

    return state.breadcrumb_trail[state.breadcrumb_trail.length - 2].path;
  },
  breadcrumb_trail(state, getters) {
    return state.breadcrumb_trail;
  },
  navigated_deeper(state, getters) {
    // Als de vorige breadcrumb_trail gelijk is aan de vorige breadcrumb dan betekent
    // dit dat de gebruiker dieper is gegaan. Als dat niet zo is is hij of op hetzelfde niveau
    // gebleven, of terug gegaan. Van deze logica maken we hier gebruik om te bepalen
    // of een gebruiker dus dieper is genaviveerd.
    // Als beide home zijn dan zijn we natuurlijk niet dieper genavigeerd, dit kan met een rechtstreekse
    // navigatie vanaf de homepage anders mis gaan
    if (getters.previous_breadcrumb === getters.previous_breadcrumb_trail) {
      return true;
    }
    return false;
  },
  page_refreshed(state, getters) {
    return state.page_refreshed;
  }
};

// actions
const actions = {
  add_patient_breadcrumb({state, commit, dispatch, getters, rootState}, patient) {

    let patients = Utility.clone(state.patients);

    // If it's not new, remove the existing one first
    if (Utility.id_already_exists(patients, patient.id)) {
      patients = Utility.remove_object_from_array_by_id(patients, patient.id);
    }
    patients.unshift(patient);
    commit('set_patient_breadcrumbs', patients);
  },
  add_breadcrumb_trail({state, commit}, breadcrumb) {
    const breadcrumbTrail = state.breadcrumb_trail;
    const lastBreadcrumb = breadcrumbTrail[breadcrumbTrail.length - 1];

    // When no last breadcrumb, or when they dont match
    if (typeof lastBreadcrumb === 'undefined' || lastBreadcrumb.path !== breadcrumb.path) {
      breadcrumbTrail.push(breadcrumb);
      commit('set_breadcrumb_trail', breadcrumbTrail);
      commit('set_page_refreshed', false);
      return;
    }

    if (lastBreadcrumb.path === breadcrumb.path) {
      commit('set_page_refreshed', true);
      return true;
    }
    commit('set_page_refreshed', false);
  },
  add_breadcrumb({state, commit, dispatch, getters, rootState}, breadcrumb) {

    // We need to clone it, otherwise we bypass the mutations and screw up
    // the persistence
    let breadcrumbs = Utility.clone(state.data);

    // First check if the new breadcrumb is equal to the last breadcrumb
    // And there are already breadcrumbs in the basket
    if (breadcrumbs.length) {
      const last_breadcrumb = breadcrumbs[breadcrumbs.length - 1];
      if (last_breadcrumb.path === breadcrumb.path && last_breadcrumb.title === breadcrumb.title) {
        return false;
      }
    }

    // Secondly, we don't want to add the Home link to the first array item since the Home icon is already
    // Rendered when items exist in the array.
    if (breadcrumbs.length === 0 && breadcrumb.path === '/') {
      return false;
    }

    // Third, if the breadcrumb is already in the hierarchy, we replace that one
    // For instance:
    // https://medimo.test/status/91311/
    // https://medimo.test/status/91312/
    // If the previous breadcrumb is in the same hierarchy, it has to go
    if (breadcrumbs.length > 0) {
      const last_breadcrumb = breadcrumbs[breadcrumbs.length - 1];

      // Convert the paths to arrays so we can check the values, see if they are similar
      const trimSlashesRegex = /^\/|\/$/g;
      const last_breadcrumb_paths = last_breadcrumb.path.replace(trimSlashesRegex, '').split('/');
      const new_breadcrumb_paths = breadcrumb.path.replace(trimSlashesRegex, '').split('/');

      // Check for identical lengths (if they differ, the path may continue)
      if (last_breadcrumb_paths.length === new_breadcrumb_paths.length) {
        // We make an Exception for specific start paths, like Status - if they are in the
        // path, we will accept them as different breadcrumbs on the same level
        const whiteListedPaths = ['status'];
        let isWhiteListed = false;
        whiteListedPaths.forEach(pathStart => {
          if (last_breadcrumb_paths.length === 1 && new_breadcrumb_paths.length === 1) {
            isWhiteListed = last_breadcrumb_paths[0].startsWith(pathStart) && new_breadcrumb_paths[0].startsWith(pathStart);
          }
        });

        if (!isWhiteListed) {
          // Pop the last array so we only compare the paths up to the last one
          last_breadcrumb_paths.pop();
          new_breadcrumb_paths.pop();

          let allPathsAreIdentical = true;
          last_breadcrumb_paths.forEach((path, index) => {
            if (new_breadcrumb_paths[index] !== path) {
              allPathsAreIdentical = false;
            }
          });

          // When they are identical we pop the last one,
          if (allPathsAreIdentical) {
            breadcrumbs.pop();
          }
        }
      }
    }

    // Fourth - if we've already been to that path, we just revert back to it.
    if (breadcrumbs.length > 0) {
      breadcrumbs.some((oldBreadcrumb, index) => {
        if (oldBreadcrumb.path === breadcrumb.path) {
          // Clear the breadcrumbs, including the match
          breadcrumbs = breadcrumbs.slice(0, index);
          // Break the loop, so we don't overwrite stuff
          return true;
        }
      });
    }

    breadcrumbs.push(breadcrumb);

    commit('set_breadcrumbs', breadcrumbs);
  }
};

// mutations
const mutations = {
  set_patient_breadcrumbs(state, patient_breadcrumbs) {
    if (patient_breadcrumbs.length > state.max_patient_breadcrumbs) {
      patient_breadcrumbs = patient_breadcrumbs.slice(0, state.max_patient_breadcrumbs);
    }

    state.patients = patient_breadcrumbs;
  },
  set_breadcrumb_trail(state, breadcrumb_trail) {

    const max = 100;

    if (breadcrumb_trail.length > max) {
      breadcrumb_trail = breadcrumb_trail.slice(Math.max(breadcrumb_trail.length - max, 0));
    }

    state.breadcrumb_trail = breadcrumb_trail;
  },
  set_breadcrumbs(state, breadcrumbs) {

    if (breadcrumbs.length > state.max_breadcrumbs) {
      breadcrumbs = breadcrumbs.slice(Math.max(breadcrumbs.length - state.max_breadcrumbs, 0));
    }

    state.data = breadcrumbs;
  },
  delete_last_breadcrumb(state, breadcrumb) {
    const new_breadcrumbs = state.data;
    new_breadcrumbs.pop();

    state.data = new_breadcrumbs;
  },
  remove_breadcrumbs_after(state, breadcrumb_index) {

    let new_breadcrumbs = state.data;
    new_breadcrumbs = new_breadcrumbs.slice(0, breadcrumb_index + 1);

    state.data = new_breadcrumbs;
  },
  clear_breadcrumbs(state) {
    state.data = [];
  },
  clear_patients(state) {
    state.patients = [];
  },
  set_page_refreshed(state, value) {
    state.page_refreshed = value;
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
