import {MedicationOverviewObject} from '@/domain/models/MedicationOverviewObject';

export class GeneratedMedicationOverviewObject { 
  public id: number;
  public pharmaceuticalTreatmentId: number;
  public medication: string;
  public description: string;
  public date: string;
  public startDate: string;
  public endDate: string;
  public isStop: boolean;

  constructor(id: number, pharmaceuticalTreatmentId: number, medication: string, description: string, date: string, startDate: string, endDate: string, isStop: boolean) {
    this.id = id;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.medication = medication;
    this.description = description;
    this.date = date;
    this.startDate = startDate;
    this.endDate = endDate;
    this.isStop = isStop;
  }

  public static fromJson(json: unknown): MedicationOverviewObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationOverviewObject(
      parsedJson['id'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['medication'] as string,
      parsedJson['description'] as string,
      parsedJson['date'] as string,
      parsedJson['startDate'] as string,
      parsedJson['endDate'] as string,
      parsedJson['isStop'] as boolean,
    );
  }
}
