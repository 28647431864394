import {GeneratedRangeObject} from '@/domain/models/generated/GeneratedRangeObject';

export class RangeObject extends GeneratedRangeObject {
  public processRangeInput(value: string): void {
    const replacedValue = value.trim().replace(',', '.');

    // Check if the input contains a dash ('-')
    if (replacedValue.includes('-')) {
      const firstDashIndex = replacedValue.indexOf('-');

      // Check if the dash is at the start or end
      if (firstDashIndex === 0 || firstDashIndex === replacedValue.length - 1) {
        return;
      }

      const afterFirstDash = replacedValue.slice(firstDashIndex + 1);

      // Check if there's more than one dash
      if (afterFirstDash.includes('-')) {
        return;
      }

      const splittedValue = replacedValue.split('-');
      const firstValue = Number(splittedValue[0]);
      const secondValue = Number(splittedValue[1]);

      // Check if both values are valid numbers
      if (!isNaN(firstValue) && !isNaN(secondValue)) {
        if (firstValue === secondValue) {
          // If both values are the same, treat it as a nominalValue
          this.nominalValue = firstValue;
          this.minimumValue = null;
          this.maximumValue = null;
          return;
        } else {
          this.maximumValue = Math.max(firstValue, secondValue);
          this.minimumValue = Math.min(firstValue, secondValue);
          this.nominalValue = null;
          return;
        }
      }
    } else {
      // Handle single numeric input case
      const parsedValue = replacedValue !== '' ? Number(replacedValue) : null;
      if (parsedValue === null || !isNaN(parsedValue)) {
        this.nominalValue = parsedValue;
        this.minimumValue = null;
        this.maximumValue = null;
        return;
      }
    }
  }

  public retrieveFormattedRange(): string {
    if (this.nominalValue !== null) {
      return this.nominalValue.toString();
    }

    if (this.minimumValue !== null && this.maximumValue !== null) {
      return `${this.minimumValue}-${this.maximumValue}`;
    }

    return '';
  }
}
