import {GeneratedDosingInstructionObject} from '@/domain/models/generated/GeneratedDosingInstructionObject';

export class DosingInstructionObject extends GeneratedDosingInstructionObject { 
  public showSchemaView() {
    if (this.administeringSchedule !== null) {
      if (this.administeringSchedule.weekDay !== null && this.administeringSchedule.weekDay.length > 0) {
        return true;
      }

      if (this.administeringSchedule.administrationTime !== null && this.administeringSchedule.administrationTime.length > 1) {
        return true;
      }

      if (this.administeringSchedule.partOfDay !== null && this.administeringSchedule.partOfDay.length > 0) {
        return true;
      }
    }

    if (this.asNeeded?.maximumDose) {
      return true;
    }

    if (this.administeringSpeed) {
      return true;
    }

    if (this.durationOfAdministration) {
      return true;
    }

    if (this.doseDuration) {
      return true;
    }

    return false;
  }
}
