import {GeneratedPractitionerRole} from '@/domain/models/generated/GeneratedPractitionerRole';

export class PractitionerRole extends GeneratedPractitionerRole {
  public fullName() {
    let professional = this.healthcareProfessional.professionalObject;

    if (professional.text) {
      return professional.text;
    }
    return professional.firstName + ' ' + professional.lastName;
  }
}
