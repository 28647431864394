import { store } from '@/vue/store';
import {MedicationUse} from "@/domain/models/MedicationUse";
import {MedicationUseController} from "@/controllers/MedicationUseController";
import {MedicationUseVerificationItem} from "@/domain/models/MedicationUseVerificationItem";

export class MedicationUseVerificationController {
  protected apiEndpoint = 'medication-use-verification';

  public async list(patientId: number): Promise<MedicationUseVerification | null> {
    try {
      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint,
      });

      return response.data;
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to load medication verification.');
    }
  }

  public async confirmMedicationUse(patientId: number, pharmaceuticalTreatmentId: number): Promise<MedicationUse> {
    try {
      const response = await store.dispatch('api/postEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/confirm-use/' + pharmaceuticalTreatmentId,
      });

      return response.data.data;
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to process medication verification.');
    }
  }

  public async stopMedicationUse(patientId: number, endDateTime: string, pharmaceuticalTreatmentId: number): Promise<MedicationUse> {
    try {
      const response = await store.dispatch('api/postEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/stop-use/' + pharmaceuticalTreatmentId,
        data: {endDateTime: endDateTime}
      });

      return response.data.data;
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to process medication verification.');
    }
  }

  public async initiateUpdateMedicationUse(patientId: number, pharmaceuticalTreatmentId: number): Promise<void> {
    try {
      const response = await store.dispatch('api/postEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/initiate-update-use/' + pharmaceuticalTreatmentId,
      });

      const medicationUseController = new MedicationUseController();
      medicationUseController.setConceptOnStore(response.data['medicationUse'], response.data['drug']);

      store.commit('drugs/add_to_data', response.data['drug'], {root: true});
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to process medication verification.');
    }
  }

  public async confirmMedicationVerification(patientId: number): Promise<void> {
    try {
      await store.dispatch('api/postEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/confirm-medication-verification/',
      });
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to process medication verification.');
    }
  }
}

export interface MedicationUseVerification {
  data: MedicationUseVerificationItem[],
  lastMedicationVerificationAt: string,
}
