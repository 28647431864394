import {GeneratedVariableDosingRegimen} from '@/domain/models/generated/GeneratedVariableDosingRegimen';
import moment from "moment";

export class VariableDosingRegimen extends GeneratedVariableDosingRegimen {
  public isActive() {
    if (moment(this.startDateTime).isBefore(moment()) && this.endDateTime === null) {
      return true;
    } else if (moment(this.startDateTime).isBefore(moment()) && moment(this.endDateTime).isAfter(moment())) {
      return true;
    }

    return false;
  }
}
