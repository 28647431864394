import {VariableDosingRegimen} from '@/domain/models/VariableDosingRegimen';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {CodeValue} from '@/domain/models/CodeValue';
import {PractitionerRole} from '@/domain/models/PractitionerRole';

export class GeneratedVariableDosingRegimen { 
  public id: number;
  public patientId: number;
  public description: string;
  public externalIdentifierSystem: CodeSystem;
  public externalIdentifier: IdentifierObject;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public medicationAgreementId: number | null;
  public pharmaceuticalTreatmentId: number;
  public startDateTime: string | null;
  public endDateTime: string | null;
  public instructionsForUse: InstructionsForUse;
  public periodOfUse: TimeIntervalObject;
  public stopType: CodeValue | null;
  public comment: string | null;
  public reasonModificationOrDiscontinuation: CodeValue | null;
  public requester: PractitionerRole;

  constructor(id: number, patientId: number, description: string, externalIdentifierSystem: CodeSystem, externalIdentifier: IdentifierObject, pharmaceuticalProduct: PharmaceuticalProduct, medicationAgreementId: number | null = null, pharmaceuticalTreatmentId: number, startDateTime: string | null = null, endDateTime: string | null = null, instructionsForUse: InstructionsForUse, periodOfUse: TimeIntervalObject, stopType: CodeValue | null = null, comment: string | null = null, reasonModificationOrDiscontinuation: CodeValue | null = null, requester: PractitionerRole) {
    this.id = id;
    this.patientId = patientId;
    this.description = description;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.externalIdentifier = externalIdentifier;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.medicationAgreementId = medicationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.instructionsForUse = instructionsForUse;
    this.periodOfUse = periodOfUse;
    this.stopType = stopType;
    this.comment = comment;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
    this.requester = requester;
  }

  public static fromJson(json: unknown): VariableDosingRegimen {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new VariableDosingRegimen(
      parsedJson['id'] as number,
      parsedJson['patientId'] as number,
      parsedJson['description'] as string,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      IdentifierObject.fromJson(parsedJson['externalIdentifier']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['medicationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['comment'] as string,
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
      PractitionerRole.fromJson(parsedJson['requester']),
    );
  }
}
