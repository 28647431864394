<template>
  <!-- Dit is het stuk dat je wilt copy/pasten in een nieuwe pagina / component. -->
  <medi-modal
      :loading="loading"
      :title="action.name"
      size="medium"
      @closed="ignoreForNow"
      :hide_overlay="hide_overlay"
      :trigger="trigger_modal"
      :min_height="0"
  >
    <medimo-form>

      {{ action.description }}

      <medimo-validated-vue-select2
          label_column_classes="col-md-7"
          :label="'Moet er medicatie aan de ' + language.patient + ' toegediend worden?'"
          :help_text="'Is de organisatie verantwoordelijk voor het toedienen van de medicatie aan deze ' + language.patient + '? Bedoeld wordt het bestellen, bewaren, en aanreiken/toedienen van medicatie die de ' + language.patient + ' nodig heeft. De afspraken over het beheer worden doorgaans in het zorgplan vastgelegd.'"
          :options="[
                {value: 'Y', text: 'Ja, organisatie dient alles toe'},
                {value: 'P', text: 'Ja, organisatie dient gedeeltelijk toe'},
                {value: 'N', text: 'Nee, ' + language.patient + ' doet medicatie zelf'},
              ]"
          v-model="should_be_administered"
          name="should_be_administered"
      >
      </medimo-validated-vue-select2>

      <medimo-validated-textarea v-if="should_be_administered==='P'"
                                 class="mt-2"
                                 :full_height="false"
                                 label_column_classes="col-12 col-sm-3"
                                 label="Toelichting"
                                 :placeholder="pharmacyPlaceholder"
                                 v-model="partly_self_explanation"
                                 name="partly_self_explanation"
                                 rows="1"
      ></medimo-validated-textarea>

      <medimo-validated-vue-select2
          v-if="extraQuestionNeeded"
          label_column_classes="col-md-7"
          :label="'Wie is er verantwoordelijk voor de medicatiebehandeling?'"
          :help_text="'Bedoeld wordt de hoofdvoorschrijver. Is dat de huisarts (bij thuiszorg, zonder behandeling, of poliklinisch) of de voorschrijver van de zorgorganisatie (met behandeling, klinisch)?'"
          :options="[
                {value: 'Y', text: 'De eigen huisarts van de ' + language.patient},
                {value: 'N', text: 'De voorschrijver van zorgorganisatie'},
              ]"
          v-model="is_extramural"
          name="is_extramural"
      >
      </medimo-validated-vue-select2>

      <medimo-form-row v-if="readyForStep3">
        <medimo-form-column column_classes="mb-2 p-2">
          <span v-if="pharmacyUnknown">
            Momenteel is niet bekend bij welke apotheek deze {{ language.patient }} staat ingeschreven.<br>
            <span v-if="this.patient.authority.canEditPatientBasicInfo"><medimo-link :to="'/config/patients/tabs/' + this.patient.id + '?tab=3&noActionPopUp=1'">Klik hier om de thuisapotheek van deze {{ language.patient }} te wijzigen</medimo-link><br></span>
            <span v-else>U heeft onvoldoende rechten om dit zelf te kunnen wijzigen. Deze actie kan nu niet afgehandeld worden. Neem contact op met applicatie- of {{ language.patient }}beheer van uw organisatie.<br></span>
          </span>
          <span v-else>
            Momenteel staat deze {{ language.patient }} ingeschreven bij {{ pharmacy.name }} in {{ pharmacy.city }}.<br>
            <span v-if="this.patient.authority.canEditPatientBasicInfo"><medimo-link :to="'/config/patients/tabs/' + this.patient.id + '?tab=3&noActionPopUp=1'">Klik hier om de thuisapotheek van deze {{ language.patient }} te wijzigen</medimo-link><br></span>
            <span v-else>U heeft onvoldoende rechten om dit zelf te kunnen wijzigen.<br></span>
            <span v-if="!importPossible">Er kan niet veilig gemaild worden met deze apotheek. Deze actie kan nu niet afgehandeld worden. Laat applicatiebeheer van uw organisatie contact opnemen met de Medimo Helpdesk.<br></span>
          </span>
        </medimo-form-column>
      </medimo-form-row>

      <medimo-validated-vue-select2
          v-if="readyForStep3 && importPossible"
          label_column_classes="col-md-5"
          :label="'' + language.gds + '?'"
          :help_text="'Is het de bedoeling dat de apotheek (zoveel mogelijk) de medicatie levert als ' + language.gds + '?'"
          :options="[
                {value: 'Y', text: 'Ja, graag als ' + language.gds + ' leveren'},
                {value: 'D', text: 'Ja, indien mogelijk met autom. medicijndispenser'},
                {value: 'N', text: 'Nee, niet nodig'},
              ]"
          v-model="should_be_baxtered"
          name="should_be_baxtered"
      >
      </medimo-validated-vue-select2>

      <medimo-validated-vue-select2
          v-if="should_be_baxtered"
          class="mt-2"
          label_column_classes="col-md-9"
          :help_text="'Toestemming voor het uitwisselen van de medicatiegegevens tussen het apotheeksysteem van ' + pharmacy.name + ' en Medimo'"
          :label="'Geeft de ' + language.patient + ' toestemming voor uitwisseling medicatiegegevens?'"
          :options="[
              {value: 'Y', text: 'Ja'},
              {value: 'N', text: 'Nee'},
              ]"
          v-model="gives_permission"
          name="gives_permission"
      ></medimo-validated-vue-select2>

      <medimo-validated-textarea v-if="readyToSubmit"
                                 class="mt-2"
                                 label_column_classes="col-12 col-sm-3"
                                 label="Extra opmerking"
                                 :placeholder="pharmacyPlaceholder"
                                 v-model="explanation"
                                 name="explanation"
                                 rows="3"
      ></medimo-validated-textarea>

    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button type="success" @click="submit" :disabled="!readyToSubmit">
        Afhandelen
      </medimo-button>
      <medimo-button type="medimo" class="ml-auto" @click="ignoreForNow">
        Nu even niet
      </medimo-button>
    </template>

  </medi-modal>

</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';
import MedimoVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoVueSelect2';
import MedimoLabeledVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoLabeledVueSelect2';
import MedimoValidatedVueSelect2 from '@/vue/components/general/form/base/vue-select2/MedimoValidatedVueSelect2';

export default {
  components: {
    MedimoValidatedVueSelect2,
    MedimoLabeledVueSelect2,
    MedimoVueSelect2,
    MediModal,
  },
  emits: ['closed', 'processed'],

  props: [
    'action',
    'patient',
    'hide_overlay',
    'refresh_patient_medication_data',
  ],

  mixins: [
    BaseComponentsMixin,
    NavigatesProgrammatically,
  ],

  data: function () {
    return {
      pharmacy: null,
      should_be_administered: '',
      should_be_baxtered: '',
      partly_self_explanation: '',
      should_be_imported: '',
      gives_permission: '',
      is_extramural: '',
      explanation: '',

      agreed: false,
      note: '',
      trigger_modal: 1,
      loading: false,

      checked: false,

      // Can be changed on the Fly, see decline()
      to: '/'
    };
  },

  computed: {
    //
    language() {
      return this.$store.getters['current_user/language'];
    },
    pharmacyUnknown() {
      return this.pharmacy === undefined || this.pharmacy.id === 89;
    },
    importPossible() {
      return !this.pharmacyUnknown && this.pharmacy.can_be_notified;
    },
    readyForStep2() {
      return (this.should_be_administered === 'P' && this.partly_self_explanation.length) || this.should_be_administered === 'Y';
    },
    extraQuestionNeeded() {
      return this.readyForStep2 && this.patient.location.moduleAIS === 'M';
    },
    readyForStep3() {
      return this.readyForStep2 && (this.is_extramural === 'Y' || !this.extraQuestionNeeded);
    },
    readyToSubmit() {
      return this.gives_permission.length || this.should_be_administered === 'N' || this.is_extramural === 'N';
    },
    pharmacyPlaceholder() {
      // If no need or no permission, then no message to pharmacy (so also no placeholder about it)
      if (this.should_be_administered === 'N' || this.gives_permission === 'N') {
        return '';
      }
      return '(wordt meegenomen in bericht naar apotheek toe)';
    }
  },

  created() {
    //
  },

  mounted() {
    this.fetchPharmacyInfo();
  },

  methods: {
    ignoreForNow() {
      this.$emit('closed');
      this.processed_action_dialog();
    },

    fetchPharmacyInfo() {
      this.loading = true;
      this.$store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/pharmacy/for-patient/' + this.patient.id,
        rejectErrors: true,
      }).then(response => {
        this.loading = false;
        // We krijgen hier de PharmacyResource terug
        this.pharmacy = response.data.data;
      }).catch(() => {
        // this.errorMessage = 'Er is iets mis gegaan waardoor de actie niet verwerkt kan worden. Neem aub contact op met support.'
        this.loading = false;
      });
    },

    submit() {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        rejectErrors: true,
        action_id: this.action.id,
        patient_id: this.action.patientId,
        handler: 'intakeMedicationImport',
        refresh_patient_medication_data: this.refresh_patient_medication_data,
        data: {
          should_be_administered: this.should_be_administered,
          should_be_baxtered: this.should_be_baxtered,
          gives_permission: this.gives_permission,
          is_extramural: this.is_extramural,
          partly_self_explanation: this.partly_self_explanation,
          explanation: this.explanation,
        }
      }).then(() => {
        // Afronden en melding tonen
        this.loading = false;
        this.$store.dispatch('notifications/addSuccessAlert', {message: 'Gegevens opgeslagen'});

        this.$emit('processed');
        this.close();
      }).catch((response) => {
        try {
          // For this specific use-case, we want to add processed because the action is in fact removed
          if (response['data'][0]['notification']['text'] === 'Apotheek import is niet actief op deze locatie, actie is verwijderd') {
            this.$emit('processed');
            this.close();
          }
        } catch (error) {
          // Ignore this error, it's because response['data'][0]['notification']['text'] doesn't exist
        }
        this.loading = false;
      });
    },

    /**
     * Updates the current_user's array so the dialog won't bother him/her again
     */
    processed_action_dialog() {
      this.$store.commit('current_user/set_processed_action_dialog', this.action);
    }
  },

  watch: {
    should_be_administered() {
      this.should_be_baxtered = '';
      this.gives_permission = '';
    }
  }
};
</script>
