import DataType from "@/enums/DataType";
import MedicationChecks from "@/declarations/MedicationCheck";
import StoreUpdateData from "@/declarations/StoreUpdateData";
import { store } from '@/vue/store';
import {AdministeringScheduleObject} from "@/domain/models/AdministeringScheduleObject";
import {PractitionerRole} from "@/domain/models/PractitionerRole";
import {MedicationGuard} from "@/domain/models/MedicationGuard";
import {DosingInstructionObject} from "@/domain/models/DosingInstructionObject";

export abstract class AbstractMedication<T> {
  protected abstract storeName: string;
  protected abstract apiEndpoint: string;
  protected abstract fromJson(data: unknown): T;

  private controller: AbortController | null = null;

  public async mutation(patientId: number, id: number, minStartDateTime: string): Promise<Mutation> {
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/mutation',
      data: {
        minStartDatetime: minStartDateTime
      }
    });

    return response.data;
  }

  public async check(patientId: number, id: number): Promise<MedicationChecks> {
    const draft = store.getters[this.storeName + '/' + DataType.draft](id);
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id,
      data: draft
    });

    return response.data;
  }

  public async medicationGuard(patientId: number, id: number): Promise<MedicationGuard> {
    // todo mp9 this way we can cancel previous calls, needs a refactor.
    if (this.controller !== null) {
      this.controller.abort();
      this.controller = new AbortController();
    } else {
      this.controller = new AbortController();
    }

    const draft = store.getters[this.storeName + '/' + DataType.draft](id);
    const response = await store.dispatch('api/postEndpoint', {
      endpoint: '/api/v1/mp9/patient/' + patientId + '/' + this.apiEndpoint + '/' + id + '/medication-guard',
      data: draft,
      signal: this.controller.signal
    });

    return response.data;
  }

  public storeDraft(id: number): void {
    const draft = store.getters[this.storeName + '/' + DataType.draft](id);
    store.commit(this.storeName + '/storeDraft', draft);
  }

  public setConceptOnStore(data: unknown, drug: unknown): void {
    store.commit(this.storeName + '/clearData');
    const agreement = this.fromJson(data);
    store.commit(this.storeName + '/setData', {data: agreement, drug: drug});
  }

  public removeConcept(id: number): void {
    store.commit(this.storeName + '/removeConcept', id);
  }

  public revertChanges(id: number): void {
    store.commit(this.storeName + '/revertChanges', id);
  }

  public removeFromStore(): void {
    store.commit(this.storeName + '/clearData');
  }

  public setDataOnStore(data: T, drug: unknown): T {
    store.commit(this.storeName + '/setData', {data: data, drug: drug});
    return data;
  }

  public async createDosingInstruction(id: number, sequenceNumber: number, payload: AdministeringScheduleObject | null): Promise<boolean> {
    try {
      const dosingInstruction = new DosingInstructionObject();
      dosingInstruction.sequenceNumber = sequenceNumber;

      if (payload) {
        dosingInstruction.administeringSchedule = payload;
      }

      store.commit(this.storeName + '/createDosingInstruction', {
        id: id,
        dosingInstruction: dosingInstruction
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  public async removeDosingInstruction(id: number, dosingInstructionIndex: number): Promise<boolean> {
    try {
      store.commit(this.storeName + '/removeDosingInstruction', {
        id: id,
        dosingInstructionIndex: dosingInstructionIndex
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  public updateStore(payload: StoreUpdateData): void {
    this.createAdministeringScheduleWhenNull(payload);
    store.commit(this.storeName + '/' + payload.type, {
      id: payload.id,
      value: payload.value,
      key: payload.key,
      dosingInstructionIndex: payload.dosingInstructionIndex, // Not required when updating other than dosing instruction.
    });
  }

  /**
   * Function is used to ensure an administeringSchedule is present whenever an element
   * in the administering schedule is updated. This element can be null from the backend.
   */
  private createAdministeringScheduleWhenNull(payload: StoreUpdateData): void {
    if (payload.type !== 'updateAdministeringSchedule') {
      return;
    }

    if (payload.dosingInstructionIndex === undefined) {
      return;
    }

    const draft = store.getters[this.storeName + '/' + DataType.draft](payload.id);

    // So when administerSchedule does not exist we update the store with an empty AdministeringScheduleObject object.
    // eslint-disable-next-line
    // @typescript-eslint/ban-ts-comment
    if (draft.instructionsForUse?.dosingInstructions[payload.dosingInstructionIndex].administeringSchedule === null) {
      this.updateStore({
        id: payload.id,
        type: 'updateDosingInstruction',
        value: new AdministeringScheduleObject(),
        key: 'administeringSchedule',
        dosingInstructionIndex: payload.dosingInstructionIndex
      });
    }
  }
}

export interface Mutation {
  mutationDateTime: string,
  nextPackageDateTime: string,
  prescriber: PractitionerRole,
}
