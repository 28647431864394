import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  search_endpoint: '/api/v1/medication/provisional-drug-request',
  sort_columns: [{column_name: 'drug.naam', direction: 'ASC'}],
};

const actions = {
  ...BaseModule.actions,
  set_endpoint({state, commit, dispatch}, payload) {
    commit(
      'set_search_endpoint',
      '/api/v1/medication/provisional-drug-request/' + payload.patientId
    );
  },
  fetch_single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: state.search_endpoint + '/record/' + payload.id
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
  save({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: state.search_endpoint + '/record/' + payload.id,
      data: payload.data
    }, {root: true}).then(response => {
      commit('add_to_data', response.data.data);
    });
  },
};
const getters = { ...BaseModule.getters };
const mutations = { ...BaseModule.mutations };

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
