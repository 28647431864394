import Utility from '@/vue/utility/utility';
import BaseModule from '@/vue/store/modules/base_module';

// initial state
// shape: [{ id, quantity }]
const state = {
  data: {
    pendingLspLink: false,
    pendingProtocolLink: false,
    pendingProvisionalLink: false,
    pendingProvisionalDrugRequestLink: false,
    lspInformationLink: false,
  }
};

// getters
const getters = {
  lsp (state) {
    return state.data.pendingLspLink;
  },
  protocol (state) {
    return state.data.pendingProtocolLink;
  },
  provisional (state) {
    return state.data.pendingProvisionalLink;
  },
  provisional_drug_request (state) {
    return state.data.pendingProvisionalDrugRequestLink;
  },
  lsp_information(state) {
    return state.data.lspInformationLink;
  }
};

// actions
const actions = {
};

// mutations
const mutations = {
  set_lsp(state, link) {
    state.data.pendingLspLink = link;
  },
  set_protocol(state, link) {
    state.data.pendingProtocolLink = link;
  },
  set_provisional(state, link) {
    state.data.pendingProvisionalLink = link;
  },
  set_provisional_drug_request(state, link) {
    state.data.pendingProvisionalDrugRequestLink = link;
  },
  set_lsp_information(state, link) {
    state.data.lspInformationLink = link;
  }
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
