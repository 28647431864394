<template>
  <div class="data-tree xml">
    <div class="row my-2"  v-if="withSearch">
      <div class="col-sm-3">
        <medimo-input class="search" v-model="query" :placeholder="queryPlaceholder"></medimo-input>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox class="toggle-matches" v-model="onlyShowMatches" label="Matches-only" label_position="right"></medimo-labeled-checkbox>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox class="toggle-match-content" v-model="showMatchContent" label="Toon match content" label_position="right"></medimo-labeled-checkbox>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox class="toggle-fade" v-model="fadeMatchContent" label="Fade non-match" label_position="right"></medimo-labeled-checkbox>
      </div>
    </div>
    <div class="root">
      <xml-tree-branch
          :element="element"
          :layer="1"
          :query-value="query"
          :onlyShowMatches="onlyShowMatches"
          :showMatchContent="showMatchContent"
          :fadeMatchContent="fadeMatchContent"
          :autoCollapseAfter="autoCollapseAfter"
      ></xml-tree-branch>
    </div>
  </div>
</template>

<script>
import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import utility from '@/vue/utility/utility';
import XmlTreeBranch from './XmlTreeBranch';
import MedimoLabeledCheckbox from '@/vue/components/general/form/base/MedimoLabeledCheckbox';

export default {
  name: "XmlTree",
  components: {MedimoLabeledCheckbox, XmlTreeBranch, MedimoInput},
  props: {
    rawData: String,


    // Deze zijn nodig om de filtering ook recursive werkend te krijgen
    withSearch: {default: false},
    queryPlaceholder: {default: 'Zoek...'},
    queryValue: {default: ''},

    autoCollapseAfter: {required: true},
  },
  data() {
    return {
      show: true,
      query: '',
      onlyShowMatches: true,
      fadeMatchContent: false,
      showMatchContent: true,
    };
  },
  computed: {
    element() {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(this.rawData, "text/xml");
      return xmlDoc.documentElement;
    },
    // Children zijn hier de child "DOM" nodes die vanuit de parser komen
    // Intern maakt hij op basis van de XML een soort tree aan die dezelfde eigenschappen heeft als een element uit de querySelector
    children() {
      const children = [];
      let node = this.element.firstChild;
      while (node) {
        children.push(node);
        node = node.nextSibling;
      }
      return children;
    },

    isText() {
      if (this.children.length == 1) {
        if (this.children[0].nodeType == 3) {
          return true;
        }
      }

      return false;
    }
  },
  created() {
    //
  },
  methods: {
    matchesFilter(value) {
      return utility.string_contains_query(value, this.filterValue);
    }
  }
};
</script>
