<template>
  <div class="data-tree json">
    <div class="row my-2"  v-if="withSearch">
      <div class="col-sm-3">
        <medimo-input class="search" v-model="query" :placeholder="queryPlaceholder"></medimo-input>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox class="toggle-matches" v-model="onlyShowMatches" label="Matches-only" label_position="right"></medimo-labeled-checkbox>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox class="toggle-match-content" v-model="showMatchContent" label="Toon match content" label_position="right"></medimo-labeled-checkbox>
      </div>
      <div class="col-sm-3">
        <medimo-labeled-checkbox  class="toggle-fade" v-model="fadeMatchContent" label="Fade non-match" label_position="right"></medimo-labeled-checkbox>
      </div>
    </div>
    <div class="root" v-if="isValidJson">
      <json-tree-branch
          :jsonKey="''"
          :jsonValue="jsonData"
          :layer="1"
          :query-value="query"
          :onlyShowMatches="onlyShowMatches"
          :fadeMatchContent="fadeMatchContent"
          :show-match-content="showMatchContent"
          :auto-collapse-after="autoCollapseAfter"
      ></json-tree-branch>
    </div>
    <div class="row" v-if="!isValidJson">
      <div class="col col-sm-6">
        <medimo-notification type="danger">
          Invalide JSON
        </medimo-notification>
      </div>
    </div>
  </div>
</template>

<script>
import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import JsonTreeBranch from './JsonTreeBranch';
import MedimoLabeledCheckbox from '@/vue/components/general/form/base/MedimoLabeledCheckbox';
import MedimoAlert from '@/vue/components/general/MedimoAlert';
import MedimoNotification from '@/vue/components/general/MedimoNotification';

export default {
  name: "JsonTree",
  components: {MedimoNotification, MedimoAlert, MedimoLabeledCheckbox, JsonTreeBranch, MedimoInput},
  props: {
    rawData: String,
    // Deze zijn nodig om de filtering ook recursive werkend te krijgen
    withSearch: {default: false},
    queryPlaceholder: {default: 'Zoek...'},
    queryValue: {default: ''},

    // Collapsed de regels als ze 3 niveaus + diep zijn
    autoCollapseAfter: {default: 3},
  },
  data() {
    return {
      show: true,
      query: '',
      onlyShowMatches: true,
      fadeMatchContent: true,

      showMatchContent: true,

      isValidJson: null,
    };
  },
  computed: {
    jsonData() {
      if (this.isValidJson) {
        return JSON.parse(this.rawData);
      }
      return JSON.parse("");
    }
  },
  created() {
    // We willen een error kunnen tonen als de Json invalid is zonder dat de hele app direct
    // krak zegt
    try {
      JSON.parse(this.rawData);
    } catch (e) {
      this.isValidJson = false;
    }
    // Dan als hij hier nog steed null is, zetten we hem op true
    if (this.isValidJson === null) {
      this.isValidJson = true;
    }
  },
  methods: {
    //
  }
};
</script>
