import {GeneratedTimeIntervalObject} from '@/domain/models/generated/GeneratedTimeIntervalObject';
import moment from "moment";
import DurationConstructor = moment.unitOfTime.DurationConstructor;
import Ucum from '@/enums/Ucum';

export class TimeIntervalObject extends GeneratedTimeIntervalObject {
  // Returns the selected end date time or the calculated
  // end date time base on the selected duration.
  public endDateTimeOrDuration(): string | null {
    if (this.endDateTime) {
      return this.endDateTime;
    }

    if (this.duration) {
      return moment(this.startDateTime).add(this.duration.value, this.convertDurationCode(this.duration.code?.code)).format();
    }

    return null;
  }

  private convertDurationCode(code: string | undefined): DurationConstructor {
    switch (code) {
      case Ucum.YEAR:
        return 'y';
      case Ucum.WEEK:
        return 'w';
      default:
        return 'd';
    }
  }
}
