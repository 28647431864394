import {MedicationAgreement} from '@/domain/models/MedicationAgreement';
import {CodeSystem} from '@/domain/models/CodeSystem';
import {IdentifierObject} from '@/domain/models/IdentifierObject';
import {PharmaceuticalProduct} from '@/domain/models/PharmaceuticalProduct';
import {PractitionerRole} from '@/domain/models/PractitionerRole';
import {InstructionsForUse} from '@/domain/models/InstructionsForUse';
import {TimeIntervalObject} from '@/domain/models/TimeIntervalObject';
import {CodeObject} from '@/domain/models/CodeObject';
import {CodeValue} from '@/domain/models/CodeValue';

export class GeneratedMedicationAgreement { 
  public id: number;
  public description: string;
  public patientId: number;
  public externalIdentifierSystem: CodeSystem;
  public externalIdentifier: IdentifierObject;
  public pharmaceuticalProduct: PharmaceuticalProduct;
  public previousMedicationAgreementId: number | null;
  public administrationAgreementId: number | null;
  public pharmaceuticalTreatmentId: number;
  public medicationUseId: number | null;
  public prescriber: PractitionerRole;
  public startDateTime: string | null;
  public endDateTime: string | null;
  public instructionsForUse: InstructionsForUse;
  public periodOfUse: TimeIntervalObject;
  public additionalInformation: CodeObject | null;
  public stopType: CodeValue | null;
  public comment: string | null;
  public reasonModificationOrDiscontinuation: CodeValue | null;
  public prescriptionReason: CodeObject | null;
  public placeholder: boolean;
  public cancelledIndicator: boolean;

  constructor(id: number, description: string, patientId: number, externalIdentifierSystem: CodeSystem, externalIdentifier: IdentifierObject, pharmaceuticalProduct: PharmaceuticalProduct, previousMedicationAgreementId: number | null = null, administrationAgreementId: number | null = null, pharmaceuticalTreatmentId: number, medicationUseId: number | null = null, prescriber: PractitionerRole, startDateTime: string | null = null, endDateTime: string | null = null, instructionsForUse: InstructionsForUse, periodOfUse: TimeIntervalObject, additionalInformation: CodeObject | null = null, stopType: CodeValue | null = null, comment: string | null = null, reasonModificationOrDiscontinuation: CodeValue | null = null, prescriptionReason: CodeObject | null = null, placeholder: boolean, cancelledIndicator: boolean) {
    this.id = id;
    this.description = description;
    this.patientId = patientId;
    this.externalIdentifierSystem = externalIdentifierSystem;
    this.externalIdentifier = externalIdentifier;
    this.pharmaceuticalProduct = pharmaceuticalProduct;
    this.previousMedicationAgreementId = previousMedicationAgreementId;
    this.administrationAgreementId = administrationAgreementId;
    this.pharmaceuticalTreatmentId = pharmaceuticalTreatmentId;
    this.medicationUseId = medicationUseId;
    this.prescriber = prescriber;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.instructionsForUse = instructionsForUse;
    this.periodOfUse = periodOfUse;
    this.additionalInformation = additionalInformation;
    this.stopType = stopType;
    this.comment = comment;
    this.reasonModificationOrDiscontinuation = reasonModificationOrDiscontinuation;
    this.prescriptionReason = prescriptionReason;
    this.placeholder = placeholder;
    this.cancelledIndicator = cancelledIndicator;
  }

  public static fromJson(json: unknown): MedicationAgreement {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new MedicationAgreement(
      parsedJson['id'] as number,
      parsedJson['description'] as string,
      parsedJson['patientId'] as number,
      CodeSystem.fromJson(parsedJson['externalIdentifierSystem']),
      IdentifierObject.fromJson(parsedJson['externalIdentifier']),
      PharmaceuticalProduct.fromJson(parsedJson['pharmaceuticalProduct']),
      parsedJson['previousMedicationAgreementId'] as number,
      parsedJson['administrationAgreementId'] as number,
      parsedJson['pharmaceuticalTreatmentId'] as number,
      parsedJson['medicationUseId'] as number,
      PractitionerRole.fromJson(parsedJson['prescriber']),
      parsedJson['startDateTime'] as string,
      parsedJson['endDateTime'] as string,
      InstructionsForUse.fromJson(parsedJson['instructionsForUse']),
      TimeIntervalObject.fromJson(parsedJson['periodOfUse']),
      parsedJson['additionalInformation'] ? CodeObject.fromJson(parsedJson['additionalInformation']) : null,
      parsedJson['stopType'] ? CodeValue.fromJson(parsedJson['stopType']) : null,
      parsedJson['comment'] as string,
      parsedJson['reasonModificationOrDiscontinuation'] ? CodeValue.fromJson(parsedJson['reasonModificationOrDiscontinuation']) : null,
      parsedJson['prescriptionReason'] ? CodeObject.fromJson(parsedJson['prescriptionReason']) : null,
      parsedJson['placeholder'] as boolean,
      parsedJson['cancelledIndicator'] as boolean,
    );
  }
}
