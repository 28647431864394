import utility from './utility';

export default {

  selectAndCopyText(element) {
    let range;
    if (window.getSelection) {
      range = document.createRange();
      range.selectNode(element);
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
    }

    document.execCommand("copy");
  },
  copyValueToClipboard(value, prefix = '', suffix = '') {
    if (utility.isObject(value) || utility.isArray(value)) {
      value = JSON.stringify(value);
    }

    const element = document.createElement('DIV');
    element.innerText = prefix + value + suffix;

    this._createVirtualContainer();
    // Zonder dit gaat hij piepen en errors throwen
    this.virtualContainer.appendChild(element);

    this.selectAndCopyText(element);
  },
  copyContentsToClipboard(array, delimiter = null, prefix = null, suffix = null) {
    this.delimiter = delimiter;
    this.prefix = prefix;
    this.suffix = suffix;

    // IE11 Polyfill
    if (window.NodeList && !NodeList.prototype.forEach) {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }

    this._instantiateVirtualTable();

    // The goal now is to generate a virtualTable with X and Y axis if the row is defined
    // or just a long list if the row component is not defined.
    // That way a user can quickly copy/paste it in Excel or whatever

    // Als de eerste cel ook een array is dan kopieren we het als een table
    if (Array.isArray(array[0]) && this.delimiter !== null) {
      // Als er een delimiter is ingesteld willen we de tabs dus _niet_ en wordt 1 rij eigenlijk een string
      // met de delimiter als... delimiter.
      const delimitedArray = [];
      // Hier transformeren we een multi-dimensionale array dan ook naar een lijst
      array.forEach(arrayRow => {
        let rowAsString = '';
        arrayRow.forEach(element => {
          rowAsString += element + this.delimiter;
        });
        delimitedArray.push([rowAsString]);
      });
      // En hier vervangen we hem dan
      array = delimitedArray;
    }
    if (!Array.isArray(array[0]) && this.delimiter !== null) {
      console.warn('Er wordt een delimiter meegegeven aan data die geen array is. Delimiters werken alleen met arrays.');
    }

    if (Array.isArray(array[0])) {
      this._createVirtualTable(array);
    }
    // Anders kopieren we het als lijst
    else {
      this._createVirtualList(array);
    }
    // And finally copy the virtual table to the clipboard
    this._copyTable();
  },


  /*
   |--------------------------------------------------------------------------
   | Helpers (private)
   |--------------------------------------------------------------------------
   |
   |
   |
   |
   */
  _instantiateVirtualTable() {
    // The new virtual table that will contain all the data
    this.virtualTable = document.createElement("TABLE");
  },
  _createVirtualTable(rows) {
    if (this.prefix !== null) {
      this._addRowToTable([this.prefix]);
    }

    // Dit is een multi-dimensional array waarbij de eerste array de rijen zijn en iedere
    // rij weer bestaat uit een eigen cellen
    // Dus array[0][3] is rij 1, cel 4
    rows.forEach(cells => {

      // Defined the CellContents
      const cellContents = [];

      // Get the content of all the cells
      cells.forEach(cellContent => {
        cellContents.push(cellContent);
      });

      // And finally add the cells to a new virtual row
      this._addRowToTable(cellContents);
    });

    if (this.suffix !== null) {
      this._addRowToTable(["‏ "]);
      this._addRowToTable([this.suffix]);
    }
  },
  _createVirtualList(rows) {
    // In dit geval is elke row een regel, dus maken we er een lijst van
    rows.forEach(row => {
      this._addRowToTable(row);
    });
  },
  _createVirtualContainer() {
    // First generate a virtual <table> with <thead> body, etc.
    this.virtualContainer = document.createElement("DIV");
    // Make sure it's invisible, do not use display:none, won't work:
    this.virtualContainer.style.position = 'absolute';
    this.virtualContainer.style.left = '-9999px';

    // Make the BG white, or else it's Blue when copying to Word
    this.virtualContainer.style.backgroundColor = 'white';

    const body = document.querySelector('BODY');
    body.appendChild(this.virtualContainer);
  },
  _copyTable() {
    this._createVirtualContainer();

    // Now we need a virtual container, otherwise we get this error:
    // DOMException: Failed to execute 'selectNode' on 'Range': the given Node has no parent.
    // And add it to the DOM or we get this one:
    // The given range isn't in document.
    // Append it to the table
    this.virtualContainer.appendChild(this.virtualTable);

    // Then reselect the table
    const virtualChildTable = this.virtualContainer.querySelector('table');

    // Finally copy it.
    this.selectAndCopyText(virtualChildTable);

    // Remove the virtual container
    this.virtualContainer.remove();
  },
  /**
   * Adds a single row to the virtual table based on cell values
   * @param cells
   * @returns {*}
   */
  _addRowToTable(cells) {

    const virtualRow = document.createElement("TR");

    cells.forEach(cell => {

      const virtualTd = document.createElement("TD");
      const textNode = document.createTextNode(cell);
      // Add the text to the TD
      virtualTd.appendChild(textNode);
      // Then add the TD to the TR
      virtualRow.appendChild(virtualTd);
    });

    // Add the virtualTR to the VirtualTable
    this.virtualTable.appendChild(virtualRow);
  },

};
