import Utility from "@/vue/utility/utility";
import BaseModule from "@/vue/store/modules/base_module";
import DataType from "@/enums/DataType";

const state = {
  ...BaseModule.state,
};

// getters
const getters = {
  ...BaseModule.getters,
  draft(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.draft];
  },
  userModified(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.userModified];
  },
  backend(state) {
    return (id) => Utility.find_model_by_id(state.data, id)[DataType.backend];
  },
  backendByProperty(state) {
    return (id, property) => findByProperty(state.data, property, id);
  },
  findByTreatment(state) {
    return (id) => Utility.find_models_by_property(state.data, 'pharmaceuticalTreatmentId', id);
  },
};

function findByProperty(data, property, id) {
  const result = data.find(item => item.backend[property] === id);

  if (result !== undefined) {
    return result['backend'];
  }

  return null;
}

// actions
const actions = {
  ...BaseModule.actions,
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  setData(state, data) {
    state.data.push({
      id: data.data.id,
      drugId: data.drug.id,
      pharmaceuticalTreatmentId: data.data.pharmaceuticalTreatmentId,
      draft: null,
      userModified: null,
      backend: null,
    });

    const lastKey = state.data.length - 1;

    state.data[lastKey][DataType.draft] = data.data; // Assign the cloned data
    state.data[lastKey][DataType.backend] = state.object.fromJson(Utility.clone(data.data));
  },
  storeDraft(state, data) {
    const element = Utility.find_model_by_id(state.data, data.id);

    element[DataType.draft] = data;
    element[DataType.userModified] = state.object.fromJson(Utility.clone(data));
  },
  clearData(state) {
    state.data = [];
  },
  revertChanges(state, data) {
    const element = Utility.find_model_by_id(state.data, data);
    let revertTo = null;
    if (element.userModified === null) {
      revertTo = element.backend;
    } else {
      revertTo = element.userModified;
    }

    element[DataType.draft] = state.object.fromJson(Utility.clone(revertTo)); // When userModified empty we revert back to backend changes.
  },
  removeConcept(state, data) {
    const element = Utility.find_model_by_id(state.data, data);
    element[DataType.draft] = state.object.fromJson(Utility.clone(element.backend));
    element[DataType.userModified] = null;
  },
  createDosingInstruction(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);
    const nextKey = element.draft['instructionsForUse']['dosingInstructions'].length;
    element.draft['instructionsForUse']['dosingInstructions'][nextKey] = payload.dosingInstruction;
  },
  removeDosingInstruction(state, payload) {
    const element = Utility.find_model_by_id(state.data, payload.id);
    element.draft['instructionsForUse']['dosingInstructions'].splice(payload.dosingInstructionIndex, 1);
  },
  updateDosingInstruction(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.draft];
    draft['instructionsForUse']['dosingInstructions'][payload.dosingInstructionIndex][payload.key] = payload.value;
  },
  updateAdministeringSchedule(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.draft];
    draft['instructionsForUse']['dosingInstructions'][payload.dosingInstructionIndex]['administeringSchedule'][payload.key] = payload.value;
  },
  updateInstructionForUse(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.draft];
    draft['instructionsForUse'][payload.key] = payload.value;
  },
  updateSpecification(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.draft];
    draft[payload.key] = payload.value;
  },
  updatePharmaceuticalProduct(state, payload) {
    const draft = Utility.find_model_by_id(state.data, payload.id)[DataType.draft];
    draft['pharmaceuticalProduct'][payload.key] = payload.value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
