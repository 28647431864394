<template>
    <!--
      We use -1 for countdown to make sure deletes through the store are processed first.
      Otherwise we get a flashing notification dropdown box.
    -->
    <component :is="to.length ? 'medimo-link' : 'div'" :class="component + ' ' + (alert ? 'medimo-alert' : 'medimo-notification') + ' clickable w-100 ' + component + '-' + type + ' ' + (margin_bottom ? 'mb-1' : 'mb-0') + ' ' + (margin_top ? 'mt-1' : 'mt-0') + ' ' + extra_classes  + ' ' + (to.length ? 'd-block' : '') "
               v-if="hide_after === 0 || (hide_after > 0 && countdown > -1)"
               :to="to"
               :target="target"
               :trigger="triggerNavigation ? triggerNavigation : null"
               tag="div"
               @click="clicked"
               v-click-outside="close"
    >
      <div class="w-100" style="align-items: center;">
        <span v-if="exclamation" class="mr-1">
            <fa-icon v-if="type === 'danger'" icon="fa-regular fa-circle-exclamation" size="lg"/>
            <fa-icon v-else-if="type === 'warning'" icon="fa-regular fa-triangle-exclamation" size="lg"/>
            <fa-icon v-else-if="type === 'info'" icon="fa-regular fa-circle-info" size="lg"/>
            <fa-icon v-else-if="type === 'success'" icon="fa-regular fa-circle-check" size="lg"/>
            <b>Let op!</b>
        </span>

        <span :class="'title'" v-bind:class="{'font-weight-bold': boldTitle, 'font-weight-normal': !boldTitle}">
            <!-- Title -->
            <slot></slot>
        </span>

        <!-- body -->
        <div v-if="$slots.body" class="body" v-bind:class="{'p-0': !padding_bottom}">
          <slot name="body"></slot>
        </div>

        <span class="float-right" v-if="redirects_user">{{ countdown }}</span>
      </div>
    </component>
</template>

<script>

import MedimoLink from './MedimoLink';

export default {
  components: {
    MedimoLink
  },

  emits: ['click'],

  props: {
    // Optionally add the ID so it can be removed from the notifications.alerts store when it's timer runs out
    id: {default: null},

    // Set the Type: info, secondary, warning, success and danger
    type: {default: 'success'},

    // If you need this, you're checking out the wrong component. Check MedimoAlert
    alert: {default: false},

    // Use these to auto-hide the notification
    // When left at the default 0, the notification is permanent
    hide_after: {default: 0},

    // Show info icon + Let op!
    exclamation: {default: false},

    // If you want the alert to be clickable, set this to the path:
    to: {default: ''},

    target: {default: '_self'},

    // Set this one as well to redirect the user after a notification
    redirects_user: {default: false},
    redirect_delay: {default: 3000},

    // Use these to disable the top and bottom margins. Currently used in the MedimoAlertContainer
    margin_top: {default: true},
    margin_bottom: {default: true},
    padding_bottom: {default: true},
    boldTitle: {default: true},

    extra_classes: {default: ''},
  },

  mixins: [],

  data: function () {
    return {
      allowedToClose: false,
      countdown: 0,
      triggerNavigation: false,
      countdownInterval: null,
    };
  },

  computed: {
    /**
     * Used at various points to differentiate between an alert and a notification.
     * Feature-wise they're identical, design wise they're different
     * @returns {string}
     */
    component() {
      return this.alert ? 'alert' : 'notification';
    }
  },

  created() {
    //
  },

  mounted() {
    // Check if we should redirect
    if (this.redirects_user) {
      this.start_countdown(this.redirect_delay);
      // this.redirect();
    }

    // Or hide the notification
    if (this.hide_after > 0) {
      this.start_countdown(this.hide_after);
    }

    setTimeout(() => {
      // Met deze verzekeren we dat de gebruiker de MedimoAlert niet binnen 400ms per ongeluk sluit
      this.allowedToClose = true;
    }, 400);
  },

  methods: {
    redirect() {
      setTimeout(() => {
        this.triggerNavigation = true;
      }, this.redirect_delay);
    },

    // Starts the countdown clock based on a miliseconds value
    start_countdown(value) {
      this.countdown = Math.ceil(value / 1000);
      this.countdownInterval = setInterval(() => {
        this.countdown = this.countdown - 1;
      }, 1000);
    },
    navigate() {
      if (this.to.length) {
        this.triggerNavigation++;
      }
    },
    close() {
      if (this.allowedToClose) {
        this.$store.commit('notifications/delete_alert', this.id);
      }
    },
    clicked() {
      this.$emit('click');
      this.close();
    }
  },

  watch: {
    countdown(value) {
      if (value === 0 && this.id !== null) {
        if (this.alert) {
          this.$store.commit('notifications/delete_alert', this.id);
        } else {
          this.$store.commit('notifications/delete_notification', this.id);
        }
      } else if (value === -1) {
        clearInterval(this.countdownInterval); // Stop interval
      }
    }
  }
};
</script>
