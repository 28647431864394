import BaseModule from '@/vue/store/modules/base_module';
import Utility from '@/vue/utility/utility';

// initial state
// shape: [{ id, quantity }]
const state = {
  store_namespace: 'medication_treatments',
  ...BaseModule.state,
  meta: {},
  sort_columns: [{
    column_name: 'drug_name',
    direction: 'ASC',
  }],
  // Provide easy access to a specific sorting state through a simple string. Allows for quick backend saving as well.
  // You can then quickly loop through these state's columns (since they're arrays, not objects) for the proper sort order.
  sort_states: {
    'ABC': [
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'}
    ],
    'ATC': [
      {'column_name': 'drug_atc', 'direction': 'ASC'},
      {'column_name': 'drug_name', 'direction': 'ASC'},
      {'column_name': 'id', 'direction': 'ASC'}
    ],
  },
  filter_columns: [
    'drug_name',
    'medication_agreements.attributes',
    'medication_agreements.dosage_instructions.usage_type',
    'medication_agreements.dosage_instructions.administration_route',
    'medication_agreements.dosage_instructions.quantity_timing_full',
    'medication_agreements.dosage_instructions.start_datetime',
    'medication_agreements.dosage_instructions.stop_datetime',
  ],
  lastTempId: 0,
  db_data: [],      // Database models
  shadow_data: [],  // Temporary models when editing
  data: [],         // Local updated models
  last_medication_refresh: false, // Last time the medication is refreshed
  last_medication_refresh_patient_id: 0,
  inactive_instructions_loaded: {},
};

// getters
const getters = {
  refresh_medication_is_necessary(state) {
    return (payload) => {
      const moment = payload.moment;
      const patientId = payload.patient_id;
      if (state.last_medication_refresh_patient_id !== parseInt(patientId)) {
        //('Last medication refresh is for different patient', state.last_medication_refresh_patient_id, patientId);
        return true;
      }
      //('Last medication refresh: ', state.last_medication_refresh ? state.last_medication_refresh.format('DD-MM-YYYY HH:mm:ss') : false);
      return !state.last_medication_refresh || state.last_medication_refresh.isBefore(moment.subtract(10, 'seconds'));
    };
  },
  ...BaseModule.getters,
};

// actions
const actions = {
  ...BaseModule.actions,
  add_inactive_instructions({state, commit, dispatch, getters, rootState}, payload) {

    // inactives may already by loaded in store:
    const inactive_instruction_loaded = state.inactive_instructions_loaded[payload.medicationTreatmentId];

    if (inactive_instruction_loaded) {
      return;
    }
    // otherwise, load the now:
    const endpoint = '/api/v1/patients/' + payload.patientId + '/get-inactive-instructions/' + payload.medicationTreatmentId;
    return dispatch('api/postEndpoint', {
      endpoint: endpoint,
      data: {dosing_instruction_ids_loaded: payload.dosing_instruction_ids_loaded}
    }, {root: true}).then(response => {
      commit('medication_treatments/set_inactive_instructions_loaded', payload.medicationTreatmentId, {root: true});

      //filter the id's already in store
      const idsInStore = rootState.dosage_instructions.data.map((dosingInstruction) => { return dosingInstruction.id; });
      const dosingInstructions = response.data.dosing_instructions.filter((dosingInstruction) => { return !idsInStore.includes(dosingInstruction.id);});

      // Nu kon het voorkomen dat we de treatments en agreements ook mistte, omdat we
      // alleen nog maar inactieve dosing instructions hadden. Dus die voegen we hieronder alsnog weer toe:
      response.data.medication_agreements.forEach(medicationAgreement => {
        commit('medication_agreements/add_to_data', medicationAgreement, {root: true});
        commit('medication_agreements/add_to_db_data', medicationAgreement, {root: true});
      });

      // Beide met forEach voor MedicationTreatment
      response.data.medication_treatments.forEach(medicationTreatment => {
        commit('medication_treatments/add_to_data', medicationTreatment, {root: true});
        commit('medication_treatments/add_to_db_data', medicationTreatment, {root: true});
      });

      // Beide met forEach voor MedicationTreatment
      response.data.administration_agreements.forEach(administrationAgreement => {
        commit('administration_agreements/add_to_data', administrationAgreement, {root: true});
      });

      return dispatch('dosage_instructions/process_backend_adds', dosingInstructions, {root: true});
    });
  },
  fetch({state, commit, dispatch, getters, rootState}, payload) {
    let endpoint = '/api/v1/patients/' + payload.patientId + '/medication-treatments';

    if (typeof payload.dateTime !== 'undefined' && payload.dateTime !== null && payload.dateTime.length) {
      endpoint += '?dateTime=' + payload.dateTime;
    }

    return dispatch('api/getEndpoint', {
      endpoint: endpoint,
      data: {} // Empty, since it's a GET
    }, {root: true});
  },
  store({state, commit, dispatch, getters, rootState, rootGetters}, payload) {
    const medicationTreatment = payload.medication_treatment;
    const suppressNotifications = payload.suppress_notifications;
    const patientId = payload.patient_id;

    const medicationAgreements = rootGetters['medication_agreements/for_medication_treatment_id'](medicationTreatment.id);

    const changes = rootGetters['medication_agreement_changes/for_agreements'](medicationAgreements);

    let relatedDosingInstructionIds = changes.map(function (change) {
      return change.new_instruction_ids;
    });
    relatedDosingInstructionIds = relatedDosingInstructionIds.reduce((acc, val) => acc.concat(val), []);

    let dosageInstructions = rootGetters['dosage_instructions/find_all'](relatedDosingInstructionIds);
    dosageInstructions = Utility.sort_array_of_objects_asc_by_property(dosageInstructions, 'id');
    let signalsWithNotes = rootGetters['medication_guard_signals/for_dosage_instructions'](dosageInstructions);

    // remove signal.message because it's not necessary and seems to contain invalid json sometimes in some browsers
    signalsWithNotes = signalsWithNotes.map( (signal) => { signal.message = ''; return signal;});

    const manualDosingSchemas = rootGetters['manual_dosing_schemas/for_dosage_instructions'](dosageInstructions);
    const traces = rootGetters['medication_guard_signals/traces_for_dosage_instructions'](dosageInstructions);

    dosageInstructions.forEach(function (dosingInstruction) {
      dosingInstruction.quantity = rootGetters['dosage_instructions/encoded_quantity'](dosingInstruction);
    });

    const data = {
      medication_agreements: medicationAgreements,
      suppress_notifications: suppressNotifications,
      medication_treatment_id: medicationTreatment.id,
      mutations: changes,
      dosing_instructions: dosageInstructions,
      signals_with_notes: signalsWithNotes,
      manual_dosing_schemas: manualDosingSchemas,
      traces: traces,
    };

    // if (window.medimo.debugPrescribe.isDebugging()) {
    //   window.app.sendSentry('Debugging Voorschrijven', null, {data: JSON.stringify(data)});
    // }

    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/patients/' + patientId + '/medication-treatment',
      data: data,
    }, {root: true});
  },
  create({state, commit, dispatch, getters, rootState}, dosage_instruction) {

    const tempId = state.lastTempId + 1;
    //Find last temp id in store
    state.lateTempId = tempId;

    const newTempId = 'new-' + tempId;

    const medicationTreatment = {
      id: newTempId,
      patient_id: dosage_instruction.patient_id,
      drug_id: dosage_instruction.drug_id,
      attributes: [
        'NIV2',
        'KK'
      ]
    };

    commit('medication_treatments/add_to_data', medicationTreatment, {root: true});

    dispatch('medication_agreements/create', {dosage_instruction: dosage_instruction, medication_treatment_id: newTempId}, {root: true});

    return newTempId;
  },
  create_from_taxe({state, dispatch, commit}, payload) {
    const patientId = payload.patient_id;
    const drug = payload.drug;
    const type = payload.type;

    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + patientId + '/taxe/prescribe/' + drug.id + '?type=' + type
    }, {root: true});
  },
  create_from_formulary({state, dispatch, commit}, payload) {
    const patientId = payload.patient_id;
    const arsenaryItem = payload.arsenary_item;
    const type = payload.type;

    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/patients/' + patientId + '/formulary/prescribe/' + arsenaryItem.arsenary_id + '?type=' + type,
    }, {root: true});
  },
  create_temporary({state, commit, dispatch, getters, rootState}, payload) {

    const dosingInstruction = payload.dosingInstruction;

    const tempId = state.lastTempId + 1;
    //Find last temp id in store
    state.lateTempId = tempId;

    const newTempId = 'new-' + tempId;

    const medicationTreatment = {
      id: newTempId,
      patient_id: dosingInstruction.patient_id,
      drug_id: dosingInstruction.drug_id,
      attributes: [
        'NIV2',
        'KK'
      ]
    };

    commit('medication_treatments/add_to_shadow_data', medicationTreatment, {root: true});

    return dispatch('medication_agreements/create_temporary', {
      dosingInstruction: dosingInstruction,
      manualDosingSchema: payload.manualDosingSchema,
      medicationTreatmentId: newTempId
    }, {root: true});
  },
  set_data({state, commit, dispatch, getters, rootState}, medication_treatments) {
    commit('set_data', medication_treatments);
    medication_treatments.forEach(medication_treatment => {
      medication_treatment.medication_agreements.forEach(medication_agreement => {
        commit('medication_agreements/add_to_data', medication_agreement, {root: true});
        medication_agreement.dosage_instructions.forEach(dosage_instruction => {

          // We need these 3 at the Dosage level as well.
          dosage_instruction.drug_name = medication_agreement.drug_name;
          dosage_instruction.drug_atc = medication_agreement.drug_atc;
          dosage_instruction.attributes = medication_agreement.attributes;
          dosage_instruction.is_medical_device = medication_agreement.is_medical_device;

          commit('dosage_instructions/add_to_data', dosage_instruction, {root: true});
        });
      });
    });
  },
  reset_all_local_data({state, dispatch}) {
    dispatch('reset_local_data', true);
    dispatch('medication_agreements/reset_local_data', true, {root: true});
    dispatch('dosage_instructions/reset_local_data', true, {root: true});
  }
};

// mutations
const mutations = {
  ...BaseModule.mutations,
  set_data(state, value) {
    state.data = value;
  },
  set_meta(state, value) {
    state.meta = value;
  },
  set_last_medication_refresh(state, payload) {
    state.last_medication_refresh = payload.moment;
    state.last_medication_refresh_patient_id = parseInt(payload.patient_id);
  },
  set_inactive_instructions_loaded(state, medicationTreatmentId) {
    state.inactive_instructions_loaded[medicationTreatmentId] = true;
  },
  reset_inactive_instructions_loaded(state, medicationTreatmentId) {
    state.inactive_instructions_loaded[medicationTreatmentId] = false;
  },
  reset_all_inactive_instructions_loaded(state, medicationTreatmentId) {
    state.inactive_instructions_loaded = {};
  },
};

export default {

  namespaced: true,

  state,
  getters,
  actions,
  mutations
};
