import {TimeObject} from '@/domain/models/TimeObject';

export class GeneratedTimeObject { 
  public hour: number | null;
  public minutes: number | null;
  public seconds: number | null;

  constructor(hour: number | null = null, minutes: number | null = null, seconds: number | null = null) {
    this.hour = hour;
    this.minutes = minutes;
    this.seconds = seconds;
  }

  public static fromJson(json: unknown): TimeObject {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new TimeObject(
      parsedJson['hour'] as number,
      parsedJson['minutes'] as number,
      parsedJson['seconds'] as number,
    );
  }
}
