import {MedicationAgreement} from "@/domain/models/MedicationAgreement";
import {PharmaceuticalTreatment} from "@/domain/models/PharmaceuticalTreatment";
import {MedicationUse} from "@/domain/models/MedicationUse";
import {VariableDosingRegimen} from "@/domain/models/VariableDosingRegimen";
import {AdministrationAgreement} from "@/domain/models/AdministrationAgreement";
import {store} from "@/vue/store";
import DataType from "@/enums/DataType";
import {AbstractMedicationData} from "@/controllers/AbstractMedicationData";

export abstract class AbstractPharmaceuticalTreatment<T> {
  protected abstract storeName: string;
  protected abstract dataType: string;

  public async store(patientId: number, pharmaceuticalTreatmentId: number): Promise<boolean> {
    try {
      const userModifiedData = this.userModifiedData(pharmaceuticalTreatmentId);

      // When treatment zero its a new one.
      let apiEndPoint = '';
      if (pharmaceuticalTreatmentId === 0) {
        apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/';
      } else {
        apiEndPoint = '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId;
      }

      await store.dispatch('api/postEndpoint', {
        endpoint: apiEndPoint,
        data: {data: userModifiedData, type: this.dataType}
      });

      store.commit(this.storeName + '/clearData');

      return true;
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to store pharmaceutical treatment.');
    }
  }

  public async list(patientId: number): Promise<PharmaceuticalTreatment[] | null> {
    try {
      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/',
      });

      return response.data.data;
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to list pharmaceutical treatments.');
    }
  }

  public async read(patientId: number, pharmaceuticalTreatmentId: number): Promise<AbstractMedicationData<T>[] | null> {
    try {
      const dataFromStore = this.dataFromStore(pharmaceuticalTreatmentId);

      // When we already have one with T available we take it from the store.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (dataFromStore !== null && dataFromStore.length > 0) {
        return Promise.resolve(dataFromStore);
      }

      // New pharmaceutical treatment so we throw an error if nothing in the store.
      if (pharmaceuticalTreatmentId === 0) {
        throw new Error('New pharmaceutical treatment not found.');
      }

      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/pharmaceutical-treatment/' + pharmaceuticalTreatmentId,
      });

      const pharmaceuticalTreatment = response.data.data;

      this.storeMedicationAgreements(pharmaceuticalTreatment);
      this.storeMedicationUses(pharmaceuticalTreatment);
      this.storeAdministrationAgreements(pharmaceuticalTreatment);
      this.storeVariableDosingRegimens(pharmaceuticalTreatment);
      this.storeDrug(pharmaceuticalTreatment);

      return this.dataFromStore(pharmaceuticalTreatmentId);
    } catch (error: unknown) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error('Failed to show pharmaceutical treatment.');
    }
  }

  public async readFromStore(id: number, dataType: DataType): Promise<T | null> {
    return store.getters[this.storeName + '/' + dataType](id);
  }

  public async readFromStoreByProperty(id: number, property: string): Promise<T | null> {
    return store.getters[this.storeName + '/backendByProperty'](id, property);
  }

  public removeFromStore(): void {
    store.commit('mp9/medication_agreements/clearData');
    store.commit('mp9/administration_agreements/clearData');
    store.commit('mp9/variable_dosing_regimens/clearData');
    store.commit('mp9/medication_uses/clearData');
  }

  private dataFromStore(pharmaceuticalTreatmentId: number): AbstractMedicationData<T>[] {
    return store.getters[this.storeName + '/findByTreatment'](pharmaceuticalTreatmentId);
  }

  private userModifiedData(pharmaceuticalTreatmentId: number): T[] {
    const dataFromStore: AbstractMedicationData<T>[] = this.dataFromStore(pharmaceuticalTreatmentId);

    // Retrieve all user modified records.
    const userModifiedData: T[] = [];
    for (const data of dataFromStore) {
      if (data.userModified !== null) {
        userModifiedData.push(data.userModified);
      }
    }

    return userModifiedData;
  }

  private storeMedicationAgreements(pharmaceuticalTreatment: any): void {
    for (const agreement of pharmaceuticalTreatment['medicationAgreements']) {
      const medicationAgreement = MedicationAgreement.fromJson(agreement);
      store.commit('mp9/medication_agreements/setData', {data: medicationAgreement, drug: pharmaceuticalTreatment['drug']});
    }
  }

  private storeMedicationUses(pharmaceuticalTreatment: any): void {
    for (const use of pharmaceuticalTreatment['medicationUses']) {
      const medicationUse = MedicationUse.fromJson(use);
      store.commit('mp9/medication_uses/setData', {data: medicationUse, drug: pharmaceuticalTreatment['drug']});
    }
  }

  private storeAdministrationAgreements(pharmaceuticalTreatment: any): void {
    for (const administration of pharmaceuticalTreatment['administrationAgreements']) {
      const administrationAgreement = AdministrationAgreement.fromJson(administration);
      store.commit('mp9/administration_agreements/setData', {data: administrationAgreement, drug: pharmaceuticalTreatment['drug']});
    }
  }

  private storeVariableDosingRegimens(pharmaceuticalTreatment: any): void {
    for (const regimen of pharmaceuticalTreatment['variableDosingRegimen']) {
      const variableDosingRegimen = VariableDosingRegimen.fromJson(regimen);
      store.commit('mp9/variable_dosing_regimens/setData', {data: variableDosingRegimen, drug: pharmaceuticalTreatment['drug']});
    }
  }

  private storeDrug(pharmaceuticalTreatment: any): void {
    store.commit('drugs/set_data', {data: [pharmaceuticalTreatment['drug']]});
  }
}
