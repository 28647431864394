import { store } from '@/vue/store';
import {MedicationOverview} from "@/domain/models/MedicationOverview";

export class MedicationOverviewController {
  private storeName = 'mp9/medication_overview';

  public async read(patientId: number): Promise<MedicationOverview|null> {
    try {
      const response = await store.dispatch('api/getEndpoint', {
        endpoint: '/api/v1/mp9/patient/' + patientId + '/medication-overview',
      });
      return this.setDataOnStore(response.data);
    } catch (error) {
      // Errors already handled in api store.
      // Throw error for further processing of failure.
      throw new Error();
    }
  }

  private setDataOnStore(data: unknown) {
    const medicationOverview = MedicationOverview.fromJson(data);
    store.commit(this.storeName + '/set_data', medicationOverview);
    return this.dataFromStore();
  }

  private dataFromStore(): MedicationOverview {
    return store.getters[this.storeName + '/data'];
  }
}
