import {Location} from '@/domain/models/Location';

export class GeneratedLocation { 
  public id: number;
  public name: string;
  public moduleTDR: string;

  constructor(id: number, name: string, moduleTDR: string) {
    this.id = id;
    this.name = name;
    this.moduleTDR = moduleTDR;
  }

  public static fromJson(json: unknown): Location {
    const parsedJson = typeof json === 'string' ? JSON.parse(json) : json;
    return new Location(
      parsedJson['id'] as number,
      parsedJson['name'] as string,
      parsedJson['moduleTDR'] as string,
    );
  }
}
