<template>
  <div
      :id="`${id}-DatePicker`"
      :class="{'flex-1 inline': inline, 'p-0 range flex-1': range, 'is-dark': dark, 'has-shortcuts': range && !noShortcuts}"
      class="datepicker-container flex flex-fixed"
  >
    <RangeShortcuts
        v-if="range && !noShortcuts"
        ref="range-shortcuts"
        :modelValue="shortcut"
        :color="color"
        :dark="dark"
        :custom-shortcuts="customShortcuts"
        :height="height"
        @change-range="$emit('update:modelValue', $event)"
    />
    <div class="calendar lm-w-100">
      <div class="datepicker-controls flex align-center justify-content-center">
        <div class="arrow-month h-100">
          <button
              type="button"
              tabindex="-1"
              class="datepicker-button datepicker-prev text-center h-100 flex align-center"
              @click="changeMonth('prev')"
          >
            <fa-icon icon="fa-solid fa-caret-left" size="lg"/>
          </button>
        </div>
        <div
            class="datepicker-container-label flex-1 flex justify-content-center"
        >

          <div class="h-100 flex align-center flex">
            <CustomButton
                class="date-buttons lm-fs-16 padding-button"
                :color="color"
                :dark="dark"
                key="nowButton"
                @click="emitNow"
            >
              <fa-icon icon="fa-solid fa-house" size="lg"/>
            </CustomButton>
          </div>

          <div class="h-100 w-100 flex align-center justify-content-right">
            <CustomButton
                v-for="m in [month]"
                :key="m.month"
                class="date-buttons lm-fs-16 padding-button month-button"
                :color="color"
                :dark="dark"
                @click="selectingYearMonth = 'month'"
            >
              {{ monthFormatted }}
            </CustomButton>
            <CustomButton
                v-for="y in [year]"
                :key="y"
                class="date-buttons lm-fs-16 padding-button year-button"
                :color="color"
                :dark="dark"
                @click="selectingYearMonth = 'year'"
            >
              {{ year }}
            </CustomButton>
          </div>

        </div>
        <div class="arrow-month h-100 text-right">
          <button
              type="button"
              tabindex="-1"
              class="datepicker-button datepicker-next text-center h-100 flex align-center justify-content-right"
              @click="changeMonth('next')"
          >
            <fa-icon icon="fa-solid fa-caret-right" size="lg"/>
          </button>
        </div>
      </div>
      <WeekDays
          :week-days="weekDays"
          :dark="dark"
      />
      <div
          class="month-container"
      >
        <TransitionGroup :name="transitionDaysName">
          <div
              v-for="m in [month]"
              :key="m.month"
              class="datepicker-days flex"
          >
            <day-select-button
                @click="selectDate(day)"
                :day="day"
                v-bind="$attrs"
                v-for="(day, index) in allMonthDays"
                :key="day.format('DMY')"
                :current_month="day.current_month"
                :index="index"
                :modelValue="modelValue"
                :minDate="minDate"
                :maxDate="maxDate"
                :locale="locale"
                :noWeekendsDays="noWeekendsDays"
                :disabledWeekly="disabledWeekly"
                :disabledDates="disabledDates"
                :enabledDates="enabledDates"
            ></day-select-button>
          </div>
        </TransitionGroup>
      </div>
      <YearMonthSelector
          v-if="selectingYearMonth"
          :locale="locale"
          :color="color"
          :dark="dark"
          :mode="selectingYearMonth"
          :month="month"
          @update:modelValue="selectYearMonth"
          @back="selectingYearMonth = null"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-mini';
import {getWeekDays} from '@/vue/components/general/form/date-time-picker/modules/month';
import RangeShortcuts from './_subs/RangeShortcuts';
import YearMonthSelector from './_subs/YearMonthSelector';
import WeekDays from './_subs/WeekDays';
import CustomButton from '@/vue/components/general/form/date-time-picker/_subs/CustomButton';
import KeyboardAccessibility from '@/vue/components/general/form/date-time-picker/mixins/keyboard-accessibility';
import Month from '@/vue/components/general/form/date-time-picker/modules/month';
import DaySelectButton from './_subs/DaySelectButton';

export default {
  name: 'DatePicker',
  components: {
    RangeShortcuts, YearMonthSelector, WeekDays, CustomButton, DaySelectButton
  },
  mixins: [KeyboardAccessibility],
  emits: ['update:modelValue','change-month','change-year-month'],
  props: {
    id: {type: String, default: null},
    modelValue: {type: [String, Object], default: null},
    shortcut: {type: String, default: null},
    color: {type: String, default: null},
    minDate: {type: String, default: null},
    maxDate: {type: String, default: null},
    locale: {type: String, default: null},
    inline: {type: Boolean, default: null},
    noWeekendsDays: {type: Boolean, default: null},
    disabledWeekly: {type: Array, default: () => ([])},
    range: {type: Boolean, default: false},
    disabledDates: {type: Array, default: () => ([])},
    enabledDates: {type: Array, default: () => ([])},
    dark: {type: Boolean, default: false},
    month: {type: Object, default: null},
    height: {type: Number, default: null},
    noShortcuts: {type: Boolean, default: null},
    firstDayOfWeek: {type: Number, default: null},
    customShortcuts: {type: Array, default: () => ([])},
    visible: {type: Boolean, default: null}
  },
  data() {
    return {
      transitionDaysName: 'slidenext',
      transitionLabelName: 'slidevnext',
      selectingYearMonth: null,
      isKeyboardActive: true
    };
  },
  computed: {
    bgStyle() {
      return {
        backgroundColor: this.color
      };
    },
    // Returns how many "empty", i.e. non-current-month days there are
    // We'll build on this to generate / show past month days here.
    numberOfdaysLastMonth() {
      return this.weekStart;
    },
    previousMonthDays() {
      const previousMonthDays = this.previousMonth().getMonthDays();
      // Get the last X days of the previous month
      return this.previousMonth().getMonthDays().slice(previousMonthDays.length - this.numberOfdaysLastMonth, previousMonthDays.length);
    },
    // This one returns an array with the days we need to show in the next month.
    // This will be the endEmptyDays + 7, if the total of days in the current month is < 35 (5 rows of 7)
    nextMonthDays() {
      const daysCurrentAndPrevious = this.monthDays.length + this.numberOfdaysLastMonth;

      // When it's less than 35 days, we have an empty row of days we can fill, so need to add 7
      const daysNextMonth = daysCurrentAndPrevious <= 35 ? (this.endEmptyDays + 7) : this.endEmptyDays;

      // Then we get all the days from the next month, and slice that baby up to size!
      return this.nextMonth().getMonthDays().slice(0, daysNextMonth);
    },
    endEmptyDays() {
      const getDays = (this.monthDays.length + this.weekStart) > 35;
      const number = getDays ? 42 : 35;
      return number - this.monthDays.length - this.weekStart;
    },
    monthDays() {
      return this.month.getMonthDays();
    },
    // We use this one to get all the days in the month, but inject the "current_month" variable.
    allMonthDays() {
      const allMonthDays = [];
      // First last month.
      this.previousMonthDays.forEach(day => {
        day.current_month = false;
        allMonthDays.push(day);
      });
      // Then this month.
      this.monthDays.forEach(day => {
        day.current_month = true;
        allMonthDays.push(day);
      });
      // Then next month.
      this.nextMonthDays.forEach(day => {
        day.current_month = false;
        allMonthDays.push(day);
      });
      return allMonthDays;
    },
    weekStart() {
      return this.month.getWeekStart();
    },
    monthFormatted() {
      return `${this.month.getFormatted()}`;
    },
    year() {
      return `${this.month.getYear()}`;
    },
    weekDays() {
      return getWeekDays(this.locale, this.firstDayOfWeek);
    }
  },
  watch: {
    //
  },
  methods: {


    changeMonth(val) {
      this.transitionDaysName = `slide${val}`;
      this.transitionLabelName = `slidev${val}`;
      this.$emit('change-month', val);
    },
    selectYearMonth(event) {
      const {month, year} = event;
      const isBefore = year === this.month.year
        ? month < this.month.month
        : year < this.month.year;
      this.transitionLabelName = isBefore ? `slidevprev` : `slidevnext`;
      this.selectingYearMonth = null;
      this.$emit('change-year-month', event);
    },
    selectDate(day) {
      if (this.range && !this.noShortcuts) {
        this.$refs['range-shortcuts'].selectedShortcut = null;
      }
      if (this.range) {
        if (!this.modelValue.start || this.modelValue.end || day.isBefore(moment(this.modelValue.start))) {
          this.modelValue.start = day.format('YYYY-MM-DD');
          this.modelValue.end = null;
        } else {
          this.modelValue.end = day.format('YYYY-MM-DD');
        }
        this.$emit('update:modelValue', this.modelValue);
      } else {
        this.$emit('update:modelValue', moment(day).format('YYYY-MM-DD'));
      }
    },
    emitNow() {
      this.$emit('update:modelValue', moment().format('YYYY-MM-DD HH:mm'));
    },
    previousMonth() {
      // month, year, locale
      let month = this.month.month - 1;
      let year = this.month.year;

      // It's from 0 to 11, so if it's less than 0, we're in the previous year
      if (month < 0) {
        month = 11;
        year--;
      }
      // This class was VueCtkDateTimePicker native; has some handy functions.
      return new Month(month, year, this.locale);
    },
    nextMonth() {
      // month, year, locale
      let month = this.month.month + 1;
      let year = this.month.year;

      // It's from 0 to 11, so if it's greater than 11, we're in the next year
      if (month > 11) {
        month = 0;
        year++;
      }
      // This class was VueCtkDateTimePicker native; has some handy functions.
      return new Month(month, year, this.locale);
    }
  }
};
</script>
