enum Ucum {
  WEEK = 'wk',
  DAY = 'd',
  YEAR = 'a',

  SECOND = 's',
  MINUTE = 'min',
  HOUR = 'h',
}

export default Ucum;